/* eslint-disable dot-notation */
// not playing well with css module styles[*]

import { useReducer, useState } from 'react';
import classNames from 'classnames';

import { Box, Button, Icon, Spinner, TextField } from '@nike/eds';
import { Workflow } from '@nike.innovation/composure-sdk';
import { useNavigate } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { useApiClient } from '../../../shared/hooks/use-api-client';
import { WorkflowDesignerSettings } from './workflow-designer-settings';
import WorkflowAddFieldModal from './workflow-add-field-modal';
import { WorkflowStepSelector } from './workflow-step-selector';
import Breadcrumbs, { Breadcrumb } from '../../../shared/breadcrumbs/breadcrumbs';
import { WorkflowDesignerStep } from './workflow-designer-step';
import {
  genInitialDesignerState,
  regenDesignerState,
  workflowDesignerReducer,
  WorkflowDesignerState,
} from '../../core/reducers/workflow-designer-reducer';

import styles from './workflow-designer.module.css';
import { isStepErroring } from '../../core/validation/step/validate-step';

export function WorkflowDesigner({ workflow }: { workflow?: Workflow }) {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [designerState, dispatch] = useReducer(
    workflowDesignerReducer,
    workflow === undefined ? genInitialDesignerState() : regenDesignerState(workflow)
  );
  const [newFieldModalVisible, setNewFieldModalVisible] = useState(false);

  const [isPublishing, setIsPublishing] = useState(false);

  const currentStep =
    designerState.selectedStepIndex !== undefined
      ? designerState.steps[designerState.selectedStepIndex]
      : undefined;
  const selectedField = designerState.selectedFieldId
    ? currentStep?.form[designerState.selectedFieldId]
    : undefined;

  const saveWorkflow = async () => {
    let result;
    setIsPublishing(true);

    if (!workflow) {
      result = await apiClient.createWorkflow({
        name: designerState.name,
        steps: designerState.steps,
      });
    } else {
      result = await apiClient.createWorkflowVersion({
        ...workflow,
        steps: designerState.steps,
      });

      if (workflow.name !== designerState.name) {
        await apiClient.renameAsset(workflow.id, designerState.name);
      }
    }

    setIsPublishing(false);
    navigate(`/workflows/${result.assetId}/versions/${result.versionId}`);
  };

  const isErroring = (state: WorkflowDesignerState): boolean => {
    let isError = false;
    state.steps.forEach((_step, index) => {
      if (isStepErroring(state, index)) {
        isError = true;
      }
    });
    return isError;
  };

  const crumbs: Breadcrumb[] = match(workflow)
    .with({ id: P.string, name: P.string, versionId: P.string }, refined => [
      { url: '/workflows', name: 'Workflows' },
      {
        url: `/workflows/${refined.id}/versions/${refined.versionId}`,
        name: 'Details',
      },
      { url: '', name: 'New Version' },
    ])
    .with(P.nullish, () => [
      { url: '/workflows', name: 'Workflows' },
      { url: '', name: 'New' },
    ])
    .exhaustive();

  return (
    <Box className={styles['designer-layout']}>
      <Box className={styles['breadcrumbs']}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>

      <Box className={styles['workflow-steps']}>
        <WorkflowStepSelector designerState={designerState} dispatch={dispatch} />
      </Box>

      <Box
        className={classNames(
          styles['workflow-name'],
          'eds-flex',
          'eds-flex--align-items-flex-start',
          'eds-gap--24',
          { 'eds-spacing--mb-24': designerState.name !== '' }
        )}
      >
        <Box className="eds-flex--grow-1">
          <TextField
            id="workflow-name"
            type="text"
            label="Name"
            className={styles['workflow-name--input']}
            hasErrors={designerState.name === ''}
            errorMessage="Cannot be blank"
            placeholder="Workflow name"
            hideLabel
            value={designerState.name}
            onChange={e => dispatch({ kind: 'EDIT_WORKFLOW_NAME', newName: e.target.value })}
            data-testid="workflowName"
          />
        </Box>

        <Box className={styles['mt-10']}>
          <Button
            beforeSlot={isPublishing ? <Spinner /> : <Icon name="Global" />}
            title="Save"
            disabled={
              isErroring(designerState) ||
              designerState.steps.length === 0 ||
              designerState.name === '' ||
              isPublishing
            }
            onClick={saveWorkflow}
            data-testid="submit"
          >
            Save and close
          </Button>
        </Box>
      </Box>

      <Box className={styles['designer']}>
        {currentStep && (
          <WorkflowDesignerStep
            designerState={designerState}
            dispatch={dispatch}
            setNewFieldModalVisible={setNewFieldModalVisible}
            selectedStep={currentStep}
          />
        )}
      </Box>

      <Box className={styles['settings']}>
        {selectedField && (
          <WorkflowDesignerSettings
            designerState={designerState}
            selectedField={selectedField}
            dispatch={dispatch}
          />
        )}
      </Box>

      <WorkflowAddFieldModal
        dispatch={dispatch}
        newFieldModalVisible={newFieldModalVisible}
        setNewFieldModalVisible={setNewFieldModalVisible}
      />
    </Box>
  );
}
