import { Aurora, Entitlement, MidsoleCreate, MidsoleCreateVersion } from '@nike.innovation/aurora';
import { NavigateFunction } from 'react-router';
import { match, P } from 'ts-pattern';
import axios from 'axios';
import ga4Event from '../../../shared/utils/ga4-helpers/ga4-events';

// On submit for the new-midsole-form. Based on params received, this will either create a new asset for a midsole or a new version of an existing asset.
export default async function midsoleFormSubmit(
  client: Aurora,
  asset: { midsoleName: string } | { midsoleId: string; midsoleVersionId: string },
  meshFiles: FileList,
  midsoleBoundaryFiles: FileList,
  uvBoundaryFiles: FileList,
  entitlements: Entitlement[],
  navigate: NavigateFunction,
  token: string
): Promise<{ assetId: string; versionId: string }> {
  const meshFilesArr = Array.from(meshFiles);
  const midsoleBoundaryFilesArr = Array.from(midsoleBoundaryFiles);
  const uvFilesArr = Array.from(uvBoundaryFiles);

  const newMidsoleArtifacts = [...meshFilesArr, ...midsoleBoundaryFilesArr, ...uvFilesArr];

  console.log('midsoleArtifacts', newMidsoleArtifacts);

  const artifactPostResults = await Promise.all(
    newMidsoleArtifacts.map(async x => {
      const fileType = x.name.split('.').pop() || 'N/A';
      const artifact = await client.Midsole.createArtifact(
        {
          name: x.name,
          fileType,
          contentType: fileType,
        },
        token
      );
      await axios.put(artifact.location, x, {
        headers: { 'Content-Type': fileType },
      });
      return artifact;
    })
  );

  console.log('artifactPostResults: ', artifactPostResults);

  const midsole = await match(asset)
    .with({ midsoleId: P.string, midsoleVersionId: P.string }, async refined => {
      const { midsoleId, midsoleVersionId } = refined;
      const newMidsole = MidsoleCreateVersion.parse({
        name: 'new Version',
        artifactIds: [...artifactPostResults.map(x => x.id)],
      });

      const newVersion = await client.Midsole.createAssetVersion(
        midsoleId,
        midsoleVersionId,
        newMidsole,
        token
      );
      ga4Event({ category: 'midsoles', action: 'asset_version_upload', label: 'success' });
      return newVersion;
    })
    .with({ midsoleName: P.string }, async refined => {
      const { midsoleName } = refined;
      const newMidsole = MidsoleCreate.parse({
        name: midsoleName,
        artifactIds: [...artifactPostResults.map(x => x.id)],
        entitlements,
      });
      const newVersion = await client.Midsole.createAsset(newMidsole, token);
      ga4Event({ category: 'midsoles', action: 'asset_upload', label: 'success' });
      return newVersion;
    })
    .exhaustive();
  const { assetId, versionId } = midsole;

  navigate(`/midsoles/${assetId}/versions/${versionId}`);
  navigate(0);
  return { assetId, versionId };
}
