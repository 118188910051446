import { Box, Button, ButtonGroup, Spinner } from '@nike/eds';
import React, { Dispatch } from 'react';

export function PaginationShowMore({
  page,
  setPage,
  solvesLoading,
}: {
  page: number;
  setPage: Dispatch<React.SetStateAction<number>>;
  solvesLoading?: boolean;
}) {
  return (
    <Box className="eds-flex eds-flex--justify-content-center eds-spacing--mt-16">
      <ButtonGroup>
        <Button
          onClick={() => {
            setPage(page + 1);
          }}
          variant="secondary"
          size="small"
          className="bg-black text-md font-medium h-9"
          disabled={solvesLoading}
        >
          {!solvesLoading ? 'Show more' : <Spinner size="medium" />}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
