import { useEffect } from 'react';
import { KeyedMutator } from 'swr';
import { Link } from 'react-router-dom';
import { Text, Box, Icon, Card, Spinner, Select, TextField, Button, Toggle } from '@nike/eds';
import { AssetSearchResponse } from '@nike.innovation/aurora';

import { useOktaAuth } from '@okta/okta-react';
import WorkflowCardGrid from './card-grid';
import { SendPageView } from '../shared/utils/ga4-helpers/ga4-events';
import { PaginationParams } from '../shared/hooks/use-pagination-params';

import styles from './workflows-browse.module.css';

interface DefinitionsSearchResponse {
  definitionSearchResult?: AssetSearchResponse;
  searchIsLoading: boolean;
  definitionSearchErr: any;
  mutate: KeyedMutator<AssetSearchResponse>;
}

export function WorkflowsBrowse({
  paginationParams: {
    setSearchParams,
    from,
    setFrom,
    size,
    setSize,
    filterCurrentUser,
    setFilterCurrentUser,
    debouncedSearchTerm,
    setSearchValue,
  },
  definitionsSearchResponse: {
    definitionSearchResult,
    searchIsLoading,
    definitionSearchErr,
    mutate,
  },
}: {
  paginationParams: PaginationParams;
  definitionsSearchResponse: DefinitionsSearchResponse;
}) {
  SendPageView({ page: window.location.pathname, title: 'Workflows Browse' });
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  useEffect(() => {
    let newSearchParams = {};

    if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
      newSearchParams = {
        searchValue: debouncedSearchTerm,
        size,
        from,
        currentUser: filterCurrentUser,
      };
    } else {
      newSearchParams = {
        size,
        from,
        currentUser: filterCurrentUser,
      };
    }

    setSearchParams(newSearchParams, { replace: true });
  }, [debouncedSearchTerm, from, size, filterCurrentUser, setSearchParams]);

  return (
    <>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-16">
        <Text font="title-1">Workflows</Text>

        <Button
          className="eds--dark"
          variant="primary"
          as={Link}
          to="/workflows/new"
          data-testid="upload-new-workflow"
        >
          Create New Workflow
        </Button>
      </Box>

      <Box className="eds-flex eds-flex--justify-content-space-between eds-flex--align-items-flex-end eds-spacing--mb-32">
        <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--24">
          <Select
            id="resultsPerPageSelect"
            label="Results Per Page"
            defaultValue={{ value: size, label: size }}
            options={[
              { value: 12, label: 12 },
              { value: 24, label: 24 },
              { value: 36, label: 36 },
            ]}
            onChange={(e: any) => {
              setSize(e.value);
              setFrom(0);
            }}
            isSearchable={false}
            required="false"
          />

          <Toggle
            id="currentUserFilterToggle"
            label="Only Show My Workflows"
            size="large"
            onChange={() => {
              setFilterCurrentUser(!filterCurrentUser);
              setFrom(0);
            }}
            checked={filterCurrentUser}
            className={styles['my-workflows-toggle']}
          />
        </Box>

        <Box className={styles['workflow-search-input']}>
          <TextField
            id="workflowSearch"
            onChange={e => {
              if (e.target && e.target.value && e.target.value.length > 0) {
                setSearchValue(e.target.value);
              } else {
                setSearchValue(undefined);
              }
            }}
            label=""
            hideLabel
            beforeSlot={<Icon name="Search" />}
            placeholder="Search by Name"
          />
        </Box>
      </Box>

      {!searchIsLoading && definitionSearchResult ? (
        <WorkflowCardGrid
          resultPage={definitionSearchResult}
          from={from}
          setFrom={setFrom}
          mutate={mutate}
        />
      ) : (
        <Card className="eds-spacing--mt-32">
          <Spinner size="large" />
        </Card>
      )}
      {!searchIsLoading && definitionSearchErr && (
        <Text>There was a problem searching for Workflows</Text>
      )}
    </>
  );
}

export default WorkflowsBrowse;
