import { Box, TextField, Text } from '@nike/eds';
import { useState } from 'react';
import { BezierCurveGraph, ValueType } from './bezier-curve-graph';
import { calculateBezierCurve, ControlPoints, solveXToY } from './calc-bezier-curve';

type BezierCurveInput = [number, number, number, number];

interface newBezierInputParams {
  value: number;
  index: number;
  oldInput: ValueType;
}

export interface BezierCurveFieldProps {
  name: string;
  kind: string;
  // the set of midsoles to select from when there are geometry inputs that support artifacts
  // represents the object that will be posted to the `solve` endpoint in composure-api
  formData: any;
  // a react hook closure to update the fields in any object
  onChange: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const newBezierValue = ({ value, index, oldInput }: newBezierInputParams): ValueType =>
  oldInput.map((v, i) => (index === i ? value : oldInput[i])) as ValueType;

const valueToPoints = (value: ValueType): ControlPoints => [
  { x: value[0], y: value[1] },
  { x: value[2], y: value[3] },
  { x: value[4], y: value[5] },
  { x: value[6], y: value[7] },
];

export function BezierCurveEditor({ name, kind, formData, onChange }: BezierCurveFieldProps) {
  const [bezierInput, setBezierInput] = useState<ValueType>([0, 0, 0, 1, 1, 0, 1, 1]);
  const [bezierOutput, setBezierOutput] = useState<number | undefined>(0);
  const bezierGraphOnChange = (value: ValueType) => setBezierInput(value); // setBezierInput(value);
  const curve = calculateBezierCurve(valueToPoints(bezierInput), 10);
  return (
    <Box className="eds-grid eds-grid--m-cols-2 eds-spacing--mt-16">
      <div className="eds-grid--col-1 eds-grid eds-grid--m-cols-3 eds-grid--items-center eds-gap--8">
        <Text className="eds-grid--col-1">Control Point</Text>
        <Text className="eds-grid--col-1">X</Text>
        <Text className="eds-grid--col-1">Y</Text>
        {[0, 1, 2, 3].map(index => (
          <>
            <Text className="eds-grid--col-1">{index + 1}</Text>
            <TextField
              className="eds-grid--col-1"
              type="number"
              disabled={index === 0 || index === 3}
              id={`bezierControl${index * 2}`}
              label={`Bezier Control ${index * 2 + 1}`}
              defaultValue={bezierInput[index * 2]}
              value={bezierInput[index * 2]}
              hideLabel
              onChange={e =>
                setBezierInput(
                  newBezierValue({
                    value: Number(e.target.value),
                    index: Number(e.target.id.charAt(e.target.id.length - 1)),
                    oldInput: bezierInput,
                  })
                )
              }
            />
            <TextField
              className="eds-grid--col-1"
              type="number"
              id={`bezierControl${index * 2 + 1}`}
              label={`Bezier Control ${index * 2 + 2}`}
              defaultValue={bezierInput[index * 2 + 1]}
              value={bezierInput[index * 2 + 1]}
              hideLabel
              onChange={e =>
                setBezierInput(
                  newBezierValue({
                    value: Number(e.target.value),
                    index: Number(e.target.id.charAt(e.target.id.length - 1)),
                    oldInput: bezierInput,
                  })
                )
              }
            />
          </>
        ))}
      </div>
      <div className="eds-grid--col-1 eds-grid--item-center">
        <BezierCurveGraph
          value={bezierInput}
          onChange={bezierGraphOnChange}
          size={250}
          outerAreaSize={25}
        />
      </div>
      <TextField
        className="eds-grid--col-1"
        type="number"
        id="bezierXInput"
        label="X Input"
        defaultValue={0}
        onChange={e => {
          const result = solveXToY(Number(e.target.value), valueToPoints(bezierInput));
          onChange({ ...formData, [name]: result });
          setBezierOutput(result);
        }}
        step={0.001}
        hasErrors={bezierOutput === undefined}
        errorMessage="There was a problem generating this output. Please enter a value between 0 and 1, with up to 3 decimal places"
      />
      <TextField
        className="eds-grid--col-1"
        type="number"
        id="bezierYOutput"
        label="Y Output"
        value={bezierOutput}
        disabled
      />
    </Box>
  );
}
