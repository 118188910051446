import axios, { ResponseType } from 'axios';
import { environment } from '../../../../../environments/environment';
import { downloadBinaryFile } from '../../../../shared/utils/file-utils';

export const exportDefinitionResults = async (
  definitionId: string,
  versionId: string,
  authToken: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${authToken}` },
    responseType: 'blob' as ResponseType,
  };
  const key = `api/v1/definitions/${definitionId}/versions/${versionId}/solves/export`;
  const response = await axios.get(`${environment.apiBaseUrl}/${key}`, config);
  downloadBinaryFile(response.data, 'results.zip');
};
