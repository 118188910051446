import { useState } from 'react';
import { useNavigate } from 'react-router';
import { KeyedMutator } from 'swr';
import { match } from 'ts-pattern';
import { useOktaAuth } from '@okta/okta-react';

import { Text, Box, Icon, Button, ButtonGroup, Modal, Tooltip, IconButton } from '@nike/eds';
import { GrasshopperDefinition } from '@nike.innovation/composure-sdk';
import { AssetSearchResponse, Aurora, AuroraBaseUrl } from '@nike.innovation/aurora';

import { ga4Event } from '../../../shared/utils/ga4-helpers/ga4-events';
import DefinitionUploadForm from '../../shared/definition-upload-form/definition-upload-form';
import { environment } from '../../../../environments/environment';
import { getUserRole } from '../../../shared/utils/entitlement-utils';
import { DefinitionSettingsModal } from './definition-settings-modal';
import { DefinitionVersionChooser } from './definition-version-chooser';

export function DefinitionDetailsHeader({
  definition,
  definitionSearchResult,
  mutateSearch,
}: {
  definition: GrasshopperDefinition;
  definitionSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  const { oktaAuth, authState } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const navigate = useNavigate();
  const baseUrl = environment.apiBaseUrl;
  const aurora = new Aurora({
    baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
  });

  const role = getUserRole(authState, definition.entitlements || []);
  const buttonDisabled = role === 'Viewer';

  const [uploadModalVis, setUploadModalVis] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const onDelete = async () => {
    if (definitionSearchResult) {
      const newDefinitions = definitionSearchResult.results.filter(
        x => x.assetId !== definition.id
      );

      mutateSearch(
        async () => {
          await aurora.ComputationDefinition.deleteAsset(definition.id, accessToken);

          return undefined;
        },
        {
          optimisticData: {
            ...definitionSearchResult,
            results: newDefinitions,
          },
          populateCache: false,
          revalidate: false,
        }
      );

      ga4Event({
        category: 'definitions',
        action: 'asset_delete',
        label: 'success',
      });

      navigate('/definitions');
    }
  };

  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-8">
        <Box className="eds-flex eds-gap--8 eds-flex--align-items-center">
          <Box className="eds-flex eds-gap--16">
            <Box>
              <Text font="title-1" data-testid="name">
                {definition.name}
              </Text>
            </Box>

            <Box>
              <Text
                font="title-2"
                style={{
                  backgroundColor: 'var(--eds-color-grey-3)',
                  borderRadius: 'var(--eds-radii-4)',
                  padding: '2px 10px',
                }}
              >
                {definition.versionNumber && `V${definition.versionNumber}`}
              </Text>
            </Box>
          </Box>

          <Tooltip bodySlot={definition.isPublic ? 'Public' : 'Private'}>
            <Icon name={definition.isPublic ? 'Unlock' : 'Lock'} size="l" />
          </Tooltip>
        </Box>

        {match(buttonDisabled)
          .with(true, () => (
            <ButtonGroup>
              <Button
                className="eds--dark"
                variant="primary"
                disabled
                title="You do not have permission to edit this asset"
              >
                Upload New Version
              </Button>

              <Button
                id="delete"
                size="small"
                variant="secondary"
                disabled
                title="You do not have permission to delete this asset"
              >
                <Icon name="Delete" />
                Delete
              </Button>
            </ButtonGroup>
          ))
          .otherwise(() => (
            <ButtonGroup>
              <DefinitionUploadForm
                visible={uploadModalVis}
                setVisible={setUploadModalVis}
                definition={definition}
                token={accessToken}
                validateEndpoint={`${baseUrl}/api/v1/definitions/validate`}
              />

              <Button
                className="eds--dark"
                variant="primary"
                onClick={() => setUploadModalVis(true)}
              >
                Upload New Version
              </Button>

              <Button
                id="delete"
                size="small"
                variant="secondary"
                onClick={() => setDeleteModalOpen(true)}
                data-testid="delete"
              >
                <Icon name="Delete" />
                Delete
              </Button>

              <IconButton
                icon="Settings"
                label="Settings"
                variant="secondary"
                onClick={() => setSettingsModalOpen(true)}
              />

              <Modal
                isOpen={deleteModalOpen}
                onDismiss={() => setDeleteModalOpen(false)}
                headerSlot={<Text font="title-3">Confirm Delete</Text>}
                footerSlot={
                  <ButtonGroup>
                    <Button
                      id="confirm-delete"
                      onClick={onDelete}
                      variant="secondary"
                      data-testid="confirm-delete"
                    >
                      <>
                        <Icon name="Delete" size="m" enableFocus />
                        Delete
                      </>
                    </Button>
                  </ButtonGroup>
                }
              >
                <Text>Are you sure you want to delete {definition.name}?</Text>
              </Modal>

              <DefinitionSettingsModal
                definition={definition}
                settingsModalOpen={settingsModalOpen}
                setSettingsModalOpen={setSettingsModalOpen}
              />
            </ButtonGroup>
          ))}
      </Box>

      <Text id="description" font="body-2" data-testid="description" className="eds-spacing--mb-8">
        {definition.description}
      </Text>

      <DefinitionVersionChooser definition={definition} />
    </Box>
  );
}
