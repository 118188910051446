/* eslint-disable no-nested-ternary */
import { AssetSearchResponse } from '@nike.innovation/aurora';
import { DefinitionIO } from '@nike.innovation/composure-sdk';
import {
  TextGroup,
  Button,
  Card,
  Spinner,
  Text,
  Box,
  Table,
  TableCell,
  TableHeading,
} from '@nike/eds';
import axios from 'axios';
import { KeyedMutator } from 'swr';
import { useState, useEffect, useReducer } from 'react';
import { Visualizer, Rhino3dmModel } from '@nike.innovation/visualizer';
import * as THREE from 'three';
import { getEntries } from '../../../solves/definition-solve-result-page';
import { environment } from '../../../../../environments/environment';
import { RhinoInputToComposure } from './input-field';
import ga4Event from '../../../../shared/utils/ga4-helpers/ga4-events';
import { genInitialRunFormState, runFormReducer, RunFormState } from './run-form-reducer';
import useGetInputSettings from './use-get-input-settings';
import { Tabtable, tabsntableTuple } from '../../../../shared/tabtable/tabtable';

export type ComposureInputField =
  | { kind: 'Slider'; min: number; max: number; default: number }
  | { kind: 'Number' }
  | { kind: 'Text' }
  | { kind: 'File' };

export interface RunFormProps {
  definitionId: string;
  io: DefinitionIO;
  versionId: string;
  resultMutate: KeyedMutator<any>;
  authToken: string;
  role: string | undefined;
}

// Allows a user to render a form that can solve against definitions behind composure-api
export function RunForm({
  definitionId,
  io,
  versionId,
  resultMutate,
  authToken,
  role,
}: RunFormProps) {
  const initialFormData: Record<string, any> = io.inputs.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.default }),
    {}
  );
  const [formData, setFormData] = useState(initialFormData);

  const initialFormFileData: Record<string, File> = {};
  const [formFileData, setFormFileData] = useState(initialFormFileData);

  const composureSolveEndpoint = `${environment.apiBaseUrl}/api/v1/definitions/${definitionId}/versions/${versionId}/solve`;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const initialState: RunFormState = genInitialRunFormState(io.inputs);
  const [runFormState, dispatch] = useReducer(runFormReducer, initialState);
  const [solveOutput, setSolveOutput] = useState(null);

  const { inputSettings } = useGetInputSettings(definitionId, versionId, authToken);

  const composureRunPageSettingsEndpoint = `${environment.apiBaseUrl}/api/v1/definitions/${definitionId}/versions/${versionId}/run/settings`;
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  useEffect(() => {
    if (inputSettings) {
      dispatch({ kind: 'UPDATE_FIELD_TYPES', newInputSettings: inputSettings });
    }
  }, [inputSettings]);

  const postInputSettings = (body: string) => {
    axios.post(composureRunPageSettingsEndpoint, body, config);
  };

  const visualizerTab: tabsntableTuple = [
    { label: 'Visualizer', value: 'Visualizer' },
    solveOutput ? (
      <Box className="eds-flex eds-flex-direction-col eds-flex--justify-content-center">
        <Visualizer>
          {getEntries(solveOutput).map((model: string) => (
            <Rhino3dmModel data={model} material={new THREE.MeshBasicMaterial()} />
          ))}
        </Visualizer>
      </Box>
    ) : null,
  ];

  const tableTab: tabsntableTuple = [
    { label: 'Table', value: 'Table' },
    <Box className="eds-grid--col-4">
      <Text font="title-3" className="eds-spacing--mb-8">
        Outputs
      </Text>
      <Table width="100%">
        <thead>
          <tr>
            <TableHeading>Name</TableHeading>
            <TableHeading>Value</TableHeading>
          </tr>
        </thead>
        <tbody>
          {io.outputs.map(row => (
            <tr key={row.name}>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {row.name === 'Geometry'
                  ? 'No Data'
                  : solveOutput
                  ? solveOutput[row.name]
                  : 'No Data'}
              </TableCell>
            </tr>
          ))}
        </tbody>
        ,
      </Table>
    </Box>,
  ];

  const tabsntables = [visualizerTab, tableTab];

  return (
    <Card className="eds-flex eds-gap--32">
      <Box className="eds-flex--order-1">
        <Box>
          <Text font="title-3" className="eds-spacing--mb-16">
            Run Experience
          </Text>

          <Box>
            <form
              onSubmit={e => {
                // Prevent form submission page refresh
                e.preventDefault();
                e.stopPropagation();
                setButtonDisabled(true);

                const fd = new FormData();

                // Add text inputs to FormData
                Object.entries(formData)
                  .filter(([_, v]) => v != null)
                  .forEach(([name, value]) => fd.append(name, value));

                // Add file inputs to FormData
                Object.entries(formFileData)
                  .filter(([_, v]) => v != null)
                  .forEach(([_, value]) => fd.append('files', value));

                axios
                  .post(composureSolveEndpoint, fd, config)
                  .then(x => {
                    resultMutate();
                    setSolveOutput(x.data);
                    ga4Event({ category: 'definitions', action: 'asset_run', label: 'success' });
                    setButtonDisabled(false);
                  })
                  .catch(err => {
                    ga4Event({ category: 'definitions', action: 'asset_run', label: 'failure' });
                    setButtonDisabled(false);
                  });
              }}
            >
              <TextGroup>
                {io.inputs.map(x => (
                  <RhinoInputToComposure
                    formData={formData}
                    onChange={setFormData}
                    formFileData={formFileData}
                    onFileChange={setFormFileData}
                    input={x}
                    runFormState={runFormState}
                    dispatch={dispatch}
                    role={role}
                    postInputSettings={postInputSettings}
                  />
                ))}
              </TextGroup>

              <Box className="eds-spacing--mt-24">
                <Button type="submit" variant="primary" disabled={buttonDisabled}>
                  {!buttonDisabled ? 'Run' : <Spinner />}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>

      <Box className="eds-flex--order-2">
        <Tabtable tabsntables={tabsntables} />
      </Box>
    </Card>
  );
}

export default RunForm;
