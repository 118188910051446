/* eslint-disable dot-notation */
// not playing well with css module styles[*]

import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import { Box, Button, Icon } from '@nike/eds';

import styles from './workflow-step-selector.module.css';
import {
  WorkflowDesignerAction,
  WorkflowDesignerState,
} from '../../core/reducers/workflow-designer-reducer';
import { isStepErroring } from '../../core/validation/step/validate-step';

export function WorkflowStepSelector({
  designerState,
  dispatch,
}: {
  designerState: WorkflowDesignerState;
  dispatch: Dispatch<WorkflowDesignerAction>;
}) {
  const selectStep = (stepIndex: number) => {
    dispatch({ kind: 'SELECT_STEP', stepIndex });
  };

  const addStep = () => {
    const stepId = uuidv4();
    const totalSteps = designerState.steps.length;

    const newStep = {
      id: stepId,
      name: `Step ${totalSteps + 1}`,
      type: 'form',
      form: {},
    };

    dispatch({
      kind: 'ADD_STEP',
      newStep,
    });
  };

  return (
    <Box className="eds-flex eds-flex--align-items-center eds-gap--32">
      <ul className="eds-flex eds-gap--16">
        {designerState.steps.map((step, index) => (
          <li key={step.id}>
            <Button
              onClick={() => selectStep(index)}
              variant="ghost"
              className={classNames(styles['button'], {
                [styles['selected']]: index === designerState.selectedStepIndex,
              })}
              afterSlot={
                isStepErroring(designerState, index) ? (
                  <Icon name="AlertCircle" style={{ color: 'red' }} />
                ) : undefined
              }
            >
              {index !== 0 ? <strong>|</strong> : ''}
              {step.name !== '' ? step.name : 'Empty name'}
            </Button>
          </li>
        ))}
      </ul>

      <Button onClick={addStep} variant="secondary" beforeSlot={<Icon name="Plus" />} size="small">
        Step
      </Button>
    </Box>
  );
}
