import { TextField, Modal, Text } from '@nike/eds';
import { BufferGeometry, Mesh } from 'three';
import { OBJLoader2 } from 'wwobjloader2';
import * as STDLIB from 'three-stdlib';
import { Visualizer, Rhino3dmModel } from '@nike.innovation/visualizer';
import * as THREE from 'three';

export interface FileInputFieldProps {
  name: string;
  // represents the object that will be posted to the `solve` endpoint in composure-api
  formData: any;
  // a react hook closure to update the fields in any object
  onChange: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  formFileData: Record<string, File>;
  onFileChange: React.Dispatch<React.SetStateAction<Record<string, File>>>;
  visualizerModalOpen: boolean;
  setVisualizerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  geometry: string;
  setGeometry: React.Dispatch<React.SetStateAction<string>>;
  isGeometry: boolean;
}

export const parseRhinoGeometry = (inputContent: string, rhino: any): string => {
  const loader = new OBJLoader2().setUseIndices(true);
  const obj = loader.parse(inputContent);
  if (obj.children.length > 0) {
    // Some obj models are comprised of multiple meshes. These are combined here into a single model.
    const geometryArray: any = [];
    obj.children.forEach(child => {
      if (child.type === 'Mesh') {
        const geometryClone = (child as Mesh).geometry.clone();

        // Bake the current child's world transform matrix into the new clone.
        geometryClone.applyMatrix4(child.matrixWorld);

        // Add the cloned geometry into the array.
        geometryArray.push(geometryClone);
      }
    });
    let mergedGeom: any = new BufferGeometry();
    mergedGeom = STDLIB.mergeBufferGeometries(geometryArray);

    // A few steps to transform the obj data into opennurbs format for rhino/gh
    const mergedMesh = new Mesh(mergedGeom);
    const rhinoMesh = rhino.Mesh.createFromThreejsJSON({ data: mergedMesh.geometry });
    const encodedMesh = rhinoMesh.encode();
    return JSON.stringify(encodedMesh);
  }
  return inputContent;
};

export function FileInputField({
  name,
  formData,
  onChange,
  formFileData,
  onFileChange,
  visualizerModalOpen,
  setVisualizerModalOpen,
  geometry,
  setGeometry,
  isGeometry,
}: FileInputFieldProps) {
  const handleUploadDialogSelection = async (event: any) => {
    if (event.target.files.length === 0) {
      return;
    }

    if (event.target.files[0].type === 'model/obj') {
      const reader = new FileReader();
      const rhino3dm = await window.rhino3dm();
      reader.onload = function () {
        const rhinoGeometry = parseRhinoGeometry(reader.result as string, rhino3dm);
        setGeometry(rhinoGeometry);
        onChange({ ...formData, [event.target.id]: rhinoGeometry });
      };
      reader.readAsText(event.target.files[0]);
    }

    onFileChange({ ...formFileData, [event.target.id]: event.target.files[0] });
    onChange({ ...formData, [event.target.id]: event.target.files[0].name });
  };

  return (
    <>
      <TextField
        id={name}
        hasErrors={false}
        label=""
        subtitle=""
        type="file"
        onChange={e => handleUploadDialogSelection(e)}
        accept={isGeometry ? '.obj' : ''}
      />
      <Modal
        isOpen={visualizerModalOpen}
        onDismiss={() => setVisualizerModalOpen(false)}
        headerSlot={<Text font="title-3">{name}</Text>}
      >
        <Visualizer>
          <Rhino3dmModel data={geometry} material={new THREE.MeshBasicMaterial()} />
        </Visualizer>
      </Modal>
    </>
  );
}
