import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Text,
  Box,
  Icon,
  Button,
  ButtonGroup,
  Modal,
  Spinner,
  IconButton,
  Snackbar,
  Snack,
  Tooltip,
} from '@nike/eds';
import { KeyedMutator } from 'swr';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useOktaAuth } from '@okta/okta-react';
import { AssetSearchResponse } from '@nike.innovation/aurora';
import { useApiClient } from '../../shared/hooks/use-api-client';
import useWorkflow from '../core/hooks/use-workflow';
import useDefinitionVersions from '../../shared/hooks/use-definition-versions';
import { DetailsCard } from './workflow-details-card';
import { getUserRole } from '../../shared/utils/entitlement-utils';

import { SendPageView, ga4Event } from '../../shared/utils/ga4-helpers/ga4-events';
import { Breadcrumbs } from '../../shared/breadcrumbs/breadcrumbs';
import { BadRequestError } from '../../shared/bad-request-error';

export function WorkflowDetailsPage({
  definitionSearchResult,
  mutateSearch,
}: {
  definitionSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  const navigate = useNavigate();
  const [showSnack, setShowSnack] = useState(false);
  SendPageView({ page: window.location.pathname, title: 'Workflow Details Page' });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { oktaAuth, authState } = useOktaAuth();
  const apiClient = useApiClient();
  const { workflowId, versionId } = useParams();

  if (!workflowId || !versionId) {
    const missingIds = [];

    if (!workflowId) {
      missingIds.push('workflowId');
    }

    if (!versionId) {
      missingIds.push('versionId');
    }

    throw new BadRequestError(`Malformed url missing: ${missingIds.join(', ')}`);
  }

  const { workflow, isLoading, isError } = useWorkflow(workflowId, versionId);

  const { definitionVersions, versionError } = useDefinitionVersions(
    workflowId,
    `${oktaAuth.getAccessToken()}`
  );

  const role = getUserRole(authState, workflow?.entitlements || []);
  const buttonDisabled = role === 'Viewer';

  const onDelete = async () => {
    if (definitionSearchResult) {
      const newDefinitions = definitionSearchResult.results.filter(x => x.assetId !== workflowId);
      mutateSearch(
        async () => {
          await apiClient.deleteAsset(workflowId || '');

          return undefined;
        },
        {
          optimisticData: {
            ...definitionSearchResult,
            results: newDefinitions,
          },
          revalidate: false,
          populateCache: false,
        }
      );

      ga4Event({
        category: 'workflows',
        action: 'asset_delete',
        label: 'success',
      });

      navigate('/workflows');
    }
  };

  return workflow ? (
    <>
      <Breadcrumbs
        crumbs={[
          { url: '/workflows', name: 'Workflows' },
          { url: '', name: 'Details' },
        ]}
      />

      <Box className="eds-flex eds-flex--justify-content-space-between">
        <Box className="eds-flex eds-gap--8 eds-flex--align-items-center">
          <Text font="title-1" data-testid={workflow.name}>
            {workflow.name}
          </Text>
          <Text
            font="title-2"
            style={{
              backgroundColor: 'var(--eds-color-grey-3)',
              borderRadius: 'var(--eds-radii-4)',
              padding: '2px 10px',
            }}
            data-testid="versionTag"
          >
            {workflow.versionNumber && `V${workflow.versionNumber}`}
          </Text>

          {workflow.isPublic ? (
            <Tooltip bodySlot="Public">
              <Icon name="Unlock" size="l" />
            </Tooltip>
          ) : (
            <Tooltip bodySlot="Private">
              <Icon name="Lock" size="l" />
            </Tooltip>
          )}
        </Box>

        {match(buttonDisabled)
          .with(true, () => (
            <ButtonGroup>
              <Button
                variant="primary"
                disabled
                title="You do not have permission to edit this asset"
                data-testid="upload-new-workflow-version"
              >
                Edit
              </Button>
              <Button
                id="delete"
                size="small"
                variant="secondary"
                disabled
                title="You do not have permission to delete this asset"
                data-testid="delete"
              >
                <Icon name="Delete" />
                Delete
              </Button>
            </ButtonGroup>
          ))
          .otherwise(() => (
            <Box className="eds-grid eds-grid--m-cols-3 eds-gap--8">
              <Button
                className="eds--dark eds-grid--m-col-3"
                variant="primary"
                as={Link}
                to="results/new"
                size="small"
              >
                Run
              </Button>
              <Tooltip bodySlot="Edit Workflow">
                <Button
                  className="eds--dark eds-grid--m-col-1"
                  variant="secondary"
                  as={Link}
                  to="new"
                  size="small"
                  data-testid="upload-new-workflow-version"
                >
                  Edit
                </Button>
              </Tooltip>

              <Tooltip className="eds-grid--m-col-1" bodySlot="Copy to Clipboard">
                <CopyToClipboard text={window.location.href}>
                  <IconButton
                    className="eds-grid--m-col-1"
                    label="Copy to Clipboard"
                    icon="CopyPaste"
                    variant="secondary"
                    size="small"
                    style={{ width: '100%' }}
                    onClick={() => setShowSnack(true)}
                  />
                </CopyToClipboard>
              </Tooltip>

              <Tooltip bodySlot="Delete Workflow">
                <IconButton
                  id="delete"
                  label="delete"
                  size="small"
                  variant="secondary"
                  style={{ width: '100%' }}
                  className="eds-grid--m-col-1"
                  onClick={() => setDeleteModalOpen(true)}
                  data-testid="delete"
                  icon="Delete"
                />
              </Tooltip>

              <Snackbar>
                {showSnack && (
                  <Snack
                    id="1"
                    status="info"
                    autoDismissDuration={2000}
                    onDismiss={id => setShowSnack(false)}
                  >
                    <Text>Link copied to clipboard</Text>
                  </Snack>
                )}
              </Snackbar>

              <Modal
                isOpen={deleteModalOpen}
                onDismiss={() => setDeleteModalOpen(false)}
                headerSlot={<Text font="title-3">Confirm Delete</Text>}
                footerSlot={
                  <ButtonGroup>
                    <Button
                      id="confirm-delete"
                      onClick={onDelete}
                      variant="secondary"
                      data-testid="confirm-delete"
                    >
                      <>
                        <Icon name="Delete" size="m" enableFocus />
                        Delete
                      </>
                    </Button>
                  </ButtonGroup>
                }
              >
                <Text>Are you sure you want to delete {workflow.name}?</Text>
              </Modal>
            </Box>
          ))}
      </Box>

      {definitionVersions ? (
        <DetailsCard workflowId={workflow.id} versionId={versionId || ''} />
      ) : (
        <Box>
          {versionError ? <Text>Could not Fetch definition information</Text> : <Spinner />}
        </Box>
      )}
    </>
  ) : (
    !isLoading && isError && (
      <Box>
        <Text>{isError}</Text>
      </Box>
    )
  );
}

export default WorkflowDetailsPage;
