/* eslint-disable dot-notation */
import {
  BaseFormElementSettings,
  FieldErrors,
  Workflow,
  WorkflowFormElementSettings,
  WorkflowStep,
} from '@nike.innovation/composure-sdk';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { validateFields } from '../validation/field/validate-field';
import { mergeRecords, validateForm } from '../validation/form/validate-form';
import { validateStep } from '../validation/step/validate-step';

// The workflow errs are a list of objects for each step, with each field
// represented by its ID as the key and the list of errs as a string[]
export type WorkflowErrors = {
  stepErrors: string[][];
  fieldsErrors: Record<WorkflowFormElementSettings['id'], FieldErrors>[];
};

export type WorkflowDesignerState = {
  name: string;
  selectedStepIndex?: number;
  selectedFieldId?: string;
  steps: WorkflowStep[];
  errors: WorkflowErrors;
};

const isDuplicateStepName = (step: WorkflowStep, state: WorkflowDesignerState): boolean =>
  state.steps.find(s => step.name === s.name && step.id !== s.id) !== undefined;

// apply rules for step and field validation in workflows
export const validateWorkflow = (state: WorkflowDesignerState): WorkflowDesignerState => {
  const stepErrors = state.steps.map(step => {
    const e = validateStep(step);
    if (isDuplicateStepName(step, state)) {
      e.push('Cannot have duplicate step names.');
    }
    return e;
  });
  const formFieldErrors = state.steps.map(step => {
    const formErrors = validateForm(step.form);
    const fieldErrors = validateFields(Object.values(step.form));
    return mergeRecords(formErrors, fieldErrors);
  });

  return {
    ...state,
    errors: { stepErrors, fieldsErrors: formFieldErrors },
  };
};

// Accepts an existing workflow and will generate the WorkflowDesignerState for editing
export const regenDesignerState = (workflow: Workflow): WorkflowDesignerState =>
  validateWorkflow({
    name: workflow.name,
    selectedStepIndex: 0,
    selectedFieldId: Object.keys(workflow.steps[0].form)[0],
    steps: workflow.steps,
    errors: { stepErrors: [[]], fieldsErrors: [{}] },
  });

// generates the initial state for the workflow designer for a new workflow
export const genInitialDesignerState = (): WorkflowDesignerState => {
  const initialStepId = uuidv4();
  const steps: WorkflowStep[] = [
    {
      id: initialStepId,
      name: 'Step 1',
      type: 'form',
      form: {},
    },
  ];

  return {
    name: `Untitled ${new Date()
      .toLocaleString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(/[/,]/g, '')}`,
    selectedStepIndex: 0,
    steps,
    errors: { stepErrors: [[]], fieldsErrors: [{}] },
  };
};

export type WorkflowDesignerAction =
  | { kind: 'EDIT_WORKFLOW_NAME'; newName: string }
  | { kind: 'ADD_STEP'; newStep: WorkflowStep }
  | { kind: 'DELETE_STEP'; stepIndex: number }
  | { kind: 'SELECT_STEP'; stepIndex: number }
  | { kind: 'EDIT_STEP_NAME'; stepIndex: number; newName: string }
  | { kind: 'ADD_FIELD'; newField: WorkflowFormElementSettings }
  | { kind: 'SELECT_FIELD'; fieldId: string }
  | { kind: 'EDIT_FIELD_NAME'; name: string }
  | { kind: 'TOGGLE_FIELD_REQUIREMENT' }
  | { kind: 'EDIT_FIELD_SETTING'; newSetting: Partial<WorkflowFormElementSettings['settings']> }
  | {
      kind: 'EDIT_FIELD';
      stepIndex: number;
      fieldId: string;
      fieldName: string;
      newSettings: WorkflowFormElementSettings;
    }
  | { kind: 'DELETE_FIELD'; fieldId: string };

export type MatchSettings<T extends BaseFormElementSettings> = T extends infer U
  ? 'settings' extends keyof U
    ? U['settings']
    : never
  : never;

export const workflowDesignerReducer = (
  state: WorkflowDesignerState,
  action: WorkflowDesignerAction
): WorkflowDesignerState =>
  validateWorkflow(
    match(action)
      .with({ kind: 'EDIT_WORKFLOW_NAME' }, refined => ({
        ...state,
        name: refined.newName,
      }))
      .with({ kind: 'ADD_STEP' }, refined => ({
        ...state,
        steps: [...state.steps, refined.newStep],
        selectedStepIndex: state.steps.length,
      }))
      .with({ kind: 'DELETE_STEP' }, ({ stepIndex }) => {
        const newSteps = state.steps.filter((_step, i) => i !== stepIndex);
        const newSelectedStepIndex = stepIndex === 0 ? 0 : stepIndex - 1;

        return {
          ...state,
          selectedStepIndex: newSelectedStepIndex,
          selectedFieldId: undefined,
          steps: newSteps,
        };
      })
      .with({ kind: 'SELECT_STEP' }, ({ stepIndex }) => ({
        ...state,
        selectedStepIndex: stepIndex,
        selectedFieldId: undefined,
      }))
      .with({ kind: 'EDIT_STEP_NAME' }, refined => {
        const steps = state.steps.map((step, index) => {
          if (index === refined.stepIndex) {
            return {
              ...step,
              name: refined.newName,
            };
          }

          return step;
        });

        return { ...state, steps };
      })
      .with({ kind: 'ADD_FIELD' }, ({ newField }) => {
        const steps: WorkflowStep[] = state.steps.map((step, index) => {
          // Add the new field to the current selected step
          if (index === state.selectedStepIndex) {
            return {
              ...step,
              form: {
                ...step.form,
                [newField.id]: newField,
              },
            };
          }

          return step;
        });

        return { ...state, steps, selectedFieldId: newField.id };
      })
      .with({ kind: 'SELECT_FIELD' }, ({ fieldId }) => ({
        ...state,
        selectedFieldId: fieldId,
      }))
      .with({ kind: 'EDIT_FIELD_NAME' }, refined => {
        if (state.selectedFieldId !== undefined && state.selectedStepIndex !== undefined) {
          const selectedField = state.selectedFieldId;

          const newSteps = state.steps.map((step, index) => {
            if (index === state.selectedStepIndex) {
              return {
                ...step,
                form: {
                  ...step.form,
                  [selectedField]: {
                    ...step.form[selectedField],
                    fieldName: refined.name,
                  },
                },
              };
            }
            return step;
          });

          return { ...state, steps: newSteps };
        }

        return state;
      })
      .with({ kind: 'TOGGLE_FIELD_REQUIREMENT' }, () => {
        if (state.selectedFieldId !== undefined && state.selectedStepIndex !== undefined) {
          const selectedField = state.selectedFieldId;

          const newSteps = state.steps.map((step, index) => {
            if (index === state.selectedStepIndex) {
              return {
                ...step,
                form: {
                  ...step.form,
                  [selectedField]: {
                    ...step.form[selectedField],
                    required: !step.form[selectedField].required,
                  },
                },
              };
            }
            return step;
          });

          return { ...state, steps: newSteps };
        }

        return state;
      })
      .with({ kind: 'EDIT_FIELD_SETTING' }, refined => {
        if (state.selectedFieldId !== undefined && state.selectedStepIndex !== undefined) {
          const selectedField = state.selectedFieldId;

          const newSteps = state.steps.map((step, index) => {
            if (index === state.selectedStepIndex) {
              return {
                ...step,
                form: {
                  ...step.form,
                  [selectedField]: {
                    ...step.form[selectedField],
                    settings: {
                      ...step.form[selectedField].settings,
                      ...refined.newSetting,
                    },
                  },
                },
              } as WorkflowStep;
            }
            return step;
          });

          return { ...state, steps: newSteps };
        }

        // if either a step or a field is not selected, simply identity the current state to match UX
        return state;
      })
      .with({ kind: 'EDIT_FIELD' }, ({ stepIndex, fieldId, fieldName, newSettings }) => {
        let steps = state.steps.map((step, index) => {
          if (index === stepIndex) {
            return {
              ...step,
              form: {
                ...step.form,
                [fieldId]: newSettings,
              },
            };
          }

          return step;
        });

        if (newSettings.kind === 'text' && fieldName === 'fieldName') {
          steps = steps.map(step => {
            const newForm: Record<string, WorkflowFormElementSettings> = {};

            Object.entries(step.form).forEach(([id, field]) => {
              if (field.kind === 'file') {
                const newTransform = field.settings.fileTransform.map(transform => {
                  if (
                    transform.kind === 'upstreamTextInputSelection' &&
                    transform.fieldId === fieldId
                  ) {
                    return {
                      ...transform,
                      fieldName: newSettings.fieldName,
                    };
                  }

                  return transform;
                });

                newForm[id] = {
                  ...field,
                  settings: {
                    ...field.settings,
                    fileTransform: newTransform,
                  },
                };
              } else {
                newForm[id] = field;
              }
            });

            return {
              ...step,
              form: newForm,
            };
          });
        }

        return { ...state, steps };
      })
      .with({ kind: 'DELETE_FIELD' }, ({ fieldId }) => {
        const steps = state.steps.map((step, index) => {
          if (index === state.selectedStepIndex) {
            const newStep = {
              ...step,
              form: {
                ...step.form,
              },
            };

            delete newStep.form[fieldId];

            return newStep;
          }

          return step;
        });

        return { ...state, steps, selectedFieldId: undefined };
      })
      .exhaustive()
  );
