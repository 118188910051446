import { Box, Card, Text, Spinner, Icon } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { match } from 'ts-pattern';

import classNames from 'classnames';
import useWorkflow from '../core/hooks/use-workflow';
import { WorkflowVersionTable } from '../../shared/version-table/workflow-version-table';
import { getUserRole } from '../../shared/utils/entitlement-utils';
import WorkflowStepCard from './workflow-step-card';
import { Tabtable, tabsntableTuple } from '../../shared/tabtable/tabtable';
import { AddCollaborators } from '../../shared/permissions/add-collaborators';
import './workflow-details.css';

export interface ColumnHeader {
  Header: string;
}

export interface DetailsCardProps {
  workflowId: string;
  versionId: string;
}

// Defines an EDS table to render the inputs and outputs of a ComputationDefinition
export function DetailsCard({ workflowId, versionId }: DetailsCardProps) {
  // run form parameters
  const { authState } = useOktaAuth();
  const { workflow, isLoading } = useWorkflow(workflowId, versionId);
  const role = getUserRole(authState, workflow?.entitlements || []);

  const overviewTab: tabsntableTuple = [
    {
      label: 'Overview',
      value: 'Overview',
    },
    workflow && (
      <>
        <WorkflowStepCard workflowId={workflowId} versionId={versionId} />

        <Card className="eds-elevation--2" style={{ marginTop: '1em' }}>
          <Text font="title-3" className="eds-spacing--mb-16">
            Versions
          </Text>

          <WorkflowVersionTable assetId={workflowId} currentVersionId={versionId} />
        </Card>

        <Card className="eds-elevation--2" style={{ marginTop: '1em' }}>
          <Text id="name" data-cy="permissions" font="title-3" className="eds-spacing--mb-16">
            Permissions
          </Text>

          <Box
            id="permissions"
            className={classNames(
              'eds-tag',
              { green: role === 'Owner' },
              { yellow: role === 'Editor' },
              { blue: role === 'Viewer' }
            )}
          >
            <Icon name="Personalization" />
            {role ||
              'There was a problem retrieving your role. This problem is only related to the display, and you are able to take all actions you have permissions for.'}
          </Box>

          {workflow && role === 'Owner' && (
            <AddCollaborators asset={workflow} versionId={versionId} />
          )}
        </Card>
      </>
    ),
  ];

  const tabsntables = [overviewTab];

  // TODO: add err handling here
  return match(isLoading)
    .with(false, () => <Tabtable tabsntables={tabsntables} />)
    .otherwise(() => (
      <Card className="eds-elevation--2">
        <Spinner size="large" style={{ margin: 'auto' }} />
      </Card>
    ));
}

export default DetailsCard;
