import useSWR from 'swr';
import { match, P } from 'ts-pattern';
import { environment } from '../../../environments/environment';

/**
 * React hook to search for AD groups with a startsWith string
 * It only calls the API when startsWith has a minumum of 3 characters
 * @param startsWith
 * @param accessToken
 * @returns
 */
export function useADGroups(startsWith: string, accessToken: string) {
  const fetcher = async () => {
    const response = await fetch(`${environment.aegisBaseUrl}/groups?startsWith=${startsWith}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) {
      throw await response.json();
    }

    const adGroups = await response.json();
    return adGroups;
  };

  // only call fetch AD groups when the string has a minimum 3 characters, as it's required by the API
  const shouldFetch = startsWith.length >= 3;

  const { data, error } = useSWR(shouldFetch ? ['oktaADGroups', startsWith] : null, fetcher);

  const errorMessage = match(error)
    .with({ details: { statusCode: 403 } }, () => 'You do not have permission to view this asset')
    .with({ message: P.string }, e => e.message)
    .otherwise(() => undefined);

  return {
    adGroups: data || { items: [] },
    isGroupsLoading: shouldFetch && !error && !data,
    isError: errorMessage,
  };
}
