import { Dispatch } from 'react';
import classNames from 'classnames';

import { Box, Button, Card, Icon, IconButton, TextField } from '@nike/eds';
import { WorkflowStep } from '@nike.innovation/composure-sdk';

import { WorkflowFormElement } from './workflow-form-element';

import styles from './workflow-designer-step.module.css';
import {
  WorkflowDesignerAction,
  WorkflowDesignerState,
} from '../../core/reducers/workflow-designer-reducer';

export function WorkflowDesignerStep({
  designerState,
  dispatch,
  setNewFieldModalVisible,
  selectedStep,
}: {
  designerState: WorkflowDesignerState;
  dispatch: Dispatch<WorkflowDesignerAction>;
  setNewFieldModalVisible: Dispatch<boolean>;
  selectedStep: WorkflowStep;
}) {
  const deleteStep = () => {
    if (designerState.selectedStepIndex !== undefined) {
      dispatch({ kind: 'DELETE_STEP', stepIndex: designerState.selectedStepIndex });
    }
  };

  const editStepName = (newName: string) => {
    if (designerState.selectedStepIndex !== undefined) {
      dispatch({ kind: 'EDIT_STEP_NAME', stepIndex: designerState.selectedStepIndex, newName });
    }
  };

  const stepNameHasErrors = (newName: string) =>
    newName === '' ||
    designerState.steps.find(step => step.name === newName && step.id !== selectedStep.id) !==
      undefined;

  return (
    // eslint-disable-next-line dot-notation
    <Card className={classNames('eds-elevation--2', styles['node'])} padding={36}>
      <>
        <Box className={styles['delete-button']}>
          <IconButton icon="Delete" label="Delete step" variant="ghost" onClick={deleteStep} />
        </Box>

        <Box className="eds-spacing--mb-40 eds-spacing--mr-16">
          <TextField
            id={`workflowNode-${selectedStep.id}`}
            type="text"
            label="Name"
            className={styles['node-name']}
            hasErrors={stepNameHasErrors(selectedStep.name)}
            errorMessage={
              selectedStep.name === '' ? 'Cannot be blank.' : 'Cannot have duplicate step names.'
            }
            placeholder="Name of step in workflow"
            hideLabel
            value={selectedStep.name}
            onChange={e => editStepName(e.target.value)}
            data-testid="workflowStepName"
          />
        </Box>

        {Object.keys(selectedStep.form).map(fieldId => (
          <WorkflowFormElement
            key={fieldId}
            designerState={designerState}
            dispatch={dispatch}
            settings={selectedStep.form[fieldId]}
          />
        ))}

        <Button
          onClick={() => {
            setNewFieldModalVisible(true);
          }}
          variant="secondary"
          beforeSlot={<Icon name="Plus" />}
          data-testid="workflowAddField"
        >
          Field
        </Button>
      </>
    </Card>
  );
}
