import {
  WorkflowFormElementSettings,
  WorkflowResult,
  WorkflowResultStep,
  WorkflowResultStepUserInput,
  WorkflowStep,
} from '@nike.innovation/composure-sdk';
import axios from 'axios';
import { P, match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowDesignerState } from './workflow-designer-reducer';

import { WorkflowOperatorState } from './workflow-operator-reducer';

export const getSelectedFile = (
  stepId: string,
  fieldName: string,
  fileName: string,
  workflowState: WorkflowOperatorState
): File | undefined => {
  const stepIndex = workflowState.workflow.steps.findIndex(step => step.id === stepId);
  const userInput = workflowState.userData[stepIndex][fieldName];

  return match(userInput)
    .with(P.array(P.instanceOf(File)), refined => refined.find(x => x.name === fileName))
    .otherwise(() => undefined);
};

export type PresignedUrlMapping = {
  presignedUrls: [
    {
      stepId: string;
      presignedUrls: {
        [fieldName: string]: { fileName: string; presignedUrl: string }[];
      };
    }
  ];
};

// Simplifies the PresignedUrlMapping data structure to a list of { file, presignedUrl } objects
export const createFileUploadList = (
  uploadMapping: PresignedUrlMapping,
  workflowState: WorkflowOperatorState
) =>
  uploadMapping.presignedUrls
    .flatMap(mapping =>
      Object.entries(mapping.presignedUrls).flatMap(([fieldName, fileNameAndUrl]) =>
        fileNameAndUrl.map(({ fileName, presignedUrl }) => {
          const file = getSelectedFile(mapping.stepId, fieldName, fileName, workflowState);
          return { file, presignedUrl };
        })
      )
    )
    .filter(({ file }) => file !== undefined);

export const createUploadPromises = (
  objects: {
    file: File | undefined;
    presignedUrl: string;
  }[]
) =>
  objects.map(obj => {
    const { file, presignedUrl } = obj;
    return axios.put(presignedUrl, file);
  });

// Helper function to check if a step is complete
export const validateStepCompletion = (
  step: WorkflowStep,
  stepData: Record<string, unknown>
): boolean => {
  const fields = Object.values(step.form);
  return fields.every(
    field =>
      !field.required ||
      (stepData[field.fieldName] !== undefined && stepData[field.fieldName] !== '')
  );
};

// Used to filter a WorkflowResultStep's userInput to only include file inputs
export const filterToFileInputs = (
  userInput: WorkflowResultStep['userInput']
): WorkflowResultStep['userInput'] => {
  // File inputs are denoted with values of shape { fileName: string }[]
  const fileInputs = Object.entries(userInput).filter(([_, fieldValue]) =>
    Array.isArray(fieldValue)
  );
  return Object.fromEntries(fileInputs);
};

// Utility function to convert a FileList to an array of file names
export const extractFileNames = (fileList: FileList): { fileName: string }[] =>
  Array.from(fileList).map(file => ({ fileName: file.name }));

export const parseValue = (value: unknown): WorkflowResultStepUserInput =>
  match(value)
    .with(P.string, refined => refined)
    .with(P.number, refined => refined)
    .with(P.boolean, refined => refined)
    .with(P.array(P.string), refined => refined)
    .with(P.array(P.instanceOf(File)), refined => refined.map(x => ({ fileName: x.name })))
    .with(P.nullish, () => 'N/A') // If an optional field is empty we cast it to 'N/A'
    .with({ value: P.string, label: P.string }, refined => refined.value)
    .otherwise(val => `${val}`);

/**
 * Accepts a stepData object that represents the user's input for a step
 * and parses its values to the proper type
 * // TODO: how do we support user specified transformations?
 * @param stepData
 * @returns Record<string, WorkflowResultStepUserInput>
 */
export const parseStepInput = (
  stepData: Record<string, unknown>
): Record<string, WorkflowResultStepUserInput> =>
  Object.fromEntries(Object.entries(stepData).map(x => [x[0], parseValue(x[1])]));

// Called when an operator completes a workflow
export const generateWorkflowResult = (operator: WorkflowOperatorState): WorkflowResult => {
  const { workflow, userData, startedAt, user } = operator;
  const { id, versionId } = workflow;

  const steps = userData.map((stepData, stepIndex) => {
    const stepId = workflow.steps[stepIndex].id;
    // TODO: handle file transformation here
    const parsedStepData = Object.fromEntries(
      Object.entries(stepData).map(x => [x[0], parseValue(x[1])])
    );

    return {
      stepId,
      user,
      userInput: parsedStepData,
    };
  });

  return {
    id: uuidv4(),
    workflowId: id,
    versionId,
    status: 'completed',
    startedAt,
    completedAt: new Date(),
    steps,
  };
};

/**
 * Accepts a designer state and will use its selected stepId
 * to fetch text fields from previous steps that have required: true
 * @param designerState
 * @returns
 */
export const getAvailableInputFieldsForTransform = (
  designerState: WorkflowDesignerState
): {
  stepId: WorkflowStep['id'];
  fieldId: WorkflowFormElementSettings['id'];
  fieldName: WorkflowFormElementSettings['fieldName'];
}[] => {
  if (designerState.selectedStepIndex !== undefined) {
    const upstreamSteps = designerState.steps.slice(0, designerState.selectedStepIndex + 1);
    const upstreamRequiredTextFields = upstreamSteps
      .flatMap(x =>
        Object.values(x.form).map(a =>
          a.kind === 'text' || a.kind === 'select'
            ? { stepId: x.id, fieldId: a.id, fieldName: a.fieldName }
            : null
        )
      )
      .filter(x => x !== null) as { stepId: string; fieldId: string; fieldName: string }[];

    return upstreamRequiredTextFields;
  }

  return [];
};
