import { match, P } from 'ts-pattern';
import { GrasshopperInputParameter } from '@nike.innovation/composure-sdk';

export interface InputSettings {
  [inputName: string]: string;
}

export interface HopsPromptSettingsObj {
  options: string[];
}

export interface PromptSettings {
  [inputName: string]: HopsPromptSettingsObj;
}

export type RunFormState = {
  inputSettings: InputSettings;
  promptSettings: PromptSettings;
};

export type RunFormAction =
  | { kind: 'EDIT_FIELD_TYPE'; inputName: string; newRenderMethod: string }
  | { kind: 'UPDATE_FIELD_TYPES'; newInputSettings: InputSettings };

export const getRenderTypes = (ghInput: GrasshopperInputParameter): string[] =>
  match(ghInput)
    .with(
      {
        type: 'Number',
        name: P.string,
        minimum: null,
        maximum: P.union(P.number, null),
        step: 'any',
      },
      {
        type: 'Number',
        name: P.string,
        minimum: P.number,
        maximum: null,
      },
      res => ['Number Text', 'Bezier Graph']
    )
    .with(
      {
        type: 'Number',
        isArray: P.boolean,
        minimum: P.number,
        maximum: P.number,
        step: 'any',
      },
      res => ['Number Text', 'Slider', 'Bezier Graph']
    )
    .with(
      {
        type: 'Integer',
        name: P.string,
        minimum: null,
        maximum: P.union(P.number, null),
      },
      {
        type: 'Integer',
        name: P.string,
        minimum: P.number,
        maximum: null,
      },
      res => ['Integer Text']
    )
    .with(
      {
        type: 'Integer',
        isArray: P.boolean,
        minimum: P.number,
        maximum: P.number,
      },
      res => ['Integer Text', 'Slider']
    )
    .with(
      {
        type: 'Text',
        description: P.when(description => description && /.*{.+}/.test(description)),
      },
      res => ['Dropdown']
    )
    .with({ type: 'Text' }, res => ['Text', 'File Input'])
    .with(
      {
        type: 'Boolean',
      },
      res => ['Boolean']
    )
    .with(
      {
        type: P.union('Geometry', 'Mesh'),
      },
      res => ['Geometry Input']
    )
    .otherwise(res => ['Other']);

// Parses the HOPS description string and returns a HopsPromptSettingsObj containing all matched settings if properly formatted
// Otherwise, null is returned
const loadSettings = (description: string | null): HopsPromptSettingsObj | null => {
  const settingsString = description?.match(/\{.+\}/)?.toString();

  const settingsTitles = settingsString?.matchAll(/(\w+):/g);
  const settingsOptions = settingsString?.matchAll(/\[.+?\]/g);

  if (settingsTitles && settingsOptions) {
    const titleArray = Array.from(settingsTitles);
    const optionsArray = Array.from(settingsOptions);
    if (titleArray.length === optionsArray.length && titleArray.length > 0) {
      const obj: HopsPromptSettingsObj = { options: [] };

      titleArray.forEach((titleString, index) => {
        const title = titleString[0].slice(0, -1);
        match(title)
          .with('options', () => {
            const options = optionsArray[index][0]
              .slice(1, -1)
              .split(',')
              .map(x => (x[0] === ' ' ? x.slice(1) : x));
            obj.options = options;
          })

          .otherwise(() => {
            console.log(`No match: ${title}`);
          });
      });

      return obj;
    }
  }

  return null;
};

const genInitialInputSettings = (inputs: GrasshopperInputParameter[]): InputSettings =>
  inputs.reduce(
    (inputSettings, input) => ({
      ...inputSettings,
      [input.name]: getRenderTypes(input)[0],
    }),
    {}
  );

const genInitialPromptSettings = (inputs: GrasshopperInputParameter[]): PromptSettings =>
  inputs
    .filter(input => loadSettings(input.description))
    .reduce(
      (promptSettings, input) => ({
        ...promptSettings,
        [input.name]: loadSettings(input.description),
      }),
      {}
    );

export const genInitialRunFormState = (inputs: GrasshopperInputParameter[]): RunFormState => ({
  inputSettings: genInitialInputSettings(inputs),
  promptSettings: genInitialPromptSettings(inputs),
});

export const runFormReducer = (state: RunFormState, action: RunFormAction): RunFormState =>
  match(action)
    .with({ kind: 'EDIT_FIELD_TYPE' }, refined => ({
      ...state,
      inputSettings: {
        ...state.inputSettings,
        [refined.inputName]: refined.newRenderMethod,
      },
    }))
    .with({ kind: 'UPDATE_FIELD_TYPES' }, refined => {
      if (refined.newInputSettings) {
        const updatedInputSettings = Object.entries(refined.newInputSettings).reduce(
          (acc, curr) => ({
            ...acc,
            [curr[0]]: curr[1],
          }),
          state.inputSettings
        );

        return {
          ...state,
          inputSettings: updatedInputSettings,
        };
      }

      return state;
    })
    .exhaustive();
