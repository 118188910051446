import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';
import { match, P } from 'ts-pattern';

import {
  Text,
  Box,
  Card,
  Spinner,
  Button,
  Modal,
  Table,
  TableHeading,
  TableCell,
  TextField,
  Snack,
  Snackbar,
  Icon,
} from '@nike/eds';

import { AssetSearchResponse } from '@nike.innovation/aurora';
import { Visualizer, Rhino3dmModel } from '@nike.innovation/visualizer';
import * as THREE from 'three';

import { SolveValueModal } from './solve-output-modal';
import './definition-solve-result-page.css';
import { SendPageView } from '../../shared/utils/ga4-helpers/ga4-events';
import Breadcrumbs from '../../shared/breadcrumbs/breadcrumbs';
import { downloadBinaryFile } from '../../shared/utils/file-utils';
import useDefinitionSolve from './use-definition-solve';
import { useGetFileInputs } from './get-file-inputs';
import { DefinitionDetailsHeader } from '../show/definition-details-page/definition-details-header';
import useDefinition from '../../shared/hooks/use-definition';

export interface SaveFileModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
}

function SaveFileModal({ visible, setVisible, value }: SaveFileModalProps) {
  const [fileName, setfileName] = useState('');

  return (
    <Modal
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={
        <Text font="title-3" as="h3">
          Save
        </Text>
      }
      // The Modal FocusTrap prevents the artificial click for downloading the file from propagating.
      disableFocusTrap
      footerSlot={undefined}
    >
      <TextField
        id="savefile"
        hasErrors={false}
        label="Filename:"
        onChange={e => setfileName(e.target.value)}
      />
      <Button
        className="eds--dark eds-spacing--my-16"
        variant="primary"
        size="small"
        disabled={fileName.length === 0}
        onClick={() => {
          const blob = new Blob([value]);
          downloadBinaryFile(blob, fileName);
          setVisible(false);
        }}
      >
        Save
      </Button>
    </Modal>
  );
}

export const rebuildFile = (base64String: string, mimeType: string): Blob => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], {
    type: mimeType,
  });
};

export function getEntries(items: any): string[] {
  return Object.entries(items).flatMap(([key, values]) => values as string);
}

export function SolveResultPage({
  definitionSearchResult,
  mutateSearch,
}: {
  definitionSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  const { definitionId, versionId, timestamp } = useParams();

  SendPageView({ page: window.location.pathname, title: 'Definition Solve Page' });

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const { definition } = useDefinition(definitionId || '', versionId || '', accessToken);

  const { definitionSolve, defSolveLoading, useDefSolveErr } = useDefinitionSolve(
    definitionId,
    versionId,
    timestamp,
    accessToken
  );

  const inputFiles = useGetFileInputs(definitionId, versionId, timestamp, accessToken);

  const [valueModalVis, setValueModalVis] = useState(false);
  const [expandedValue, setExpandedValue] = useState('');
  const [saveFileModalVis, setSaveFileModalVis] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(JSON.stringify(definitionSolve.input));
    setIsClicked(true);
  };

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { url: '/definitions', name: 'Grasshopper Definitions' },
          { url: `/definitions/${definitionId}/versions/${versionId}`, name: 'Details' },
          { url: '', name: 'Result' },
        ]}
      />

      {definition && (
        <DefinitionDetailsHeader
          definition={definition}
          definitionSearchResult={definitionSearchResult}
          mutateSearch={mutateSearch}
        />
      )}

      <SolveValueModal
        visible={valueModalVis}
        setVisible={setValueModalVis}
        setSave={setSaveFileModalVis}
        value={expandedValue}
      />

      <SaveFileModal
        visible={saveFileModalVis}
        setVisible={setSaveFileModalVis}
        value={expandedValue}
      />

      <Card className="" style={{ marginTop: '1em' }}>
        <Text font="title-1" className="eds-spacing--mb-8">
          Run Result
        </Text>
        {match({ definitionSolve, defSolveLoading, useDefSolveErr })
          .with({ defSolveLoading: P.when(loading => loading) }, () => <Spinner size="large" />)
          .with({ useDefSolveErr: P.when(err => err) }, () => (
            <Text font="title-3">No definition found</Text>
          ))
          .otherwise(() => (
            <>
              <Text font="subtitle-1" className="eds-spacing--mb-16">
                Execution Time:{' '}
                {definitionSolve.executionTime
                  ? `${definitionSolve.executionTime / 1000} seconds`
                  : 'Not Recorded'}
              </Text>
              <Box className="eds-grid eds-grid--m-cols-8">
                <Box className="eds-grid--col-4">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text font="title-3" className="eds-spacing--mb-8">
                      Inputs
                    </Text>
                    <Button
                      variant="secondary"
                      afterSlot={<Icon name="CopyPaste" size="m" />}
                      style={{ padding: '5px' }}
                      onClick={handleCopy}
                    >
                      Copy Inputs
                    </Button>
                    <Snackbar>
                      {isClicked && (
                        <Snack
                          id="1"
                          onDismiss={id => setIsClicked(false)}
                          autoDismissDuration={2000}
                          status="success"
                        >
                          <p className="eds-type--body-2">Copied to Clipboard!</p>
                        </Snack>
                      )}
                    </Snackbar>
                  </div>
                  <Table width="100%">
                    <thead>
                      <tr>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Value</TableHeading>
                        <TableHeading>Download</TableHeading>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.entries(definitionSolve.input).map(row => (
                        <tr key={row[0]}>
                          {/* TODO: make this passable to the caller, which set of fields do you want to render from the asset search? Also, how best to describe value for complex inputs */}
                          <TableCell>{String(row[0].replace('RH_IN:', ''))}</TableCell>
                          <TableCell>
                            {String(JSON.stringify(row[1])).length > 20 ? (
                              <Button
                                className="eds--dark"
                                variant="primary"
                                size="small"
                                onClick={() => {
                                  setValueModalVis(true);
                                  setExpandedValue(String(JSON.stringify(row[1])));
                                }}
                              >
                                View Input
                              </Button>
                            ) : (
                              <Text>{String(row[1])}</Text>
                            )}
                          </TableCell>
                          <TableCell>
                            {Object.keys(inputFiles).includes(row[0]) ? (
                              <Button
                                size="small"
                                onClick={() => {
                                  downloadBinaryFile(
                                    rebuildFile(
                                      inputFiles[row[0]].content,
                                      inputFiles[row[0]].mimeType
                                    ),
                                    inputFiles[row[0]].fileName
                                  );
                                }}
                              >
                                Download
                              </Button>
                            ) : (
                              <Button size="small" disabled className="disabled">
                                Download
                              </Button>
                            )}
                          </TableCell>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Box>
                <Box className="eds-grid--col-4">
                  <Text font="title-3" className="eds-spacing--mb-8">
                    Outputs
                  </Text>
                  <Table width="100%">
                    <thead>
                      <tr>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Value</TableHeading>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.entries(definitionSolve.output).map(row => (
                        <tr key={row[0]}>
                          {/* TODO: make this passable to the caller, which set of fields do you want to render from the asset search? */}
                          <TableCell>{String(row[0])}</TableCell>
                          <TableCell>
                            {String(row[1]).length > 20 ? (
                              <>
                                <Button
                                  className="eds--dark"
                                  variant="primary"
                                  size="small"
                                  onClick={() => {
                                    setExpandedValue((row[1] as string[]).join('\r\n'));
                                    setValueModalVis(true);
                                  }}
                                >
                                  View Output
                                </Button>
                                <Button
                                  className="eds--dark"
                                  variant="primary"
                                  size="small"
                                  onClick={() => {
                                    setExpandedValue((row[1] as string[]).join('\r\n'));
                                    setSaveFileModalVis(true);
                                  }}
                                >
                                  Download File
                                </Button>
                              </>
                            ) : (
                              <Text>{String(row[1])}</Text>
                            )}
                          </TableCell>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Box>
              </Box>
              <Box className="eds-flex eds-flex-direction-col eds-flex--justify-content-center">
                <Visualizer>
                  {getEntries(definitionSolve.output).map((model: string) => (
                    <Rhino3dmModel data={model} material={new THREE.MeshBasicMaterial()} />
                  ))}
                </Visualizer>
              </Box>
            </>
          ))}
      </Card>
    </>
  );
}

export default SolveResultPage;
