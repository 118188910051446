import { Text, Table, TableCell, TableHeading, IconButton, Modal } from '@nike/eds';
import { match, P } from 'ts-pattern';
import { Workflow, WorkflowResultStep, WorkflowResult } from '@nike.innovation/composure-sdk';
import { getFileFromWorkflow } from '../../shared/utils/file-utils';
import { WorkflowResultStatusLabel, convertDateToDisplayString } from './workflow-result-table';
import './workflow-results.css';
import { environment } from '../../../environments/environment';

export interface WorkflowResultModalProps {
  // TODO props updated, new type probably needed.
  workflow: Workflow;
  workflowResult: WorkflowResult;
  rowModalVis: boolean;
  setRowModalVis: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
}

/* Function for button below
function downloadResult(workflowResult: WorkflowResult) {
  const fileName = workflowResult.id;
  const json = JSON.stringify(workflowResult, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
*/

/* Button that downloads entire run to JSON file if that is interesting
<Button
    beforeSlot={<Icon name="Download" />}
    size="small"
    onClick={() => {downloadResult(workflowResult)}}
    style={{ marginBottom: '0.5em' }}
>
    Download Result
</Button>
*/

function WorkflowStepCell(
  { workflowId, versionId, id }: WorkflowResult,
  { userInput, stepId }: WorkflowResultStep,
  token: string
) {
  return (
    <Table width="100%">
      <thead>
        <tr>
          <TableHeading>Name</TableHeading>
          <TableHeading>Value</TableHeading>
        </tr>
      </thead>

      <tbody>
        {Object.entries(userInput).map(io => (
          <tr key={io[0]}>
            {match(io[1])
              .with(P.array({ fileName: P.string }), f => (
                <>
                  <TableCell>{io[0]}</TableCell>
                  <TableCell>
                    <div>
                      {f.map(fn => (
                        <Text key={fn.fileName}>{fn.fileName}</Text>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      label="download"
                      size="small"
                      variant="primary"
                      onClick={() => {
                        const baseUrl = environment.apiBaseUrl;
                        f.forEach(fileInput => {
                          const contentUrl = `${baseUrl}/api/v1/workflows/${workflowId}/versions/${versionId}/results/${id}/steps/${stepId}/${io[0]}/${fileInput.fileName}`;
                          getFileFromWorkflow(contentUrl, fileInput.fileName, token);
                        });
                      }}
                      icon="Download"
                    />
                  </TableCell>
                </>
              ))
              .with(P.string, P.number, P.boolean, P.array(P.string), () => (
                <>
                  <TableCell>{io[0]}</TableCell>
                  <TableCell>{String(io[1])}</TableCell>
                  <TableCell />
                </>
              ))
              .otherwise(() => 'otherwise clause')}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default function ResultModal({
  workflow,
  workflowResult,
  rowModalVis,
  setRowModalVis,
  token,
}: WorkflowResultModalProps) {
  return (
    <Modal
      headerSlot={
        <Text font="title-3" style={{ marginTop: '1em', marginBottom: '0.5em' }}>
          Result ID: {workflowResult.id}
        </Text>
      }
      isOpen={rowModalVis}
      onDismiss={() => setRowModalVis(false)}
    >
      <Text font="legal-1" style={{ marginBottom: '0.25em' }}>
        User: {workflowResult.steps[0].user}
      </Text>
      <Text font="legal-1" style={{ marginBottom: '0.25em' }}>
        Started At: {convertDateToDisplayString(workflowResult.startedAt)}
      </Text>
      <Text font="legal-1" style={{ marginBottom: '0.25em' }}>
        Completed At:{' '}
        {workflowResult.completedAt ? convertDateToDisplayString(workflowResult.completedAt) : '--'}
      </Text>
      <>
        <Text
          font="legal-1"
          style={{ marginBottom: '1em', marginRight: '1em', display: 'inline-block' }}
        >
          Status:
        </Text>
        <div style={{ display: 'inline-block' }}>
          {WorkflowResultStatusLabel(workflowResult.status)}
        </div>
      </>
      <hr />
      <div className="result-table-wrap">
        <Table width="100vw" className="">
          <thead>
            <tr>
              {workflow.steps.map(step => (
                <TableHeading key={step.id}>{step.name}</TableHeading>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {workflowResult.steps.map(step => (
                <TableCell key={step.stepId} className="result-cell">
                  {WorkflowStepCell(workflowResult, step, token)}
                </TableCell>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </Modal>
  );
}
