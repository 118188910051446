import { useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Spinner, Text } from '@nike/eds';
import { BadRequestError } from '../../../shared/bad-request-error';
import { WorkflowDesigner } from '../../shared/designer/workflow-designer';
import useWorkflow from '../../core/hooks/use-workflow';

export function NewWorkflowVersionPage() {
  const { workflowId, versionId } = useParams();
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token in NewWorkflowVersionPage');
  }

  if (!workflowId || !versionId) {
    const missingIds = [];

    if (!workflowId) {
      missingIds.push('workflowId');
    }

    if (!versionId) {
      missingIds.push('versionId');
    }

    throw new BadRequestError(`Malformed url missing: ${missingIds.join(', ')}`);
  }

  const { workflow, isLoading } = useWorkflow(workflowId, versionId);

  if (isLoading) {
    return <Spinner />;
  }

  if (workflow) {
    return <WorkflowDesigner workflow={workflow} />;
  }

  return <Text>There was a problem rendering the designer for this workflow</Text>;
}
