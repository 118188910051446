/* eslint-disable react/no-unknown-property */
import { Object3D, MathUtils } from 'three';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Rhino3dmLoader, OBJLoader } from 'three-stdlib';
import * as THREE from 'three';

import { PropsWithChildren, useEffect, useState } from 'react';

export function OBJModel({ data }: { data: string }) {
  function convertData() {
    const loader = new OBJLoader();
    const threedobject = loader.parse(data);
    return threedobject;
  }

  return <primitive object={convertData()} />;
}

export function Rhino3dmModel({ data, material }: { data: string; material: THREE.Material }) {
  const [renderState, setRenderState] = useState<Object3D>();

  async function convertData() {
    let obj = null;
    try {
      obj = JSON.parse(data);
    } catch (e) {
      // string but not JSON
    }

    if (!obj || !obj.opennurbs) {
      return;
    }

    const rhino = await window.rhino3dm();
    const doc = new rhino.File3dm();
    const mesh = rhino.CommonObject.decode(obj);
    doc.objects().add(mesh, null);

    const { buffer } = new Uint8Array(doc.toByteArray());
    const loader = new Rhino3dmLoader();
    loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@8.0.0-beta/');

    loader.parse(buffer, o => {
      //
      // due to different coordinate systems between GH and ThreeJS
      // everything must be rotated -90 degrees.
      //
      o.rotateX(MathUtils.degToRad(-90));
      o.traverse(c => {
        if (c instanceof THREE.Mesh) {
          // eslint-disable-next-line no-param-reassign
          c.material = material;
        }
      });
      setRenderState(o);
    });
  }

  useEffect(() => {
    convertData();
  }, [data]);

  return renderState && <primitive object={renderState} />;
}

export function Cube() {
  return (
    <mesh>
      <boxGeometry args={[2, 2, 2]} />
    </mesh>
  );
}

export function Sphere() {
  return (
    <mesh position={[-4, 0, 0]}>
      <sphereGeometry args={[1]} />
    </mesh>
  );
}

export function Visualizer({ children }: PropsWithChildren) {
  return (
    <Canvas style={{ background: '#FFFFFF', height: '768px', width: '1024px' }}>
      <PerspectiveCamera makeDefault position={[0, 5, 10]} />
      <OrbitControls />
      <ambientLight intensity={0.4} />
      <pointLight position={[10, 10, 10]} decay={0} intensity={1} />
      {children}
    </Canvas>
  );
}
