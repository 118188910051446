import axios from 'axios';
import { SolveInputMetadata } from '@nike.innovation/composure-sdk';
import { useState, useEffect } from 'react';

import { environment } from '../../../environments/environment';

/**
 * React hook for
 * @param assetId
 * @param versionId
 * @param token
 * @returns
 */
export const useGetFileInputs = (
  assetId: string | undefined,
  versionId: string | undefined,
  timestamp: string | undefined,
  token: string
): SolveInputMetadata => {
  const [files, setFiles] = useState<SolveInputMetadata>({});

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const linksKey = `api/v1/definitions/${assetId}/versions/${versionId}/solves/${timestamp}/files`;

    async function fetchFiles() {
      try {
        const linksResponse = await axios.get(`${environment.apiBaseUrl}/${linksKey}`, config);
        const links = linksResponse.data;
        const inputFiles: any = {};
        const promises = Object.entries(links).map(async ([inputName, link]) => {
          const response = await axios.get(`${environment.apiBaseUrl}/${link}`, config);
          inputFiles[inputName] = response.data;
        });
        await Promise.all(promises);
        setFiles(inputFiles);
      } catch (error) {
        // handle error here
      }
    }

    fetchFiles();
  }, [assetId, versionId, timestamp, token]);

  return files;
};
