import JSZip from 'jszip';
import { Workflow, WorkflowResult } from '@nike.innovation/composure-sdk';
import { environment } from '../../../environments/environment';
import { downloadBinaryFile } from '../../shared/utils/file-utils';
import { getWorkflowResultData, WorkflowResultAsText } from './workflow-result-table';

//
// Returns the current time in the format: YYYY-MM-DD--HH-MM-SS
//
function GetCurrentTimeFormatted() {
  const currentDateTime = new Date();
  const currentYear = currentDateTime.getFullYear();
  const currentMonth = (currentDateTime.getMonth() + 1).toString().padStart(2, '0');
  const currentDate = currentDateTime.getDate().toString().padStart(2, '0');
  const currentHour = currentDateTime.getHours().toString().padStart(2, '0');
  const currentMinute = currentDateTime.getMinutes().toString().padStart(2, '0');
  const currentSecond = currentDateTime.getSeconds().toString().padStart(2, '0');
  return `${currentYear}-${currentMonth}-${currentDate}--${currentHour}-${currentMinute}-${currentSecond}`;
}

//
// Construct the headers for the .csv file. There are two levels of headers used, a group and field
// which are combined for the headers in the .csv file. For example a group header of 'Step 1' and
// a field header of 'Operator' would be combined into 'Step 1-Operator'
//
export function constructCSVHeaders(workflow: Workflow) {
  const headers = [];

  // Metadata headers are constructed explicitly as they are not part of a step
  headers.push(`Metadata-RunIndex`);
  headers.push(`Metadata-RunUUID`);
  headers.push(`Metadata-User`);
  headers.push(`Metadata-StartedAt`);
  headers.push(`Metadata-CompletedAt`);
  headers.push(`Metadata-Status`);

  // Iterate over the fields in each step and add them as headers.
  workflow.steps.forEach(step => {
    const groupHeader = workflow.steps.find(x => x.id === step.id)?.name ?? step.id;

    Object.entries(step.form).forEach(entry => {
      headers.push(`${groupHeader}-${entry[1].fieldName}`);
    });
  });

  return headers.join(',');
}

//
// Construct a data row for the .csv file. A row corresponds to a single run of the
// workflow.
//
export function constructCSVRow(workflow: Workflow, index: number, row: WorkflowResult) {
  const rowText = [];
  const workflowRunIndex = index + 1;

  // The metadata values are added explicitly as they are not part of a step
  rowText.push(`${workflowRunIndex}`);
  rowText.push(`${row.id}`);
  rowText.push(`${row.steps[0].user}`);
  rowText.push(`${row.startedAt}`);
  rowText.push(`${row.completedAt}`);
  rowText.push(`${row.status}`);

  workflow.steps.forEach(step => {
    Object.entries(step.form).forEach(field => {
      const result = getWorkflowResultData(step.id, field[1].fieldName, row);
      const textResult = WorkflowResultAsText(result);

      // textResult will always be an array if the field is of kind 'file' but
      // Typescript doesn't know that so narrow the type here so we can iterate.
      if (field[1].kind === 'file' && Array.isArray(textResult)) {
        rowText.push(textResult.join(';'));
      } else {
        rowText.push(textResult);
      }
    });
  });

  return rowText.join(',');
}

export function constructCSV(workflow: Workflow, rows: WorkflowResult[]) {
  const csvRows = [];

  csvRows.push(constructCSVHeaders(workflow));

  rows.forEach((row, index) => {
    csvRows.push(constructCSVRow(workflow, index, row));
  });

  return csvRows.join('\n');
}

export async function exportWorkflowResults(
  workflow: Workflow,
  versionNumber: string,
  rows: WorkflowResult[],
  token: string
) {
  const fileDownloadPromises: Promise<Response | void>[] = [];
  const zipArchive = new JSZip();

  const csvRows = constructCSV(workflow, rows);
  zipArchive.file('results.csv', csvRows);

  //
  // Retrieve all files that were uploaded during the runs of the workflow. Similar to
  // constructing the .csv, we iterate over each row (workflow run) and then each field of
  // each step. If the field is a 'file' type field then request that file(s).
  //
  rows.forEach((row, index) => {
    const workflowRunIndex = index + 1;

    workflow.steps.forEach(step => {
      Object.entries(step.form).forEach(field => {
        const result = getWorkflowResultData(step.id, field[1].fieldName, row);
        const textResult = WorkflowResultAsText(result);

        // textResult will always be an array if the field is of kind 'file' but
        // Typescript doesn't know that so narrow the type here so we can iterate.
        if (field[1].kind === 'file' && Array.isArray(textResult)) {
          textResult.forEach(fileName => {
            const baseUrl = environment.apiBaseUrl;
            const workflowId = workflow.id;
            const stepId = step.id;
            const stepName = step.name;
            const { fieldName } = field[1];
            const contentUrl = `${baseUrl}/api/v1/workflows/${workflowId}/versions/${workflow.versionId}/results/${row.id}/steps/${stepId}/${fieldName}/${fileName}`;
            fileDownloadPromises.push(
              fetch(contentUrl, { headers: { Authorization: `Bearer ${token}` } })
                .then(resp => resp.text())
                .then(presignedUrl =>
                  fetch(presignedUrl)
                    .then(resp => resp.blob())
                    .then(blob => {
                      zipArchive.file(`${workflowRunIndex}/${stepName}/${fileName}`, blob);
                    })
                )
            );
          });
        }
      });
    });
  });
  await Promise.all(fileDownloadPromises);

  //
  // After all the files have been received do the final archive
  // generation and trigger the download in the browser.
  //
  return zipArchive.generateAsync({ type: 'blob' }).then(content => {
    const currentTimeString = GetCurrentTimeFormatted();
    downloadBinaryFile(content, `${workflow.name}-v${versionNumber}--${currentTimeString}.zip`);
  });
}
