import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { match } from 'ts-pattern';
import { Box, Text } from '@nike/eds';
import { oktaAuth } from './shared/utils/okta-auth';
import AppRoutes from './app-routes';
import { VerticalNav } from './shared/vertical-nav/vertical-nav';
import { environment } from '../environments/environment';

import '@nike/eds/dist/index.css';
import '../assets/fonts.css';
import './app.css';

const trackingId = match(window.location.host)
  .with('composure-test.nike.io', () => 'G-Z7HH3FKS93')
  .with('composure.nike.io', () => 'G-G27EFHG47M')
  .otherwise(() => 'G-F03JSEKVJZ');
ReactGA.initialize(trackingId);

const inTest = !environment.production;

export function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  useEffect(() => {
    if (inTest) document.title = 'Test: Composure';
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <main>
        <Box
          className="App eds-flex eds-flex--direction-row eds-flex--align-items-stretch"
          style={{ height: '100%', overflowY: 'hidden' }}
        >
          <VerticalNav />

          <Box style={{ width: '100vw', minHeight: '100vh', overflowY: 'auto' }}>
            <Box
              className="eds-spacing--px-56 eds-spacing--my-32"
              maxWidth="1536px"
              ml="auto"
              mr="auto"
            >
              <AppRoutes />
            </Box>

            {inTest && (
              <Text className="eds-type--display-5 eds-color--grey-1 watermark">
                Test Environment
              </Text>
            )}
          </Box>
        </Box>
      </main>
    </Security>
  );
}

export default App;
