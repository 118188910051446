import { Card, Tab, TabGroup } from '@nike/eds';
import React, { ReactElement, useState } from 'react';

export interface ColumnHeader {
  Header: string;
}

export interface TabCardProps {
  // Array of tuples that contain the tabs, and their relevant components in JSX
  tabs: [{ value: string; label: string }, ReactElement][];
  currentTab: string;
}

export type tabsTuple = [{ value: string; label: string }, ReactElement];

/**
 * Allows you to create a tabulated Card of content
 * @param  - [{value: string, label: string}, JSX.Element][] - tab config w/ any nested components
 * @returns
 */
export function TabCard({ tabs, currentTab }: TabCardProps) {
  const [activeIndex, setActiveIndex] = useState('0');

  return (
    <Card>
      <TabGroup
        name="tab-card"
        activeId={currentTab}
        onChange={e => {
          setActiveIndex(e.target.value);
        }}
      >
        {tabs.map((x, i) => (
          <Tab key={x[0].value} value={i.toString()} id={x[0].value}>
            {x[0].label}
          </Tab>
        ))}
      </TabGroup>
      {tabs[parseInt(activeIndex, 6)][1]}
    </Card>
  );
}

export default TabCard;
