import useSWR from 'swr';
import { environment } from '../../../environments/environment';

export type DefinitionSolveTableRow = {
  user: string;
  status: 'succeeded' | 'failed';
  sk: number;
  warnings: string[] | [];
  errors: string[] | [];
};

/**
 * React hook for
 * @param assetId
 * @param versionId
 * @param token
 * @returns
 */
export function useWorkflowResultsQuery(assetId: string, versionId: string, token: string) {
  const key = `api/v1/workflows/${assetId}/versions/${versionId}/results?limit=150`;

  const fetcher = async () => {
    const response = await fetch(`${environment.apiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw await response.json();
    }
    const results = await response.json();
    return results;
  };

  const { data, error, mutate } = useSWR(key, fetcher);
  return {
    workflowResults: data,
    resultsLoading: !error && !data,
    resultsFetchErr: error,
    resultMutate: mutate,
  };
}

export default useWorkflowResultsQuery;
