import { WorkflowStep } from '@nike.innovation/composure-sdk';
import { WorkflowDesignerState } from '../../reducers/workflow-designer-reducer';
import { isFieldErroring } from '../field/validate-field';

export type WorkflowStepValue = WorkflowStep[keyof WorkflowStep];

export const isNotEmpty = (a: WorkflowStepValue): string | undefined => {
  if (typeof a === 'string') {
    return a.length === 0 ? 'cannot be empty.' : undefined;
  }

  if (typeof a === 'object') {
    return Object.entries(a).length === 0 ? 'cannot be empty.' : undefined;
  }

  return undefined;
};

export type StepValidationRules = {
  [K in keyof WorkflowStep]?: (a: WorkflowStepValue) => string | undefined;
};

export const stepValidationRules: StepValidationRules = {
  name: isNotEmpty,
};

// Accepts a workflow step and returns an array of validation errors
// If the array is empty, the step is valid
export const validateStep = (step: WorkflowStep): string[] =>
  Object.entries(stepValidationRules)
    .map(([field, rule]) => {
      const formattedField = field.charAt(0).toUpperCase() + field.slice(1);
      const validationResult = rule(step[field as keyof WorkflowStep]);
      if (validationResult) {
        return `${formattedField} ${validationResult}`;
      }
      return undefined;
    })
    .filter(item => item !== undefined) as string[];

export const isStepErroring = (state: WorkflowDesignerState, index: number): boolean => {
  const stepError = state.errors.stepErrors[index];
  if (stepError.length > 0) {
    return true;
  }

  return Object.values(state.errors.fieldsErrors[index]).some(isFieldErroring);
};
