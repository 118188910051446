/* eslint-disable no-return-await */
import useSWR from 'swr';
import { match, P } from 'ts-pattern';

import { Workflow } from '@nike.innovation/composure-sdk';

import { useApiClient } from '../../../shared/hooks/use-api-client';

export function useWorkflow(
  assetId: string,
  assetVersionId: string
): {
  workflow: Workflow | undefined;
  isLoading: boolean;
  isError: any;
} {
  const apiClient = useApiClient();

  const { data, error } = useSWR(['workflow', assetId, assetVersionId], () =>
    apiClient.getWorkflowVersion(assetId, assetVersionId)
  );

  if (data?.name === undefined) {
    return {
      workflow: undefined,
      isLoading: true,
      isError: new Error('Could not parse name out of workflow'),
    };
  }

  const errorMessage = match(error)
    .with({ details: { statusCode: 403 } }, () => 'You do not have permission to view this asset')
    .with({ message: P.string }, e => e.message)
    .otherwise(() => undefined);

  return {
    workflow: { ...data, id: assetId, versionId: assetVersionId },
    isLoading: !error && !data,
    isError: errorMessage,
  };
}

export default useWorkflow;
