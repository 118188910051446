import { useOktaAuth } from '@okta/okta-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { NavigateOptions, URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@nike.innovation/ui-components';

export interface PaginationParams {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions
  ) => void;
  from: number;
  setFrom: Dispatch<SetStateAction<number>>;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  filterCurrentUser: boolean;
  setFilterCurrentUser: Dispatch<SetStateAction<boolean>>;
  createdByUserId?: string;
  searchValue?: string;
  setSearchValue: Dispatch<SetStateAction<string | undefined>>;
  debouncedSearchTerm?: string;
}

/**
 * React hook for grouping pagination state together
 * @returns paginationParams
 */
export function usePaginationParams(defaults: { pageSize?: number } = {}): PaginationParams {
  const { pageSize = 10 } = defaults;
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [from, setFrom] = useState(Number(searchParams.get('from')) || 0);
  const [size, setSize] = useState(Number(searchParams.get('size')) || pageSize);
  const [searchValue, setSearchValue] = useState<string | undefined>(
    searchParams.get('searchValue') || undefined
  );
  const [filterCurrentUser, setFilterCurrentUser] = useState(
    searchParams.get('currentUser') !== 'false'
  );
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const createdByUserId = filterCurrentUser
    ? oktaAuth.token.decode(accessToken).payload.sub
    : undefined;

  return {
    searchParams,
    setSearchParams,
    from,
    setFrom,
    size,
    setSize,
    filterCurrentUser,
    setFilterCurrentUser,
    createdByUserId,
    searchValue,
    setSearchValue,
    debouncedSearchTerm,
  };
}
