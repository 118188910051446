import { useState } from 'react';
import { VerticalNavbar, Icon, setActiveValueInNavItems, NavItem, NavEvent } from '@nike/eds';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation, useNavigate } from 'react-router';
import './vertical-nav.css';

const homeEvent = {
  id: 'home',
  label: 'Home',
  active: true,
};

const retrieveResourceFromPath = (pathname: string) => {
  if (pathname.includes('definitions')) {
    return 'definitions';
  }

  if (pathname.includes('workflows')) {
    return 'workflows';
  }

  if (pathname.includes('midsoles')) {
    return 'midsoles';
  }

  if (pathname.includes('help')) {
    return 'help';
  }

  return 'home';
};

export function VerticalNav() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const location = useLocation();

  const activeResource = retrieveResourceFromPath(location.pathname);
  const [topItems, setTopItems] = useState<NavItem[]>([
    {
      id: 'compute',
      icon: 'Effort',
      label: 'Grasshopper',
      active: activeResource === 'definitions',
    },
    {
      id: 'workflows',
      icon: 'Repeat',
      label: 'Workflows',
      active: activeResource === 'workflows',
    },
    {
      id: 'midsoles',
      icon: 'Shoe',
      label: 'Midsoles',
      active: activeResource === 'midsoles',
    },
  ]);

  const [bottomItems, setBottomItems] = useState<NavItem[]>([
    {
      id: 'help',
      icon: 'QuestionCircle',
      label: 'Get Help',
      active: activeResource === 'help',
    },
  ]);

  const onNavigate = (item: NavEvent) => {
    const newItems = setActiveValueInNavItems(item.id, topItems);
    const newBottomItems = setActiveValueInNavItems(item.id, bottomItems);
    if (newItems !== undefined) {
      setTopItems(newItems);
    }
    if (newBottomItems !== undefined) {
      setBottomItems(newBottomItems);
    }

    switch (item.id) {
      case 'compute':
        navigate('/definitions');
        break;
      case 'workflows':
        navigate('/workflows');
        break;
      case 'midsoles':
        navigate('/midsoles');
        break;
      case 'help':
        navigate('/help');
        break;
      default:
        break;
    }
  };

  return (
    <div className="eds--layers-40">
      {authState?.isAuthenticated ? (
        <VerticalNavbar
          appName="Composure"
          items={topItems}
          bottomItems={bottomItems}
          onNavigate={onNavigate}
          navLogoSlot={
            <Link to="/" data-testid="home-button" onClick={() => onNavigate(homeEvent)}>
              <Icon name="NikeSwoosh" size="l" className="nike-shoe-icon" />
            </Link>
          }
        />
      ) : (
        <VerticalNavbar
          appName="Composure"
          items={[]}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onNavigate={() => {}}
          navLogoSlot={
            <Link to="/">
              <Icon name="NikeSwoosh" size="l" className="nike-shoe-icon" />
            </Link>
          }
        />
      )}
    </div>
  );
}

export default VerticalNav;
