import { Slider } from '@mui/material';
import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { Box, Button, Select, Text, TextField, Toggle } from '@nike/eds';
import { match } from 'ts-pattern';
import { Dispatch, useState } from 'react';
import { WorkflowOperatorAction } from '../core/reducers/workflow-operator-reducer';
import { isDateInPastOrFuture, isDateValid } from '../shared/date/date-utils';

export function OperatorFormElement({
  input,
  state,
  onChange,
}: {
  input: WorkflowFormElementSettings;
  state: any;
  onChange: Dispatch<WorkflowOperatorAction>;
}) {
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    input.kind === 'slider' ? input.settings.default : 0
  );

  return (
    <Box className="eds-spacing--mb-32">
      {match(input)
        .with({ kind: 'slider' }, refined => (
          <Box>
            <Box className="eds-flex">
              <Text font="title-6">{refined.fieldName}</Text>
              <Text font="title-5">{refined.required ? '*' : ''}</Text>
              <Text font="title-6" style={{ paddingLeft: '2%' }}>
                {sliderValue}
              </Text>
            </Box>
            <Slider
              className="nodrag"
              name={refined.fieldName}
              min={refined.settings.minimum}
              max={refined.settings.maximum}
              defaultValue={refined.settings.default}
              step={refined.settings.step}
              valueLabelDisplay="off"
              marks={[
                { value: refined.settings.minimum, label: refined.settings.minimum },
                { value: refined.settings.maximum, label: refined.settings.maximum },
              ]}
              onChange={(e, value) => {
                setSliderValue(value);
                onChange({ kind: 'SET_USER_INPUT', newUserData: { [refined.fieldName]: value } });
              }}
            />
          </Box>
        ))
        .with({ kind: 'number' }, refined => (
          <Box>
            <Box className="eds-flex">
              <Text font="title-6">{refined.fieldName}</Text>
              <Text font="title-5">{refined.required ? '*' : ''}</Text>
            </Box>
            <TextField
              type="number"
              id={refined.fieldName}
              label=""
              defaultValue={refined.settings.default}
              onChange={e =>
                onChange({
                  kind: 'SET_USER_INPUT',
                  newUserData: { [refined.fieldName]: e.target.value },
                })
              }
            />
          </Box>
        ))
        .with({ kind: 'text' }, refined => (
          <Box>
            <Box className="eds-flex">
              <Text font="title-6">{refined.fieldName}</Text>
              <Text font="title-5">{refined.required ? '*' : ''}</Text>
            </Box>
            <TextField
              id={refined.fieldName}
              type="text"
              label=""
              message={refined.settings.message}
              placeholder={refined.settings.placeholder}
              required={refined.required}
              value={state}
              onChange={e => {
                const { value } = e.target;
                onChange({ kind: 'SET_USER_INPUT', newUserData: { [refined.fieldName]: value } });
              }}
            />
          </Box>
        ))
        .with({ kind: 'select' }, refined => (
          <Box>
            <Box className="eds-flex">
              <Text font="title-6">{refined.fieldName}</Text>
              <Text font="title-5">{refined.required ? '*' : ''}</Text>
            </Box>
            <Select
              id={refined.fieldName}
              required={refined.required}
              options={refined.settings.options as any}
              label=""
              onChange={e =>
                onChange({ kind: 'SET_USER_INPUT', newUserData: { [refined.fieldName]: e } })
              }
            />
          </Box>
        ))
        .with({ kind: 'toggle' }, refined => (
          <Box style={{ marginTop: '1em', marginBottom: '1em' }}>
            <Toggle
              id={refined.fieldName}
              label={refined.fieldName}
              onChange={() =>
                onChange({ kind: 'SET_USER_INPUT', newUserData: { [refined.fieldName]: !state } })
              }
            />
          </Box>
        ))
        .with({ kind: 'file' }, refined => {
          if (state === undefined) {
            const acceptsMessage = `Accepts ${
              refined.settings.accepts === '' ? '*' : refined.settings.accepts
            }`;
            const message =
              refined.settings.message === ''
                ? acceptsMessage
                : `${refined.settings.message}. ${acceptsMessage}`;

            return (
              <Box>
                <Box className="eds-flex">
                  <Text font="title-6">{refined.fieldName}</Text>
                  <Text font="title-5">{refined.required ? '*' : ''}</Text>
                </Box>
                <TextField
                  id={refined.fieldName}
                  label=""
                  type="file"
                  accept={refined.settings.accepts}
                  required={refined.required}
                  multiple={refined.settings.acceptMultiple}
                  message={message}
                  onChange={e => {
                    const { files } = e.target;
                    onChange({
                      kind: 'SET_USER_INPUT',
                      newUserData: { [refined.fieldName]: files },
                    });
                  }}
                />
              </Box>
            );
          }
          // This is to get around the browser limitation of controlled file inputs
          return (
            <Box mb="0.5em">
              <Box className="eds-flex">
                <Text font="title-6" className="eds-spacing--mb-24">
                  {refined.fieldName}
                </Text>
                <Text font="title-5">{refined.required ? '*' : ''}</Text>
              </Box>
              {/* eslint-disable-next-line react/destructuring-assignment */}
              <Text className="eds-spacing--mb-16">{state.length} file(s)</Text>
              <Button
                variant="secondary"
                size="small"
                onClick={() =>
                  onChange({
                    kind: 'SET_USER_INPUT',
                    newUserData: { [refined.fieldName]: undefined },
                  })
                }
              >
                Clear
              </Button>
            </Box>
          );
        })
        .with({ kind: 'date' }, refined => (
          <Box>
            <Box className="eds-flex">
              <Text font="title-6">{refined.fieldName}</Text>
              <Text font="title-5">{refined.required ? '*' : ''}</Text>
            </Box>
            <TextField
              id={refined.id}
              type={refined.settings.withLocalTime ? 'datetime-local' : 'date'}
              label=""
              onChange={e => {
                console.log('change', e.target.value);
                console.log('when is the selected date? ', isDateInPastOrFuture(e.target.value));
                if (
                  isDateValid({
                    allowPast: refined.settings.allowPast,
                    allowFuture: refined.settings.allowFuture,
                    selectedDate: e.target.value,
                  })
                ) {
                  onChange({
                    kind: 'SET_USER_INPUT',
                    newUserData: { [refined.fieldName]: e.target.value },
                  });
                }
              }}
              value={state}
            />
          </Box>
        ))
        .exhaustive()}
    </Box>
  );
}
