import { Box, Button, Text, TextField, Label, Toggle, Icon, Select } from '@nike/eds';
import { GrasshopperInputParameter } from '@nike.innovation/composure-sdk';
import { useState, useEffect } from 'react';
import { match } from 'ts-pattern';
import { Slider } from '@mui/material';
import { InputTitle } from './input-title';
import { InputDescription } from './input-description';
import { SettingsCog } from './settings-cog';
import { BezierCurveEditor } from './bezier-curve/bezier-curve-editor';
import { FileInputField } from './file-input-field';
import { RunFormAction, RunFormState } from './run-form-reducer';

export function RhinoInputToComposure({
  formData,
  onChange,
  formFileData,
  onFileChange,
  input,
  runFormState,
  dispatch,
  role,
  postInputSettings,
}: {
  formData: Record<string, any>;
  onChange: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  formFileData: Record<string, File>;
  onFileChange: React.Dispatch<React.SetStateAction<Record<string, File>>>;
  input: GrasshopperInputParameter;
  runFormState: RunFormState;
  dispatch: React.Dispatch<RunFormAction>;
  role: string | undefined;
  postInputSettings: (body: string) => void;
}) {
  const calculateMinMax = (min: number | null, max: number | null, value: number) => {
    const currentValue = min !== null ? Math.max(min, value) : value;
    return max !== null ? Math.min(max, currentValue) : currentValue;
  };

  const [visualizerModalOpen, setVisualizerModalOpen] = useState(false);
  const [geometry, setGeometry] = useState('');

  const [sliderValue, setSliderValue] = useState<number | number[]>(
    input.type === 'Number' &&
      typeof input.isArray === 'boolean' &&
      typeof input.minimum === 'number' &&
      typeof input.maximum === 'number' &&
      typeof input.default === 'number'
      ? input.default
      : 0
  );

  useEffect(() => {
    if (input.type === 'Boolean' && input.default === null)
      onChange({ ...formData, [input.name]: false });
  }, []);

  return (
    <Box>
      {match(runFormState.inputSettings[input.name])
        .with('Text', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <TextField
              id={input.name}
              hasErrors={false}
              label=""
              subtitle=""
              onChange={e => onChange({ ...formData, [e.target.id]: e.target.value })}
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Number Text', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <TextField
              id={input.name}
              hasErrors={false}
              label=""
              subtitle=""
              onChange={e => onChange({ ...formData, [e.target.id]: e.target.value })}
              type="number"
              step="any"
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Integer Text', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <TextField
              id={input.name}
              hasErrors={false}
              label=""
              subtitle=""
              onChange={e => onChange({ ...formData, [e.target.id]: e.target.value })}
              type="number"
              value={formData[input.name]}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  let value = Number(e.currentTarget.value);
                  if (input.type === 'Integer') {
                    value = Math.floor(value);
                  }
                  onChange({
                    ...formData,
                    [input.name]: calculateMinMax(input.minimum, input.maximum, value),
                  });
                }
              }}
              onBlur={e => {
                let value = Number(e.target.value);
                if (input.type === 'Integer') {
                  value = Math.floor(value);
                }
                onChange({
                  ...formData,
                  [input.name]: calculateMinMax(input.minimum, input.maximum, value),
                });
              }}
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Slider', res => (
          <Box>
            {input.minimum !== null && input.maximum !== null ? (
              <Box>
                <Box className="eds-flex eds-flex-direction-row">
                  <Box>
                    <Label font="title-6">{input.name.replace('RH_IN:', '')}</Label>
                    <Label className="eds-label eds-color--text-secondary eds-type--body-3">
                      {input.type}
                    </Label>
                  </Box>
                  <SettingsCog
                    input={input}
                    runFormState={runFormState}
                    dispatch={dispatch}
                    role={role}
                    postInputSettings={postInputSettings}
                  />
                  <Label
                    font="title-6"
                    style={{
                      paddingLeft: '2%',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    {sliderValue}
                  </Label>
                </Box>
                <Slider
                  step={(input.maximum - input.minimum) / 100.0} // make it so there are 100 steps
                  min={input.minimum}
                  max={input.maximum}
                  name={input.name}
                  value={formData[input.name]}
                  valueLabelDisplay="off"
                  onChange={(e: any) => {
                    onChange({ ...formData, [e.target.name]: e.target.value });
                    setSliderValue(e.target.value);
                  }}
                  aria-labelledby="input-slider"
                  marks={[
                    {
                      value: input.minimum,
                      label: input.minimum,
                    },
                    { value: input.maximum, label: input.maximum },
                  ]}
                />
                <InputDescription description={input.description} />
              </Box>
            ) : (
              <Box>
                <Label font="title-6">{input.name.replace('RH_IN:', '')}</Label>
                <Label className="eds-label eds-color--text-secondary eds-type--body-3">
                  {input.type}
                </Label>
                <Text>Missing either the minimum or maximum</Text>
              </Box>
            )}
          </Box>
        ))
        .with('Boolean', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <Toggle
              id={input.name}
              label=""
              size="large"
              onChange={e => {
                const newValue = !formData[input.name];
                onChange({ ...formData, [input.name]: newValue });
              }}
              checked={formData[input.name]}
              className="eds-spacing--mt-16"
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Bezier Graph', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <BezierCurveEditor name={input.name} kind="" formData={formData} onChange={onChange} />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('File Input', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <FileInputField
              name={input.name}
              formData={formData}
              onChange={onChange}
              formFileData={formFileData}
              onFileChange={onFileChange}
              visualizerModalOpen={visualizerModalOpen}
              setVisualizerModalOpen={setVisualizerModalOpen}
              geometry={geometry}
              setGeometry={setGeometry}
              isGeometry={false}
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Geometry Input', res => (
          <Box>
            <Box className="eds-flex eds-flex-direction-row eds-flex--align-items-center eds-gap--16">
              <InputTitle
                input={input}
                runFormState={runFormState}
                dispatch={dispatch}
                role={role}
                postInputSettings={postInputSettings}
              />
              <Button
                size="small"
                onClick={() => {
                  setVisualizerModalOpen(true);
                  console.log(geometry);
                }}
                variant="secondary"
                disabled={geometry === ''}
              >
                Preview
              </Button>
            </Box>
            <FileInputField
              name={input.name}
              formData={formData}
              onChange={onChange}
              formFileData={formFileData}
              onFileChange={onFileChange}
              visualizerModalOpen={visualizerModalOpen}
              setVisualizerModalOpen={setVisualizerModalOpen}
              geometry={geometry}
              setGeometry={setGeometry}
              isGeometry
            />
            <InputDescription description={input.description} />
          </Box>
        ))
        .with('Dropdown', res => (
          <Box>
            <InputTitle
              input={input}
              runFormState={runFormState}
              dispatch={dispatch}
              role={role}
              postInputSettings={postInputSettings}
            />
            <Select
              id={input.name}
              options={
                runFormState.promptSettings[input.name] &&
                // eslint-disable-next-line dot-notation
                Array.isArray(runFormState.promptSettings[input.name]['options'])
                  ? // eslint-disable-next-line dot-notation
                    runFormState.promptSettings[input.name]['options'].map(x => ({
                      value: x,
                      label: x,
                    }))
                  : undefined
              }
              label=""
              subtitle=""
              required="false"
              onChange={e => onChange({ ...formData, [input.name]: e?.value })}
            />
            <InputDescription description={input.description?.replace(/{.+}/, '')} />
          </Box>
        ))
        .otherwise(res => (
          <Box mb="3em" mt="2em" style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="Null" size="l" />
            <Text font="title-6">
              Can&apos;t render an input for {input.name} of type {input.type}
            </Text>
          </Box>
        ))}
    </Box>
  );
}
