import { Aurora, AuroraBaseUrl } from '@nike.innovation/aurora';
import useSWR from 'swr';
import { environment } from '../../../../environments/environment';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

/**
 * React hook for a search result page for Midsole
 * @param from
 * @param size
 * @param active
 * @param token
 * @returns
 */
export function useMidsoleVersions(midsoleId: string, token: string) {
  const { data, error } = useSWR(`/midsoles/${midsoleId}`, async () =>
    aurora.Midsole.getAllVersions(midsoleId, token)
  );
  return { midsoleVersions: data, versionsAreLoading: !error && !data, versionError: error };
}

export default useMidsoleVersions;
