import { Aurora, AuroraBaseUrl } from '@nike.innovation/aurora';
import useSWR from 'swr';
import { match, P } from 'ts-pattern';
import { environment } from '../../../../environments/environment';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

/**
 * React hook for a search result page for Midsoles
 * @param assetId
 * @param assetVersionId
 * @param token
 * @returns
 */
export function useMidsole(assetId: string, assetVersionId: string, token: string) {
  const fetcher = match(assetVersionId)
    .with('latest', () => async () => aurora.Midsole.getAssetLatest(assetId, token))
    .otherwise(() => async () => aurora.Midsole.getAssetVersion(assetId, assetVersionId, token));

  const { data, error } = useSWR([assetId, assetVersionId], fetcher);

  const errorMessage = match(error)
    .with({ details: { statusCode: 403 } }, () => 'You do not have permission to view this asset')
    .with({ message: P.string }, e => e.message)
    .otherwise(() => undefined);

  return { midsole: data, isLoading: !error && !data, isError: errorMessage };
}

export default useMidsole;
