import {
  FieldErrors,
  WorkflowFormElementSettings,
  WorkflowStep,
} from '@nike.innovation/composure-sdk';

function mergeSettings(
  a: Record<string, string[] | undefined>,
  b: Record<string, string[] | undefined>
) {
  const allKeys = [...new Set([...Object.keys(a), ...Object.keys(b)])];

  return allKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: [...(a[key] || []), ...(b[key] || [])],
    }),
    {} as Record<string, string[]>
  );
}

function mergeRecordFields(a: FieldErrors, b: FieldErrors): FieldErrors {
  return {
    fieldName: [...(a.fieldName || []), ...(b.fieldName || [])],
    settings: mergeSettings(a.settings || {}, b.settings || {}),
  };
}

export function mergeRecords(
  a: Record<WorkflowFormElementSettings['id'], FieldErrors>,
  b: Record<WorkflowFormElementSettings['id'], FieldErrors>
): Record<WorkflowFormElementSettings['id'], FieldErrors> {
  const allKeys: WorkflowFormElementSettings['id'][] = [
    ...new Set([...Object.keys(a), ...Object.keys(b)]),
  ];

  return allKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: mergeRecordFields(a[key] || {}, b[key] || {}),
    }),
    {} as Record<WorkflowFormElementSettings['id'], FieldErrors>
  );
}

// validate all field names are unqiue
export const isNotDuplicateName = (
  form: WorkflowStep['form']
): Record<WorkflowFormElementSettings['id'], FieldErrors> => {
  const names = new Set<string>();

  return Object.entries(form).reduce((acc, [id, field]) => {
    if (names.has(field.fieldName)) {
      return {
        ...acc,
        [id]: {
          fieldName: [`'${field.fieldName}' is already in use in the step`],
        },
      };
    }

    names.add(field.fieldName);
    return acc;
  }, {});
};

// Accepts a form in a workflowstep
export const validateForm = (
  form: WorkflowStep['form']
): Record<WorkflowFormElementSettings['id'], FieldErrors> => isNotDuplicateName(form);
