import useSWR from 'swr';

import { Aurora, AuroraBaseUrl, AssetSearchOptions } from '@nike.innovation/aurora';
import { environment } from '../../../environments/environment';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

/**
 * React hook for a search result page for ComputationDefinition
 * @param options
 * @param token
 * @returns
 */
export function useDefinitionSearch(options: AssetSearchOptions, token: string) {
  const { data, error, mutate } = useSWR({ ...options, assetClass: 'definitions' }, async () =>
    aurora.ComputationDefinition.search({ ...options, active: true }, token)
  );

  return {
    definitionSearchResult: data,
    searchIsLoading: !error && !data,
    definitionSearchErr: error,
    mutate,
  };
}

export default useDefinitionSearch;
