import { DefinitionIO, GrasshopperIO } from '@nike.innovation/composure-sdk';
import { Box, Card, Table, TableCell, TableHeading, Text } from '@nike/eds';

import { ApiUrlCard } from './api-url-card';
import { InputParameterTable } from '../../../shared/input-parameter-table';

export function ApiTab({
  definitionId,
  versionId,
  io,
}: {
  definitionId: string;
  versionId: string;
  io: DefinitionIO | undefined;
}) {
  return (
    <>
      <ApiUrlCard definitionId={definitionId} versionId={versionId} />

      <Card className="eds-elevation--2">
        <Text font="title-3" className="eds-spacing--mb-16">
          Script Metadata
        </Text>

        {io ? (
          <>
            <Box className="eds-spacing--mb-16">
              <Text font="title-4" className="eds-spacing--mb-8">
                Inputs
              </Text>
              <InputParameterTable io={io} />
            </Box>

            <Box className="eds-spacing--mb-16">
              <Text font="title-4" className="eds-spacing--mb-8">
                Outputs
              </Text>

              <Table width="100%">
                <thead>
                  <tr>
                    <TableHeading key="name">Name</TableHeading>
                    <TableHeading key="type">Type</TableHeading>
                  </tr>
                </thead>

                <tbody>
                  {io.outputs.map(row => (
                    <tr key={row.name}>
                      {/* TODO: make this passable to the caller, which set of fields do you want to render from the asset search? */}
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.type}</TableCell>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </>
        ) : (
          <Text> No Metadata Found</Text>
        )}
      </Card>
    </>
  );
}
