import { Aurora, AuroraBaseUrl } from '@nike.innovation/aurora';
import useSWR from 'swr';
import { environment } from '../../../environments/environment';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

/**
 * React hook for a search result page for ComputationDefinition
 * @param from
 * @param size
 * @param active
 * @param token
 * @returns
 */
export function useDefinitionVersions(definitionId: string, token: string) {
  const { data, error, mutate } = useSWR(definitionId, async () =>
    aurora.ComputationDefinition.getAllVersions(definitionId, token)
  );
  return {
    definitionVersions: data,
    versionsAreLoading: !error && !data,
    versionError: error,
    mutate,
  };
}

export default useDefinitionVersions;
