import { Box, Card, Spinner, Text } from '@nike/eds';
import { useParams } from 'react-router-dom';
import useWorkflow from '../core/hooks/use-workflow';
import { OperatorForm } from './operator-form';

export function NewWorkflowResultPage() {
  const { workflowId, versionId } = useParams();

  if (workflowId === '' || versionId === '' || !workflowId || !versionId) {
    throw new Error('Workflow ID and Version ID are required');
  }

  const { workflow, isLoading, isError } = useWorkflow(workflowId, versionId);

  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (isError) {
    return <Text>There was a problem fetching/completing the workflow {isError}</Text>;
  }

  if (workflow) {
    return (
      <>
        <div className="eds-grid eds-grid--m-cols-4">
          <Box className="eds-grid--m-col-3" mt="5em">
            <Text font="title-1">{workflow.name}</Text>
          </Box>
        </div>

        <Card style={{ marginTop: '1em', minHeight: '20em' }}>
          {!isLoading && <OperatorForm workflow={workflow} />}
        </Card>
      </>
    );
  }

  return <Text>There was a problem fetching this workflow</Text>;
}
