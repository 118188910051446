/* eslint-disable dot-notation */
import { Dispatch } from 'react';
import { Slider } from '@mui/material';
import { match } from 'ts-pattern';
import classNames from 'classnames';

import { Box, IconButton, TextField, Select, Toggle, Text, Label } from '@nike/eds';
import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';

import {
  WorkflowDesignerAction,
  WorkflowDesignerState,
} from '../../core/reducers/workflow-designer-reducer';
import { getFieldErrors, isFieldErroring } from '../../core/validation/field/validate-field';

import styles from './workflow-form-element.module.css';

export function WorkflowFormElement({
  designerState,
  dispatch,
  settings,
}: {
  designerState: WorkflowDesignerState;
  dispatch: Dispatch<WorkflowDesignerAction>;
  settings: WorkflowFormElementSettings;
}) {
  const { id: fieldId } = settings;

  const deleteField = () => {
    dispatch({ kind: 'DELETE_FIELD', fieldId });
  };
  const fieldErrors = getFieldErrors(designerState, fieldId);

  return (
    <Box
      className={classNames(styles['field-element'], {
        [styles['error']]: isFieldErroring(fieldErrors),
        [styles['selected']]: designerState.selectedFieldId === fieldId,
      })}
      onClick={() => {
        dispatch({ kind: 'SELECT_FIELD', fieldId });
      }}
    >
      <Box className={styles['delete-button']}>
        <IconButton icon="Delete" label="Delete field" variant="ghost" onClick={deleteField} />
      </Box>

      {match(settings)
        .with({ kind: 'text' }, refined => (
          <TextField
            id={refined.id}
            type="text"
            label={refined.fieldName}
            message={refined.settings.message}
            value={refined.settings.default}
            placeholder={refined.settings.placeholder}
            required={refined.required}
          />
        ))
        .with({ kind: 'slider' }, refined => (
          <Box>
            <Label htmlFor="input-slider">{refined.fieldName}</Label>
            <Text font="legal-1">Preview</Text>

            <Slider
              step={refined.settings.step}
              min={0}
              max={10}
              name={refined.fieldName}
              defaultValue={2}
              marks
              aria-labelledby="input-slider"
            />
          </Box>
        ))
        .with({ kind: 'number' }, refined => (
          <TextField id={refined.id} hasErrors={false} label={refined.fieldName} type="number" />
        ))
        .with({ kind: 'select' }, refined => (
          <Select
            id={refined.id}
            options={refined.settings.options}
            label={refined.fieldName}
            required={refined.required}
          />
        ))
        .with({ kind: 'toggle' }, refined => (
          <Toggle
            id={refined.id}
            label={refined.fieldName}
            size="large"
            checked={refined.settings.default}
          />
        ))
        .with({ kind: 'file' }, refined => (
          <TextField
            id={refined.id}
            type="file"
            accept={refined.settings.accepts}
            label={refined.fieldName}
            subtitle={refined.settings.message}
            disabled
            data-cy="file-upload-workflow"
          />
        ))
        .with({ kind: 'date' }, refined => (
          <TextField
            id={refined.id}
            type={refined.settings.withLocalTime ? 'datetime-local' : 'date'}
            label={refined.fieldName}
          />
        ))
        .exhaustive()}
    </Box>
  );
}
