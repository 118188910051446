import { Dispatch, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Box, Button, Modal, Text, TextField, ToggleButton } from '@nike/eds';
import { FileNameTransform, fileNameTransformToDisplayText } from '@nike.innovation/composure-sdk';
import { match } from 'ts-pattern';
import { WorkflowDesignerAction } from '../../core/reducers/workflow-designer-reducer';

export interface FileNameModalProps {
  availableTransformFields: { stepId: string; fieldId: string; fieldName: string }[];
  dispatch: Dispatch<WorkflowDesignerAction>;
  transforms: FileNameTransform[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const runVariables: { [key: string]: string } = {
  Date: new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-'),
  Time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
  User: 'tempUser',
  UUID: uuid(),
};

const dividers = ['-', '_', '^', '.'];

const getFormattedFileName = (fileName: string) => {
  let formattedFileName = fileName;
  Object.keys(runVariables).forEach((x: string) => {
    formattedFileName = formattedFileName.replace(new RegExp(`{${x}}`, 'g'), runVariables[x]);
  });
  return formattedFileName;
};

const transformsToText = (transformArray: FileNameTransform[]) =>
  transformArray.map(x => fileNameTransformToDisplayText(x));

export function FileNameForm({
  availableTransformFields,
  dispatch,
  transforms,
  isOpen,
  setIsOpen,
}: FileNameModalProps) {
  const [customVariable, setCustomVariable] = useState<string>('');

  return (
    <Modal
      isOpen={isOpen}
      headerSlot={<Text font="title-4">Rename Uploaded File</Text>}
      onDismiss={() => {
        setIsOpen(false);
      }}
      style={{ minHeight: '60em' }}
    >
      <Box>
        <Text font="subtitle-1"> Preview </Text>
        <Text style={{ fontStyle: 'italic' }} data-testid="filename-preview">
          {' '}
          {getFormattedFileName(transformsToText(transforms).join(''))}{' '}
        </Text>

        <Box style={{ marginTop: '30px', border: '2px solid LightGray' }}>
          {transforms.map((x: FileNameTransform, index: number) => (
            <ToggleButton
              style={{ margin: '5px' }}
              onClick={() => {
                transforms.splice(index, 1);
                if (transforms.length <= 0) {
                  dispatch({
                    kind: 'EDIT_FIELD_SETTING',
                    newSetting: { fileTransform: [...transforms, { kind: 'fileName' }] },
                  });
                } else {
                  dispatch({
                    kind: 'EDIT_FIELD_SETTING',
                    newSetting: { fileTransform: [...transforms] },
                  });
                }
              }}
            >
              {fileNameTransformToDisplayText(x)}
            </ToggleButton>
          ))}
        </Box>

        <Text font="subtitle-1" style={{ marginTop: '30px' }}>
          {' '}
          Custom Text
        </Text>

        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
          <TextField
            id="text-field"
            label=""
            hideLabel
            onChange={x => {
              setCustomVariable(x.target.value);
            }}
            value={customVariable}
            data-testid="text-field"
          />
          <Button
            style={{ marginLeft: '10px' }}
            variant="secondary"
            onClick={() => {
              const formattedVariable = customVariable.replace(/\s/g, '_');
              if (formattedVariable) {
                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: {
                    fileTransform: [...transforms, { kind: 'customText', text: formattedVariable }],
                  },
                });
                setCustomVariable('');
              }
            }}
            data-testid="insert-button"
          >
            Insert
          </Button>
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
          <Box style={{ marginRight: '20px' }}>
            <Text font="subtitle-1"> Dividers</Text>
            {dividers.map(value => (
              <Button
                variant="secondary"
                style={{ margin: '5px' }}
                onClick={() => {
                  dispatch({
                    kind: 'EDIT_FIELD_SETTING',
                    newSetting: {
                      fileTransform: [...transforms, { kind: 'divider', divider: value }],
                    },
                  });
                }}
              >
                {value}
              </Button>
            ))}
          </Box>

          <Box style={{ marginRight: '20px' }}>
            <Text font="subtitle-1"> System Variables </Text>
            <Button
              variant="secondary"
              style={{ margin: '5px' }}
              onClick={() => {
                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: { fileTransform: [...transforms, { kind: 'fileName' }] },
                });
              }}
            >
              filename
            </Button>
            <Button
              variant="secondary"
              style={{ margin: '5px' }}
              onClick={() => {
                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: { fileTransform: [...transforms, { kind: 'fileExtension' }] },
                });
              }}
            >
              extension
            </Button>

            {Object.keys(runVariables).map(value => (
              <Button
                variant="secondary"
                style={{ margin: '5px' }}
                onClick={() => {
                  match(value)
                    .with('Date', () =>
                      dispatch({
                        kind: 'EDIT_FIELD_SETTING',
                        newSetting: { fileTransform: [...transforms, { kind: 'currentDate' }] },
                      })
                    )
                    .with('Time', () =>
                      dispatch({
                        kind: 'EDIT_FIELD_SETTING',
                        newSetting: { fileTransform: [...transforms, { kind: 'currentTime' }] },
                      })
                    )
                    .with('User', () =>
                      dispatch({
                        kind: 'EDIT_FIELD_SETTING',
                        newSetting: { fileTransform: [...transforms, { kind: 'user' }] },
                      })
                    )
                    .with('UUID', () =>
                      dispatch({
                        kind: 'EDIT_FIELD_SETTING',
                        newSetting: { fileTransform: [...transforms, { kind: 'uuid' }] },
                      })
                    )
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    .otherwise(() => {});
                }}
              >
                {value}
              </Button>
            ))}
          </Box>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Text font="subtitle-1">Available User Input</Text>
          {availableTransformFields.map(value => (
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: {
                    fileTransform: [
                      ...transforms,
                      {
                        kind: 'upstreamTextInputSelection',
                        stepId: value.stepId,
                        fieldId: value.fieldId,
                        fieldName: value.fieldName,
                      },
                    ],
                  },
                });
              }}
            >
              {value.fieldName}
            </Button>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}

export default FileNameForm;
