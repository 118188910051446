import { Text, Card, Link, Box } from '@nike/eds';

interface HomeCardProps {
  title: string;
  description: string[];
  link: string;
}

export const cards: HomeCardProps[] = [
  {
    title: 'Grasshopper Definitions',
    description: [
      'Scale up your use of Grasshopper across and beyond your team with self serve applications from the browser.',
    ],
    link: '/definitions',
  },
  {
    title: 'Workflows',
    description: [
      'Quickly create repeatable business processes that can automate data capture and integrate with external services without having to write code.',
    ],
    link: '/workflows',
  },
];

function HomeCard({ title, description, link }: HomeCardProps) {
  return (
    <Card key={title} className="eds-grid--m-col-1 home-card">
      <Box style={{ marginBottom: '1em' }}>
        <Link
          href={link}
          font="title-1"
          className="eds-color--black"
          style={{ border: 0 }}
          data-testid={`${title.toLowerCase()}-home-button`}
        >
          {title}
        </Link>
      </Box>

      {description.map(line => (
        <Text style={{ marginTop: '0.5em' }}>{line}</Text>
      ))}
    </Card>
  );
}

// Landing page for the composure app. Contains Cards with links to other pages.
export default function HomePage() {
  return (
    <>
      <Text font="title-1" style={{ marginBottom: '1em' }}>
        Composure
      </Text>

      <div className="eds-grid eds-grid--m-cols-2">{cards.map(card => HomeCard(card))}</div>
    </>
  );
}
