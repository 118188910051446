export function isDateInPastOrFuture(dateString: string): 'past' | 'future' {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  if (inputDate > currentDate) {
    return 'future';
  }

  return 'past';
}

export function isDateValid(incoming: {
  allowFuture: boolean;
  allowPast: boolean;
  selectedDate: string;
}): boolean {
  const comparisonResult = isDateInPastOrFuture(incoming.selectedDate);

  if (comparisonResult === 'past' && !incoming.allowPast) {
    return false;
  }

  if (comparisonResult === 'future' && !incoming.allowFuture) {
    return false;
  }

  return true;
}
