import { Workflow, WorkflowStep } from '@nike.innovation/composure-sdk';
import { match } from 'ts-pattern';

import { validateStepCompletion } from './utils';

export interface WorkflowOperatorState {
  workflow: Workflow;
  currentStep: number;
  userData: Record<string, unknown>[];
  isStepComplete: boolean[];
  startedAt: Date;
  user: string;
}

export type WorkflowOperatorAction =
  | { kind: 'NEXT_STEP' }
  | { kind: 'PREVIOUS_STEP' }
  | { kind: 'SET_USER_INPUT'; newUserData: Record<string, unknown> }
  | { kind: 'RUN_AGAIN' };

const defaultUserData = (step: WorkflowStep) => {
  const defaults: Record<string, unknown> = {};

  Object.values(step.form).forEach(field => {
    Object.entries(field.settings).forEach(([key, value]) => {
      if (key === 'default') {
        defaults[field.fieldName] = value;
      }
      if (field.kind === 'date') {
        defaults[field.fieldName] = new Date();
      }
    });
  });

  return defaults;
};

export const createInitialStepCompletion = (steps: WorkflowStep[]): boolean[] =>
  steps.map((step, i) => {
    const stepFields = Object.values(step.form);
    const fieldComplete = stepFields.map(field =>
      match(field)
        .with({ kind: 'text' }, refined => !refined.required || refined.settings.default !== '')
        .with({ kind: 'file' }, refined => !refined.required)
        .with({ kind: 'select' }, refined => !refined.required)
        .with({ kind: 'number' }, refined => !refined.required)
        .with({ kind: 'slider' }, refined => true)
        .with({ kind: 'toggle' }, refined => true)
        .with({ kind: 'date' }, refined => !refined.required)
        .exhaustive()
    );
    return fieldComplete.every(complete => complete);
  });

export const genInitialOpState = (workflow: Workflow, user: string): WorkflowOperatorState => {
  const initialUserData = workflow.steps.map(defaultUserData);
  const initialStepComplete = createInitialStepCompletion(workflow.steps);
  return {
    workflow,
    currentStep: 0,
    userData: initialUserData,
    isStepComplete: initialStepComplete,
    startedAt: new Date(),
    user,
  };
};

// This reducer exposes a UX like interface for updating the WorkflowOperatorState that is created
// when a user wants to create a new WorkflowResult. Setting the input for the reducer is based on the current selected step
export const workflowOperatorReducer = (
  state: WorkflowOperatorState,
  action: WorkflowOperatorAction
): WorkflowOperatorState =>
  match(action)
    .with({ kind: 'NEXT_STEP' }, () => {
      // Prevent going past the last step
      const nextStep = state.currentStep + 1;
      if (nextStep < state.workflow.steps.length) {
        return { ...state, currentStep: nextStep };
      }
      return state;
    })
    .with({ kind: 'PREVIOUS_STEP' }, () => {
      // Prevent going to a negative step
      const previousStep = state.currentStep - 1;
      if (previousStep >= 0) {
        return { ...state, currentStep: previousStep };
      }
      return state;
    })
    .with({ kind: 'SET_USER_INPUT' }, ({ newUserData }) => {
      const { currentStep, userData, workflow } = state;
      const updatedUserData = userData.map((stepData, index) =>
        index === currentStep ? { ...stepData, ...newUserData } : stepData
      );

      const isStepComplete = workflow.steps.map((step, index) =>
        index === currentStep
          ? validateStepCompletion(step, updatedUserData[currentStep])
          : state.isStepComplete[index]
      );

      return { ...state, userData: updatedUserData, isStepComplete };
    })
    .with({ kind: 'RUN_AGAIN' }, () => {
      const { workflow } = state;
      return genInitialOpState(workflow, state.user);
    })
    .exhaustive();
