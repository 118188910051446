import { AssetVersions } from '@nike.innovation/aurora';
import { TableCell, Text } from '@nike/eds';
import { match, P } from 'ts-pattern';
import './version-table.css';

export interface VersionDescriptionProps {
  version: AssetVersions[0];
}

/**
 * Allows you to create a tabulated Card that contains various data tables
 * @param
 * @returns
 */
export function VersionDescriptionCell({ version }: VersionDescriptionProps) {
  return (
    <>
      {match(version)
        .with(
          {
            metadata: { versionNotes: P.string },
          },
          () => (
            <>
              <TableCell title={version.artifactReferences[0].name}>
                <Text className="table-cell-handle-overflow">
                  {version.artifactReferences[0].name}
                </Text>
              </TableCell>
              <TableCell title={version.createdByUserId}>
                <Text className="table-cell-handle-overflow">{version.createdByUserId}</Text>
              </TableCell>
              <TableCell title={version.metadata?.versionNotes}>
                <Text className="table-cell-handle-overflow">{version.metadata?.versionNotes}</Text>
              </TableCell>
            </>
          )
        )
        .with(
          {
            metadata: { description: P.string },
          },
          () => (
            <>
              <TableCell title={version.artifactReferences[0].name}>
                <Text className="table-cell-handle-overflow">
                  {version.artifactReferences[0].name}
                </Text>
              </TableCell>
              <TableCell title={version.createdByUserId}>
                <Text className="table-cell-handle-overflow">{version.createdByUserId}</Text>
              </TableCell>
              <TableCell title={version.metadata?.description}>
                <Text className="table-cell-handle-overflow">{version.metadata?.description}</Text>
              </TableCell>
            </>
          )
        )
        .otherwise(() => (
          <>
            <TableCell title={version.artifactReferences[0].name}>
              <Text className="table-cell-handle-overflow">
                {version.artifactReferences[0].name}
              </Text>
            </TableCell>
            <TableCell title={version.createdByUserId}>
              <Text className="table-cell-handle-overflow">{version.createdByUserId}</Text>
            </TableCell>
            <TableCell />
          </>
        ))}
    </>
  );
}
