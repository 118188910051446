/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

export interface Point {
  x: number;
  y: number;
}

export type ControlPoints = [Point, Point, Point, Point];

type DimensionVals = [number, number, number, number];

export interface CalcPointValueParams {
  points: ControlPoints;
  input: number;
}

const calcDimensionValue = (dVals: DimensionVals, input: number): number =>
  (1 - input) ** 3 * dVals[0] +
  3 * (1 - input) ** 2 * input * dVals[1] +
  3 * (1 - input) * input ** 2 * dVals[2] +
  input ** 3 * dVals[3];

function calculatePointValue({ points, input }: CalcPointValueParams): Point {
  const x = calcDimensionValue(points.map(p => p.x) as DimensionVals, input);
  const y = calcDimensionValue(points.map(p => p.y) as DimensionVals, input);
  const result: Point = { x, y };
  return result;
}

export function calculateBezierCurve(points: ControlPoints, stepControl: number): Point[] {
  const curve: Point[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= stepControl; i++) {
    curve.push(calculatePointValue({ points, input: i / stepControl }));
  }
  return curve;
}

function cuberoot(x: number) {
  const y = Math.abs(x) ** 1 / 3;
  return x < 0 ? -y : y;
}

function solveCubic(a: number, b: number, c: number, d: number) {
  if (Math.abs(a) < 1e-8) {
    // Quadratic case, ax^2+bx+c=0
    a = b;
    b = c;
    c = d;
    if (Math.abs(a) < 1e-8) {
      // Linear case, ax+b=0
      a = b;
      b = c;
      if (Math.abs(a) < 1e-8)
        // Degenerate case
        return [];
      return [-b / a];
    }

    const D = b * b - 4 * a * c;
    if (Math.abs(D) < 1e-8) return [-b / (2 * a)];
    if (D > 0) return [(-b + Math.sqrt(D)) / (2 * a), (-b - Math.sqrt(D)) / (2 * a)];
    return [];
  }

  // Convert to depressed cubic t^3+pt+q = 0 (subst x = t - b/3a)
  const p = (3 * a * c - b * b) / (3 * a * a);
  const q = (2 * b * b * b - 9 * a * b * c + 27 * a * a * d) / (27 * a * a * a);
  let roots;

  if (Math.abs(p) < 1e-8) {
    // p = 0 -> t^3 = -q -> t = -q^1/3
    roots = [cuberoot(-q)];
  } else if (Math.abs(q) < 1e-8) {
    // q = 0 -> t^3 + pt = 0 -> t(t^2+p)=0
    roots = [0].concat(p < 0 ? [Math.sqrt(-p), -Math.sqrt(-p)] : []);
  } else {
    const D = (q * q) / 4 + (p * p * p) / 27;
    if (Math.abs(D) < 1e-8) {
      // D = 0 -> two roots
      roots = [(-1.5 * q) / p, (3 * q) / p];
    } else if (D > 0) {
      // Only one real root
      const u = cuberoot(-q / 2 - Math.sqrt(D));
      roots = [u - p / (3 * u)];
    } else {
      // D < 0, three roots, but needs to use complex numbers/trigonometric solution
      const u = 2 * Math.sqrt(-p / 3);
      const t = Math.acos((3 * q) / p / u) / 3; // D < 0 implies p < 0 and acos argument in [-1..1]
      const k = (2 * Math.PI) / 3;
      roots = [u * Math.cos(t), u * Math.cos(t - k), u * Math.cos(t - 2 * k)];
    }
  }
  for (let i = 0; i < roots.length; i++) roots[i] -= b / (3 * a);

  return roots;
}

export function solveXToY(x: number, controlPoints: ControlPoints): number | undefined {
  if (x < 0 || x > 1) {
    return undefined;
  }
  const a = 3 * controlPoints[1].x - 3 * controlPoints[2].x + 1;
  const b = 3 * controlPoints[2].x - 6 * controlPoints[1].x;
  const c = 3 * controlPoints[1].x;
  const d = -x;
  const sols = solveCubic(a, b, c, d);
  const t = sols.find(v => v >= 0 && v <= 1);
  if (t === undefined) {
    return undefined;
  }
  const y = calcDimensionValue(controlPoints.map(p => p.y) as DimensionVals, t);
  return y;
}
