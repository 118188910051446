import useSWR from 'swr';
import { environment } from '../../../environments/environment';

/**
 * React hook for
 * @param assetId
 * @param versionId
 * @param token
 * @returns
 */
export function useDefinitionSolve(
  assetId: string | undefined,
  versionId: string | undefined,
  timestamp: string | undefined,
  token: string
) {
  const key = `api/v1/definitions/${assetId}/versions/${versionId}/solves/${timestamp}`;

  // Fetch the
  const fetcher = async () => {
    const response = await fetch(`${environment.apiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw await response.json();
    }
    const solves = await response.json();
    return solves;
  };

  const { data, error } = useSWR(key, fetcher);
  return { definitionSolve: data, defSolveLoading: !error && !data, useDefSolveErr: error };
}

export default useDefinitionSolve;
