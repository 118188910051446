import { DefinitionSolveQueryResponse } from '@nike.innovation/composure-sdk';
import useSWRInfinite from 'swr/infinite';
import { environment } from '../../../environments/environment';

export type DefinitionSolveTableRow = {
  user: string;
  status: 'succeeded' | 'failed';
  sk: number;
  warnings: string[] | [];
  errors: string[] | [];
};

/**
 * React hook for
 * @param assetId
 * @param versionId
 * @param token
 * @returns
 */
export function useDefinitionSolvesQuery(
  assetId: string,
  versionId: string,
  limit: number,
  token: string
) {
  const fetcher = async (key: string) => {
    const response = await fetch(`${environment.apiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw await response.json();
    }

    const solves = (await response.json()) as DefinitionSolveQueryResponse;
    return solves;
  };

  // passing in a pageIndex purely as a trigger for the new request of solves
  const getKey = (pageIndex: number, previousPageData: DefinitionSolveQueryResponse) => {
    if (previousPageData && !previousPageData) return null;

    // Check for first page, otherwise use the last to get the new solves
    const fromFlag =
      pageIndex > 0 && previousPageData.last ? `&fromTime=${previousPageData.last}` : '';

    return `api/v1/definitions/${assetId}/versions/${versionId}/solves?limit=${limit}${fromFlag}`;
  };

  const { data, mutate, size, setSize, error } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  // Data returned from SWRInfinite is an array typically in page format,
  // we need to flatten that data in the results for make one page
  const flatSolves = data?.reduce(
    (accum, curr) => ({
      result: [...accum.result, ...curr.result],
      last: curr.last,
      count: curr.count,
    }),
    { result: [], count: 10, last: 0 }
  ) || { result: [], count: 10, last: 0 };

  return {
    solves: flatSolves,
    solvesLoading: !error && !flatSolves,
    solvesFetchErr: error,
    resultMutate: mutate,
    setSolvesPage: setSize,
    solvesPage: size,
  };
}

export default useDefinitionSolvesQuery;
