import { Menu, MenuItem, IconButton, Box } from '@nike/eds';
import { useState } from 'react';
import { GrasshopperInputParameter } from '@nike.innovation/composure-sdk';
import { match, P } from 'ts-pattern';

import { RunFormAction, RunFormState, getRenderTypes } from './run-form-reducer';

export interface SettingsCogProps {
  input: GrasshopperInputParameter;
  runFormState: RunFormState;
  dispatch: React.Dispatch<RunFormAction>;
  role: string | undefined;
  postInputSettings: (body: string) => void;
}

export function SettingsCog({
  input,
  runFormState,
  dispatch,
  role,
  postInputSettings,
}: SettingsCogProps) {
  const [open, setOpen] = useState(false);

  const renderTypes = getRenderTypes(input);

  return match([role, renderTypes.length])
    .with(['Owner', 1], res => (
      <Box className="eds-flex eds-flex--align-items-center" style={{ paddingLeft: '2%' }}>
        <IconButton label="Settings" icon="Settings" variant="secondary" size="small" disabled />
      </Box>
    ))
    .with(['Owner', P.number], res => (
      <Box className="eds-flex eds-flex--align-items-center" style={{ paddingLeft: '2%' }}>
        <Menu
          onClickOutside={() => setOpen(!open)}
          isOpen={open}
          bodySlot={renderTypes.map(renderType => (
            <MenuItem
              id={renderType}
              onClick={e => {
                e.preventDefault();
                setOpen(!open);
                dispatch({
                  kind: 'EDIT_FIELD_TYPE',
                  inputName: input.name,
                  newRenderMethod: renderType,
                });
                const temp = {
                  ...runFormState.inputSettings,
                  [input.name]: renderType,
                };
                postInputSettings(JSON.parse(JSON.stringify(temp)));
              }}
            >
              {renderType}
            </MenuItem>
          ))}
        >
          <IconButton
            label="Settings"
            icon="Settings"
            variant="secondary"
            size="small"
            onClick={() => setOpen(!open)}
          />
        </Menu>
      </Box>
    ))
    .otherwise(res => null);
}
