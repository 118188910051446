import {
  Table,
  TableCell,
  TableHeading,
  StatusIndicator,
  Card,
  Text,
  Button,
  Spinner,
  Box,
} from '@nike/eds';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import './definition-solve-table.css';
import { DefinitionSolveDynamoRow } from '@nike.innovation/composure-sdk';
import { exportDefinitionResults } from './export-definition-results';

export interface DefinitionSolveTable {
  rows: DefinitionSolveDynamoRow[];
  defId: string;
  verId: string;
}

/**
 * Renders a table of `DefinitionSolveDynamoRow`s for the user. These are Dynamo specific objects and
 * different from DefinitionSolve. Intended use of this component is a simple browsing experience
 * @param { rows } - array of DefinitionSolveDynamoRow objects from composure-sdk
 * @returns
 */
export function DefinitionSolveTable({ rows, defId, verId }: DefinitionSolveTable) {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const [isDownloading, setIsDownloading] = useState(false);

  const columns = [
    {
      header: 'User',
    },
    {
      header: 'Time',
    },
    {
      header: 'Warnings',
    },
    {
      header: 'Errors',
    },
    {
      header: 'Execution Time',
      title: 'Rhino Execution Time in Seconds',
    },
  ];

  return (
    <Card>
      <Box className="eds-flex eds-flex--justify-content-space-between">
        <Text font="title-3">Results</Text>
        <Button
          className="eds-flex--align-self-flex-end"
          id="getresults"
          disabled={isDownloading}
          onClick={async () => {
            setIsDownloading(true);
            await exportDefinitionResults(defId, verId, `${oktaAuth.getAccessToken()}`);
            setIsDownloading(false);
          }}
          variant="secondary"
        >
          {isDownloading ? <Spinner /> : 'Export Results'}
        </Button>
      </Box>
      <Table width="100%">
        <thead>
          <tr>
            {columns.map(column => (
              <TableHeading title={column.title || column.header} key={column.header}>
                {column.header}
              </TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.length > 0 &&
            rows.map(x => {
              const parsedTimestamp = new Date(x.sk);

              return (
                <tr
                  key={x.sk}
                  className="solve-row"
                  onClick={() => {
                    // Reconstruct the route of the resource from pk field from the dynamo row
                    // TODO: abstract this in composure-sdk within the hooks
                    // should return a dynamo row, should be partial of DefinitionSolve object
                    const { 1: definitionId, 3: versionId } = x.pk.split('#');

                    navigate(`/definitions/${definitionId}/versions/${versionId}/solves/${x.sk}`);
                  }}
                >
                  <TableCell>
                    <StatusIndicator
                      status={x.status === 'succeeded' ? 'success' : 'danger'}
                      label={x.user}
                    />
                  </TableCell>

                  <TableCell>{`${parsedTimestamp.toLocaleDateString()} ${parsedTimestamp.toLocaleTimeString()}`}</TableCell>

                  <TableCell>
                    {x.warnings !== undefined && x.warnings.length > 0 ? x.warnings : 'None'}
                  </TableCell>

                  <TableCell>
                    {x.errors !== undefined && x.errors.length > 0 ? x.errors : 'None'}
                  </TableCell>
                  <TableCell>{x.executionTime ? `${x.executionTime / 1000}s` : null}</TableCell>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card>
  );
}
