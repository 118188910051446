import { match } from 'ts-pattern';
import { capitalize } from '@mui/material';

import { FieldErrors, WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { WorkflowDesignerState } from '../../reducers/workflow-designer-reducer';

type FieldValue = WorkflowFormElementSettings[keyof WorkflowFormElementSettings];
type FieldSettingsValue =
  WorkflowFormElementSettings['settings'][keyof WorkflowFormElementSettings['settings']];

export const isNotEmpty = (a: FieldValue): string | undefined => {
  if (typeof a === 'string') {
    return a.length === 0 ? 'cannot be empty.' : undefined;
  }

  if (typeof a === 'object') {
    return Object.entries(a).length === 0 ? 'cannot be empty.' : undefined;
  }

  return undefined;
};

interface FieldValidationRules {
  fieldName: (a: FieldValue) => string | undefined;
  settings: Record<string, (a: FieldSettingsValue) => string | undefined>;
}

// Shared validation rules for all workflow field types
export const fieldValidationRules = (
  inputElement: WorkflowFormElementSettings
): FieldValidationRules =>
  match(inputElement)
    .with({ kind: 'select' }, refined => ({
      fieldName: isNotEmpty,
      settings: {
        options: (settings: typeof refined.settings) =>
          settings.options.length === 0 ? 'cannot be empty.' : undefined,
      },
    }))
    .with({ kind: 'slider' }, refined => ({
      fieldName: isNotEmpty,
      settings: {
        minimum: (settings: typeof refined.settings) =>
          settings.minimum > settings.maximum ? 'should be less or equal than maximum.' : undefined,
        maximum: (settings: typeof refined.settings) =>
          settings.minimum > settings.maximum
            ? 'should be greater or equal than minimum.'
            : undefined,
        default: (settings: typeof refined.settings) =>
          settings.default < settings.minimum || settings.default > settings.maximum
            ? 'should be between minimum and maximum.'
            : undefined,
      },
    }))
    .otherwise(() => ({
      fieldName: isNotEmpty,
      settings: {},
    }));

export const displayFormatWorkflowFieldProperty = (
  field: keyof WorkflowFormElementSettings
): string =>
  match(field)
    .with('fieldName', () => 'Field name')
    .with('kind', () => 'Field type')
    .with('required', () => 'Required')
    .with('settings', () => 'Settings')
    .otherwise(() => '');

const validateFieldSetting = (
  field: keyof WorkflowFormElementSettings['settings'],
  rule: (value: WorkflowFormElementSettings['settings']) => string | undefined,
  value: WorkflowFormElementSettings['settings']
): string[] | undefined => {
  const validationResult = rule(value);

  if (validationResult) {
    return [`${capitalize(field)} ${validationResult}`];
  }

  return undefined;
};

const validateFieldName = (
  field: keyof WorkflowFormElementSettings,
  rule: (value: FieldValue) => string | undefined,
  value: FieldValue
): string[] | undefined => {
  const validationResult = rule(value);

  if (validationResult) {
    return [
      `${displayFormatWorkflowFieldProperty(
        field as keyof WorkflowFormElementSettings
      )} ${validationResult}`,
    ];
  }

  return undefined;
};

// : [string, (value: FieldSettingsValue) => string | undefined]
const validateFieldSettings = (
  settings: WorkflowFormElementSettings['settings'],
  validationRules: FieldValidationRules['settings']
): FieldErrors['settings'] =>
  Object.entries(validationRules).reduce(
    (acc: FieldErrors['settings'], [settingField, rule]) => ({
      ...acc,
      [settingField]: validateFieldSetting(
        settingField as keyof WorkflowFormElementSettings['settings'],
        rule as (value: WorkflowFormElementSettings['settings']) => string | undefined,
        settings
      ),
    }),
    {}
  );

// Accepts a workflow field and returns an array of validation errors
// If the array is empty, the step is valid
const validateField = (inputElement: WorkflowFormElementSettings): FieldErrors => {
  const validationRules = fieldValidationRules(inputElement);

  return {
    fieldName: validateFieldName('fieldName', validationRules.fieldName, inputElement.fieldName),
    settings: validateFieldSettings(inputElement.settings, validationRules.settings),
  };
};

export const validateFields = (
  form: WorkflowFormElementSettings[]
): Record<WorkflowFormElementSettings['id'], FieldErrors> =>
  Object.fromEntries(form.map(field => [field.id, validateField(field)]));

export const getFieldErrors = (state: WorkflowDesignerState, fieldId: string): FieldErrors => {
  if (state.selectedStepIndex !== undefined) {
    return state.errors.fieldsErrors[state.selectedStepIndex][fieldId];
  }

  return {
    fieldName: undefined,
    settings: {},
  };
};

export const isFieldErroring = (fieldErrors: FieldErrors) => {
  const settingsError = Object.values(fieldErrors.settings).some(
    value => value && value.length > 0
  );

  return (fieldErrors.fieldName && fieldErrors.fieldName.length > 0) || settingsError;
};
