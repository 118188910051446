import { GrasshopperIO } from '@nike.innovation/composure-sdk';
import { Table, TableCell, TableHeading } from '@nike/eds';
import { Dispatch, SetStateAction } from 'react';
import { InputParameterTable } from '../input-parameter-table';

import { Tabtable, tabsntableTuple } from '../../../shared/tabtable/tabtable';

export interface ColumnHeader {
  Header: string;
}

export interface DefinitionParameterTableProps {
  io: GrasshopperIO;
  setIo: Dispatch<SetStateAction<GrasshopperIO | undefined>>;
}

// Defines an EDS table to render the inputs and outputs of a ComputationDefinition
export function DefinitionParameterTable({ io, setIo }: DefinitionParameterTableProps) {
  const inputsTable: tabsntableTuple = [
    { value: 'inputs', label: 'Inputs' },
    <InputParameterTable io={io} setIo={setIo} />,
  ];

  const outputsTable: tabsntableTuple = [
    { value: 'outputs', label: 'Outputs' },
    <Table width="100%">
      <thead>
        <tr>
          <TableHeading key="name">Name</TableHeading>
          <TableHeading key="type">Type</TableHeading>
        </tr>
      </thead>
      <tbody>
        {io.outputs.map(row => (
          <tr key={row.name}>
            {/* TODO: make this passable to the caller, which set of fields do you want to render from the asset search? */}
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.type}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>,
  ];

  const warningTable: tabsntableTuple = [
    {
      value: 'warnings',
      label: 'Warnings',
      disabled: io.warnings.length === 0,
      color: io.warnings.length !== 0 ? 'eds-color--brand-orange' : 'eds-color--grey-2',
    },
    <Table width="100%">
      <tbody>
        {io.warnings.map(row => (
          <tr key={row}>
            <TableCell>{row}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>,
  ];

  const errorTable: tabsntableTuple = [
    {
      value: 'errors',
      label: 'Errors',
      disabled: io.errors.length === 0,
      color: io.errors.length !== 0 ? 'eds-color--brand-red' : 'eds-color--grey-2',
    },
    <Table width="100%">
      <tbody>
        {io.errors.map(row => (
          <tr key={row}>
            <TableCell>{row}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>,
  ];

  const tabsntables = [inputsTable, outputsTable, warningTable, errorTable];

  return <Tabtable tabsntables={tabsntables} />;
}

export default DefinitionParameterTable;
