import { useState } from 'react';
import { Text, Box, Icon, Button, ButtonGroup, Spinner, Modal, Card } from '@nike/eds';
import { KeyedMutator } from 'swr';
import { useParams, useNavigate } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { useOktaAuth } from '@okta/okta-react';
import { AssetSearchResponse, Aurora, AuroraBaseUrl, Entitlement } from '@nike.innovation/aurora';
import { environment } from '../../../environments/environment';
import MidsoleVersionTable from './version-table/version-table';
import useMidsole from '../shared/use-midsoles/use-midsole';
import { useMidsoleVersions } from '../shared/use-midsoles/use-midsoles-versions';
import NewMidsoleForm from '../shared/new-midsole-form/new-midsole-form';
import midsoleFormSubmit from '../shared/new-midsole-form/new-midsole-create';
import { SendPageView, ga4Event } from '../../shared/utils/ga4-helpers/ga4-events';
import { getUserRole } from '../../shared/utils/entitlement-utils';
import Breadcrumbs from '../../shared/breadcrumbs/breadcrumbs';

interface Params {
  midsoleId: string;
  versionId: string;
}

export default function ShowMidsolePage({
  midsoleSearchResult,
  mutateSearch,
}: {
  midsoleSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  const navigate = useNavigate();
  const [uploadModalVis, setUploadModalVis] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  SendPageView({ page: window.location.pathname, title: 'Midsole Details Page' });

  const aurora = new Aurora({
    baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
  });
  const { oktaAuth, authState } = useOktaAuth();
  const { midsoleId, versionId } = useParams<keyof Params>() as Params;

  const midsoleVersionsObj = useMidsoleVersions(midsoleId, `${oktaAuth.getAccessToken()}`);

  // Individual Midsole asset
  const midsoleObj = useMidsole(midsoleId, versionId, `${oktaAuth.getAccessToken()}`);

  const onSubmit = (
    assetName: string,
    meshFiles: FileList,
    midsoleBoundaryFiles: FileList,
    uvBoundaryFiles: FileList,
    entitlements: Entitlement[]
  ): Promise<{ assetId: string; versionId: string }> =>
    midsoleFormSubmit(
      aurora,
      { midsoleId, midsoleVersionId: versionId },
      meshFiles,
      midsoleBoundaryFiles,
      uvBoundaryFiles,
      entitlements,
      navigate,
      `${oktaAuth.getAccessToken()}`
    );

  const onDelete = async () => {
    if (midsoleSearchResult) {
      setDeleting(true);

      const newMidsoles = midsoleSearchResult.results.filter(
        // eslint-disable-next-line no-shadow
        midsole => midsole.assetId !== midsoleId
      );
      mutateSearch(
        async () => {
          await aurora.Midsole.deleteAsset(midsoleId, `${oktaAuth.getAccessToken()}`);

          return undefined;
        },
        {
          optimisticData: {
            ...midsoleSearchResult,
            results: newMidsoles,
          },
          populateCache: false,
          revalidate: false,
        }
      );

      ga4Event({
        category: 'midsoles',
        action: 'asset_delete',
        label: 'success',
      });

      navigate('/midsoles');
    }
  };

  const role = getUserRole(authState, midsoleObj.midsole?.entitlements || []);
  const buttonDisabled = role === 'Viewer';

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { url: '/midsoles', name: 'Midsoles' },
          { url: '', name: 'Details' },
        ]}
      />
      {match(midsoleObj)
        .with({ midsole: { name: P.string, assetId: P.string } }, ({ midsole }) => (
          <Box>
            <div className="eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between eds-gap--16 eds-spacing--mb-32">
              <Text as="h1" font="title-2">
                {midsole.name}
              </Text>

              {match(buttonDisabled)
                .with(true, () => (
                  <ButtonGroup>
                    <Button
                      className="eds--dark"
                      variant="primary"
                      disabled
                      title="You do not have permission to edit this asset"
                    >
                      Upload New Version
                    </Button>
                    <Button
                      size="small"
                      variant="secondary"
                      disabled
                      title="You do not have permission to delete this asset"
                    >
                      <Icon name="Delete" />
                      Delete
                    </Button>
                  </ButtonGroup>
                ))
                .otherwise(() => (
                  <ButtonGroup>
                    <NewMidsoleForm
                      visible={uploadModalVis}
                      setVisible={setUploadModalVis}
                      newAsset={false}
                      onSubmit={onSubmit}
                    />

                    <Button
                      className="eds--dark"
                      variant="primary"
                      onClick={() => setUploadModalVis(true)}
                    >
                      Upload New Version
                    </Button>

                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      <Icon name="Delete" />
                      Delete
                    </Button>

                    <Modal
                      isOpen={deleteModalOpen}
                      onDismiss={() => setDeleteModalOpen(false)}
                      headerSlot={<Text font="title-3">Confirm Delete</Text>}
                      footerSlot={
                        <Button onClick={onDelete} variant="secondary">
                          {deleting ? (
                            <Spinner />
                          ) : (
                            <>
                              <Icon name="Delete" size="m" enableFocus />
                              Delete
                            </>
                          )}
                        </Button>
                      }
                    >
                      <Text>Are you sure you want to delete {midsole.name}?</Text>
                    </Modal>
                  </ButtonGroup>
                ))}
            </div>

            <Card className="eds-elevation--2">
              {match(midsoleVersionsObj)
                .with(
                  { midsoleVersions: P.array({ versionId: P.string }) },
                  ({ midsoleVersions }) => (
                    <MidsoleVersionTable
                      versions={midsoleVersions}
                      token={`${oktaAuth.getAccessToken()}`}
                    />
                  )
                )
                .with({ versionError: P.string }, ({ versionError }) => <Text>{versionError}</Text>)
                .otherwise(() => (
                  <Spinner />
                ))}
            </Card>
          </Box>
        ))
        .with({ isError: P.string }, ({ isError }) => (
          <Box>
            <Text>{isError}</Text>
          </Box>
        ))
        .otherwise(() => (
          <Spinner />
        ))}
    </>
  );
}
