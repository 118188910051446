import { AuthState } from '@okta/okta-auth-js';
import { AssetGetResponse, Entitlement } from '@nike.innovation/aurora';
import { environment } from '../../../environments/environment';

export function permissionsToRoleString(permissions: string[]) {
  if (permissions.includes('ADMIN')) {
    return 'Owner';
  }
  if (permissions.includes('WRITE')) {
    return 'Editor';
  }
  if (permissions.includes('READ')) {
    return 'Viewer';
  }

  return undefined;
}

export function isOwner({ permissions }: Entitlement) {
  return permissions.includes('ADMIN');
}

export function roleToPermissions(role: string) {
  if (role === 'Owner') {
    return ['ADMIN', 'WRITE', 'READ'];
  }
  if (role === 'Editor') {
    return ['WRITE', 'READ'];
  }
  if (role === 'Viewer') {
    return ['READ'];
  }

  return [];
}

export const entitlementPermissions = ['Editor', 'Viewer'];

export function getCurrentUserAccess(
  entitlements: Entitlement[],
  userName: string,
  claims: string[]
): string[] {
  if (entitlements.length === 0) return ['ADMIN'];

  return [
    ...new Set(
      entitlements
        .filter(entitlement => entitlement.name === userName || claims.includes(entitlement.name))
        .map(ent => ent.permissions)
        .flat()
    ),
  ];
}

export function checkForPublicEntitlement(definition: AssetGetResponse): boolean {
  if (definition.entitlements) {
    if (definition.entitlements.length === 0) return true;
    const publicEntitlement = definition.entitlements.find(
      (x: { name: string }) => x.name === environment.adGroup
    );
    if (publicEntitlement) {
      return publicEntitlement.permissions.includes('READ');
    }
  }
  return false;
}

export function getUserRole(authState: AuthState | null, assetEntitlements: Entitlement[]) {
  const userName = authState?.accessToken?.claims?.sub || '';
  // eslint-disable-next-line dot-notation
  const claims = authState?.accessToken?.claims['groups'] || [];
  const permissions = getCurrentUserAccess(assetEntitlements, userName, claims as string[]);

  return permissionsToRoleString(permissions);
}
