import {
  Table,
  TableCell,
  TableHeading,
  Text,
  Box,
  ButtonGroup,
  Link,
  Spinner,
  Tooltip,
  IconButton,
} from '@nike/eds';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useOktaAuth } from '@okta/okta-react';
import useSWR from 'swr';
import TabCard from '../../../tabcard/tabcard';
import { environment } from '../../../../environments/environment';

interface PluginInfo {
  name: string;
  version: string;
}

function useInstalledGrasshopperPlugins() {
  const { oktaAuth } = useOktaAuth();
  const key = `api/v1/plugins`;

  const fetcher = async () => {
    const response = await fetch(`${environment.apiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
    });

    const data = await response.json();
    const plugins: PluginInfo[] = Object.entries(data).map(plugin => ({
      name: `${plugin[0]}`,
      version: `${plugin[1]}`,
    }));

    return plugins;
  };

  const { data, error } = useSWR(key, fetcher);

  return { pluginInfo: data || [], loading: !error && !data, err: error };
}

export function HelpCards() {
  type tabCardTuple = [{ value: string; label: string }, ReactElement];
  const { currentTab = 'start' } = useParams();
  const installedPlugins = useInstalledGrasshopperPlugins();

  const codeExampleString =
    // eslint-disable-next-line no-template-curly-in-string
    "const fd = new FormData();\nconst config = {\nheaders:{\nAuthorization: `${oktaToken}`,\n'Content-Type': 'multipart/form-data',\nInputsRaw: 'false', \n},\n}\n// Add a text input to your solve body\nfd.append('input', 'Hello world!');\n// Map the input name to the file name\nfd.append('fileInput', 'hello.png');\n// Add a file input to your solve body from an event, where the file is called file.png\nfd.append('files', event.target.files[0]);\n// Map the input name to the file name\nfd.append('geometryInput', 'world.obj');\n// Add a file input to your solve body from an event, where the file is called world.obj\nfd.append('files', event.target.files[1]);\n// POST request using Axios and some solve endpoint\naxios.post(composureSolveEndpoint, fd, config)";

  const startCard: tabCardTuple = [
    { value: 'start', label: 'Getting Started' },
    <Box>
      <Text font="title-4" className="eds-spacing--my-24">
        How to get started using Composure?
      </Text>
      <Text as="span" font="body-2">
        To prepare an existing Grasshopper definition for Composure, check out our internal guide:{' '}
        <b>hops_school.gh</b> that is located in the Grasshopper Definitions section in Composure.{' '}
        <b>Download</b> that Grasshopper file to see how to properly set up{' '}
        <b>Inputs and Outputs</b> within Grasshopper.
      </Text>
      <Text font="body-2" className="eds-spacing--my-24">
        When you are ready to upload a new Defintion you will get feedback on the formatting and
        requirements for a definition in Composure. Once the definiton is complete, you can{' '}
        <b>Submit</b> to publish and its ready for use!
      </Text>
      <Text font="title-4">Plugins currently installed:</Text>
      <hr />
      {installedPlugins.loading ? (
        <Box className="eds-flex eds-flex--justify-content-center">
          <Spinner size="large" />
        </Box>
      ) : (
        <Table width="100%" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <TableHeading key="name">Plugin</TableHeading>
              <TableHeading key="version">Version</TableHeading>
            </tr>
          </thead>
          <tbody>
            {installedPlugins.pluginInfo.map(e => (
              <tr>
                <TableCell>{e.name}</TableCell>
                <TableCell>{e.version}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <hr />
      <Text font="body-2" className="eds-spacing--mt-8">
        To get support for a different plugin, please make a pull request in the{' '}
        <strong>
          <a href="https://github.com/nike-dam/nike.picc.dam/tree/main/apps/rhino-compute">
            app/rhino-compute
          </a>
        </strong>{' '}
        github repo with the associated dlls for the plugins checked in to /src/assets/libraries OR
        reach out through our{' '}
        <strong>
          <a href="slack://channel?team=T08495J2Y&id=C051ZRRRJM8">Support Channels</a>
        </strong>
        .
      </Text>
    </Box>,
  ];

  const solveCard: tabCardTuple = [
    { value: 'solve', label: 'Solving w/ Composure' },
    <Box>
      <Text font="title-4" className="eds-spacing--my-24">
        How to solve w/ Composure?
      </Text>
      <Text font="body-2">To solve your Grasshopper definition, you have two options</Text>
      <ul style={{ listStyleType: 'number', listStylePosition: 'outside', paddingLeft: '2em' }}>
        <Text as="li" font="body-2">
          Use the Composure UI for an easy solve experience (Refer to the &apos;Getting
          Started&apos; page)
        </Text>
        <Text as="li" font="body-2">
          Use the Composure API solve endpoint (see below)
        </Text>
      </ul>
      <Text font="title-4" className="eds-spacing--my-24">
        Using Composure API solve endpoint
      </Text>
      <ul style={{ listStyleType: 'number', listStylePosition: 'outside', paddingLeft: '2em' }}>
        <Text as="li" font="body-2">
          Upload your definition to Composure
        </Text>
        <Text as="li" font="body-2">
          Prepare your POST request
          <ul style={{ listStyleType: 'number', listStylePosition: 'outside', paddingLeft: '2em' }}>
            <Text as="li" font="body-2">
              Determine your Composure solve endpoint, which should follow the pattern:
              https://api.composure.nike.io/api/v1/definitions/(definitionId)/versions/(versionId)/solve
            </Text>
            <Text as="li" font="body-2">
              Add the correct headers to the request
              <ul
                style={{
                  listStyleType: 'number',
                  listStylePosition: 'outside',
                  paddingLeft: '2em',
                }}
              >
                <Text as="li" font="body-2">
                  Authorization: Include an &apos;oktadev&apos; token from the
                  <Link as="a" font="body-2" href="https://token.niketech.com/#" target="_blank">
                    {' '}
                    Token Generator
                  </Link>
                  <Box className="eds-flex eds-flex--justify-content-center">
                    <img
                      src="/assets/static/auth_token.png"
                      alt="Example of auth token in Postman"
                      style={{
                        width: 600,
                        height: 100,
                      }}
                      className="eds-spacing--mt-16"
                    />
                  </Box>
                </Text>
                <Text as="li" font="body-2">
                  inputsRaw: Include to define how your inputs will be processed by Composure API
                  <ol
                    style={{
                      listStyleType: 'disc',
                      listStylePosition: 'outside',
                      paddingLeft: '2em',
                    }}
                  >
                    <Text as="li" font="body-2">
                      &apos;true&apos;: Composure will pass the inputs directly to Rhino Compute to
                      be solved with. Your input files will not be saved.{' '}
                      <strong>(Recommended for Rhino experts)</strong>
                    </Text>
                    <Text as="li" font="body-2">
                      &apos;false&apos;: Composure will format your inputs for you. Your input files
                      will be saved. <strong>(Beginner friendly)</strong>
                    </Text>
                  </ol>
                </Text>
                <Text as="li" font="body-2">
                  Content-Type: &quot;multipart/form-data&quot;
                </Text>
              </ul>
              <Box className="eds-flex eds-flex--justify-content-center">
                <img
                  src="/assets/static/inputs_raw_header.png"
                  alt="Example of the inputsRaw header in Postman"
                  style={{
                    width: 1400,
                    height: 300,
                  }}
                  className="eds-spacing--mt-16"
                />
              </Box>
            </Text>
            <Text as="li" font="body-2">
              Add your solve inputs to the request body. The inputs should be mapped from
              &apos;input_name&apos; to &apos;input_value&apos;.
              <ol
                style={{
                  listStyleType: 'disc',
                  listStylePosition: 'outside',
                  paddingLeft: '2em',
                }}
              >
                <Text as="li" font="body-2">
                  If &apos;inputsRaw&apos; true, then your inputs should be correctly formatted for
                  Rhino Compute.
                  <Box className="eds-flex eds-flex--justify-content-center">
                    <img
                      src="/assets/static/solve_inputs_raw.png"
                      alt="Example of raw inputs in Postman"
                      style={{
                        width: 850,
                        height: 150,
                      }}
                      className="eds-spacing--mt-16"
                    />
                  </Box>
                </Text>
                <Text as="li" font="body-2">
                  If &apos;inputsRaw&apos; false, then your inputs should align with the following
                  formatting expectations
                  <ol
                    style={{
                      listStyleType: 'disc',
                      listStylePosition: 'outside',
                      paddingLeft: '2em',
                    }}
                  >
                    <Text as="li" font="body-2">
                      Any <strong>files</strong> used in the solve should be uploaded as multi-part
                      form data under the names &apos;files&apos;
                      <ol
                        style={{
                          listStyleType: 'disc',
                          listStylePosition: 'outside',
                          paddingLeft: '2em',
                        }}
                      >
                        <Text as="li" font="body-2">
                          All objs will be transformed into their Opennurbs format.
                        </Text>
                        <Text as="li" font="body-2">
                          All other file types will be base64 encoded.
                        </Text>
                        <Text as="li" font="body-2">
                          Include an input in the body that matches the input name to the file name
                        </Text>
                      </ol>
                    </Text>
                    <Text as="li" font="body-2">
                      All <strong>text inputs</strong> should be passed as normal
                    </Text>
                    <Box className="eds-flex eds-flex--justify-content-center">
                      <img
                        src="/assets/static/solve_inputs_unraw.png"
                        alt="Example of unraw inputs in Postman"
                        style={{
                          width: 850,
                          height: 150,
                        }}
                        className="eds-spacing--mt-16"
                      />
                    </Box>
                  </ol>
                </Text>
              </ol>
            </Text>
          </ul>
        </Text>
        <Text as="li" font="body-2">
          Perform your POST request on the Composure API solve endpoint
        </Text>
      </ul>
      <Text font="title-4" className="eds-spacing--my-24">
        Code example for solving w/ non-raw inputs
      </Text>
      <Text className="eds-gap--8 eds-spacing--p-16 eds-spacing--mb-16 eds-background--secondary">
        <Box className="eds-flex eds-flex--justify-content-flex-end">
          <Tooltip bodySlot="Copy to Clipboard">
            <CopyToClipboard text={codeExampleString}>
              <IconButton variant="ghost" label="Copy to Clipboard" icon="CopyPaste" size="small" />
            </CopyToClipboard>
          </Tooltip>
        </Box>

        <Text font="body-2">const fd = new FormData();</Text>
        <br />

        <Text font="body-2">const config = &#123;</Text>
        <Text font="body-2" style={{ paddingLeft: '2em' }}>
          headers: &#123;
        </Text>
        <Text font="body-2" style={{ paddingLeft: '4em' }}>
          Authorization: `$&#123;oktaToken&#125;`,
        </Text>
        <Text font="body-2" style={{ paddingLeft: '4em' }}>
          &apos;Content-Type&apos;: &apos;multipart/form-data&apos;,
        </Text>
        <Text font="body-2" style={{ paddingLeft: '4em' }}>
          InputsRaw: &apos;false&apos;,
        </Text>
        <Text font="body-2" style={{ paddingLeft: '2em' }}>
          &#125;,
        </Text>
        <Text font="body-2">&#125;</Text>
        <br />

        <Text font="body-2">&#47;&#47; Add a text input to your solve body</Text>
        <Text font="body-2">fd.append(&apos;input&apos;, &apos;Hello world!&apos;);</Text>
        <br />

        <Text font="body-2">&#47;&#47; Map the input name to the file name</Text>
        <Text font="body-2">fd.append(&apos;fileInput&apos;, &apos;hello.png&apos;);</Text>
        <br />

        <Text font="body-2">
          &#47;&#47; Add a file input to your solve body from an event, where the file is called
          file.png
        </Text>
        <Text font="body-2">fd.append(&apos;files&apos;, event.target.files[0]);</Text>
        <br />

        <Text font="body-2">&#47;&#47; Map the input name to the file name</Text>
        <Text font="body-2">fd.append(&apos;geometryInput&apos;, &apos;world.obj&apos;);</Text>
        <br />

        <Text font="body-2">
          &#47;&#47; Add a file input to your solve body from an event, where the file is called
          world.obj
        </Text>
        <Text font="body-2">fd.append(&apos;files&apos;, event.target.files[1]);</Text>
        <br />

        <Text font="body-2">&#47;&#47; POST request using Axios and some solve endpoint</Text>
        <Text font="body-2">axios.post(composureSolveEndpoint, fd, config)</Text>
      </Text>
    </Box>,
  ];

  const supportCard: tabCardTuple = [
    { value: 'support', label: 'Support' },
    <Box>
      <Text font="title-4" className="eds-spacing--my-24">
        Need help?
      </Text>
      <Text as="span" font="body-2">
        Currently there are two ways to get direct help for a Grasshopper Definition or a Workflow:
      </Text>
      <ul style={{ listStyleType: 'number', listStylePosition: 'outside', paddingLeft: '2em' }}>
        <Text as="li" font="body-2">
          <Link
            as="a"
            font="body-2"
            href="https://confluence.nike.com/display/DGM/DMM+Production+Support+Request"
            target="_blank"
          >
            Production Support Request Form:{' '}
          </Link>
          gives you a formal place to file a bug, propose a new feature, or just get help overall.
        </Text>
        <Text as="li" font="body-2">
          <Link
            as="a"
            font="body-2"
            href="slack://channel?team=T08495J2Y&id=C051ZRRRJM8"
            target="_blank"
          >
            Composure Slack Channel:{' '}
          </Link>
          is a more informal way to chat through ideas with fellow Composure users or the Developer
          team.
        </Text>
      </ul>
      <Text font="title-4" className="eds-spacing--my-24">
        Useful Resources
      </Text>
      <ButtonGroup>
        <Link
          as="a"
          font="body-2"
          href="https://developer.rhino3d.com/guides/compute/"
          target="_blank"
        >
          Rhino Compute
        </Link>
      </ButtonGroup>
      <ButtonGroup>
        <Link
          as="a"
          font="body-2"
          href="https://developer.rhino3d.com/guides/compute/hops-component/"
          target="_blank"
        >
          The Hops Component
        </Link>
      </ButtonGroup>
    </Box>,
  ];

  const errorCard: tabCardTuple = [
    { value: 'error', label: 'Errors' },
    <Box>
      <Text font="title-4" className="eds-spacing--my-24">
        Common Errors in Grasshopper Definitions Explained:
      </Text>
      <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: '2em' }}>
        <Text as="li" font="body-2">
          <b>GH - Missing Definition Objects: </b>means that Composure is missing a plugin you are
          using in your definition. Please contact us through our Support channels to get that
          plugin added and verify all unneeded plugins are removed.
        </Text>
        <Text as="li" font="body-2">
          <b>Object reference not set to an instance of an object: </b>means that required data is
          not getting passed to your Grasshopper definition. Key areas to check: inputs values are
          formatted correctly, a component has all the needed iputs, and making sure inputs have a
          default set.
        </Text>
        <Text as="li" font="body-2">
          <b>
            Plugin Components errors or silently failing only on Rhino Compute and on Composure:{' '}
          </b>
          the main issue could be a reference to <b>RhinoDoc.ActiveDoc</b> in a script or a plugin.
          A lot of Grasshopper C#/Python examples use ActiveDoc to get the tolerance, but
          unfortunately using Rhino Compute those reference will not work.
          <Box>
            <img
              src="/assets/static/activedoc_example.png"
              alt="Grasshopper input param in a group"
              style={{
                width: 700,
                height: 200,
              }}
              className="eds-spacing--mt-16"
            />
          </Box>
        </Text>
      </ul>
    </Box>,
  ];

  const proxzillaCard: tabCardTuple = [
    { value: 'proxzilla', label: 'Proxzilla' },
    <Box>
      <Text font="title-4" className="eds-spacing--my-24">
        What is Proxzilla?
      </Text>
      <Text as="span" font="body-2">
        Proxzilla is a desktop authentication application that allows desktop software, like
        Grasshopper, the ability to integrate with APIs.
      </Text>
      <br />
      <Link
        className="eds-flex eds-flex--justify-content-center"
        as="a"
        font="body-1"
        href="https://confluence.nike.com/pages/viewpage.action?pageId=746527981"
        target="_blank"
      >
        Download and get started!
      </Link>
      <Text font="title-5" className="eds-spacing--my-12">
        Getting an error when installing proxzilla-macos on a Mac?
      </Text>
      <Text as="span" font="body-2">
        If you are experiencing this error when attempting to run Proxzilla, try these steps to
        resolve:
      </Text>
      <Box className="eds-flex eds-flex--justify-content-center">
        <img
          src="/assets/static/proxzilla-macos-error.png"
          alt="Grasshopper input param in a group"
          style={{
            width: 300,
            height: 350,
          }}
          className="eds-spacing--mt-16"
        />
      </Box>
      <Text as="span" font="body-2">
        Instead of double clicking the file to open, <strong>right click</strong> then select Open.
      </Text>
      <Box className="eds-flex eds-flex--justify-content-center">
        <img
          src="/assets/static/right-click-open.png"
          alt="Grasshopper input param in a group"
          style={{
            width: 600,
            height: 350,
          }}
          className="eds-spacing--mt-16"
        />
      </Box>
      <Text as="span" font="body-2">
        You will be prompted once more to Open, then Proxzilla will launch!
      </Text>
      <Box className="eds-flex eds-flex--justify-content-center">
        <img
          src="/assets/static/second-open.png"
          alt="Grasshopper input param in a group"
          style={{
            width: 300,
            height: 300,
          }}
          className="eds-spacing--mt-16"
        />
      </Box>
      <Text as="span" font="body-2">
        If these steps do not work, please reach out through the Support Channel methods.
      </Text>
    </Box>,
  ];
  const tabs = [startCard, solveCard, supportCard, errorCard, proxzillaCard];

  return <TabCard tabs={tabs} currentTab={currentTab} />;
}

export default HelpCards;
