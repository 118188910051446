import {
  DefinitionIO,
  GrasshopperInputParameter,
  GrasshopperIO,
} from '@nike.innovation/composure-sdk';
import { useState } from 'react';
import { match, P } from 'ts-pattern';

import { Button, Select, Table, TableCell, TableHeading, Text } from '@nike/eds';
import SolveValueModal from '../solves/solve-output-modal';

const constructMinMaxString = (minimum: number | null, maximum: number | null) => {
  const min = minimum || minimum === 0 ? minimum : '--';
  const max = maximum || maximum === 0 ? maximum : '--';

  return `[${min}, ${max}]`;
};

const getDataStructures = (inputParameter: GrasshopperInputParameter): string[] => {
  const structList: string[] = [];

  if (inputParameter.isItem) structList.push('Item');
  if (inputParameter.isArray) structList.push('List');
  if (inputParameter.isTree) structList.push('Tree');

  return structList;
};

const constructDataStructureString = (inputParameter: GrasshopperInputParameter): string => {
  const structList = getDataStructures(inputParameter);

  if (structList.length === 3) return 'Any';
  if (structList.length === 2) return structList.toString().replace(',', ' or ');
  if (structList.length === 1) return structList[0];
  return 'Item';
};

interface SelectProps {
  value: string;
  label: string;
}

const updateInputParameter = (
  inputParameter: GrasshopperInputParameter,
  event: SelectProps[]
): GrasshopperInputParameter => {
  const selectedList = event.map(x => x.value);

  const updatedInput: GrasshopperInputParameter = {
    ...inputParameter,
    isItem: selectedList.includes('Item'),
    isArray: selectedList.includes('List'),
    isTree: selectedList.includes('Tree'),
  };

  return updatedInput;
};

const updateGrasshopperIo = (ghIo: GrasshopperIO, e: any, name: string): GrasshopperIO => {
  const updatedInputs = ghIo.inputs.map(input => {
    if (input.name === name) return updateInputParameter(input, e);
    return input;
  });

  return { ...ghIo, inputs: updatedInputs };
};

const headers = [
  {
    header: 'Name',
  },
  {
    header: '',
  },
  {
    header: '',
  },
  {
    header: 'Description',
  },
  {
    header: '',
  },
  {
    header: '',
  },
  {
    header: 'Type',
  },
  {
    header: 'Min/Max',
  },
  {
    header: 'Data Structure',
  },
  {
    header: '',
  },
  {
    header: 'Default',
  },
];

const options = ['Item', 'List', 'Tree'];

export function InputParameterTable({
  io,
  setIo,
}: {
  io: GrasshopperIO | DefinitionIO;
  setIo?: React.Dispatch<React.SetStateAction<GrasshopperIO | undefined>>;
}) {
  const [valueModalVis, setValueModalVis] = useState(false);
  const [expandedValue, setExpandedValue] = useState('');

  const canEdit = (ioObj: DefinitionIO | GrasshopperIO): ioObj is GrasshopperIO =>
    'warnings' in ioObj && 'errors' in ioObj;

  return (
    <>
      <SolveValueModal
        visible={valueModalVis}
        setVisible={setValueModalVis}
        value={expandedValue}
      />

      <Table width="100%" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading key={column.header}>{column.header}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {io.inputs.map(row => (
            <tr key={row.name}>
              {/* TODO: make this passable to the caller, which set of fields do you want to render from the asset search? */}
              <TableCell colSpan={3} title={row.name.replace('RH_IN:', '')}>
                <Text
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.name.replace('RH_IN:', '')}
                </Text>
              </TableCell>

              <TableCell colSpan={3} title={row.description ? row.description : ''}>
                <Text
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.description}
                </Text>
              </TableCell>

              <TableCell>
                <Text>{row.type}</Text>
              </TableCell>

              <TableCell>{constructMinMaxString(row.minimum, row.maximum)}</TableCell>

              <TableCell colSpan={2}>
                {canEdit(io) && setIo ? (
                  <Select
                    id="dataStructure"
                    isMulti
                    options={options.map(x => ({ value: x, label: x }))}
                    defaultValue={getDataStructures(row).map(x => ({ value: x, label: x }))}
                    onChange={e => setIo(updateGrasshopperIo(io, e, row.name))}
                    label=""
                    required
                    menuPlacement="top"
                  />
                ) : (
                  <Text>{constructDataStructureString(row)}</Text>
                )}
              </TableCell>

              <TableCell>
                {match(row)
                  .with({ default: undefined || null }, () => '--')
                  .with({ default: P.when(d => JSON.stringify(d).length > 20) }, d => (
                    <Button
                      className="eds--dark"
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        setValueModalVis(true);
                        setExpandedValue(JSON.stringify(d));
                      }}
                    >
                      Expand Value
                    </Button>
                  ))
                  .otherwise(() => JSON.stringify(row.default))}
              </TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
