import { AssetSearchResponse } from '@nike.innovation/aurora';
import { Box, ButtonGroup, IconButton, Text } from '@nike/eds';
import { Dispatch } from 'react';

export function PaginationFooter({
  from,
  setFrom,
  resultPage,
}: {
  from: number;
  setFrom: Dispatch<React.SetStateAction<number>>;
  resultPage: AssetSearchResponse;
}) {
  const currentPage = Math.ceil(from / resultPage.pagination.size) + 1;
  const totalPages = Math.ceil(
    Math.max(1, resultPage.pagination.total) / resultPage.pagination.size
  );

  return (
    <Box>
      <ButtonGroup className="eds-flex--align-items-center">
        <IconButton
          id="prevPage"
          aria-label="prevPage"
          icon="CaretLeft"
          variant="ghost"
          label="prevPage"
          onClick={() => {
            setFrom(from - resultPage.pagination.size);
          }}
          disabled={from === 0}
        />

        <Text as="h3">{`${currentPage} of ${totalPages}`}</Text>

        <IconButton
          id="nextPage"
          aria-label="nextPage"
          icon="CaretRight"
          variant="ghost"
          label="nextPage"
          onClick={() => {
            setFrom(from + resultPage.pagination.size);
          }}
          disabled={currentPage === totalPages}
        />
      </ButtonGroup>
    </Box>
  );
}
