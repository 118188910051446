import { KeyedMutator } from 'swr';
import { useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import classNames from 'classnames';

import { Box, Text, Button, Icon, Modal, Card } from '@nike/eds';
import { AssetSearchResponse } from '@nike.innovation/aurora';

import { useApiClient } from '../shared/hooks/use-api-client';
import { ga4Event } from '../shared/utils/ga4-helpers/ga4-events';
import { PaginationFooter } from '../shared/pagination-footer';

import './card-grid.css';

export interface WorkflowCardGridProps {
  resultPage: AssetSearchResponse;
  from: number;
  setFrom: Dispatch<SetStateAction<number>>;
  mutate: KeyedMutator<AssetSearchResponse>;
}

/**
 * Accepts an AssetSearchResponse and a set of columns to display and will render the resulting
 * assets with pagination
 *
 * @param resultPage: AssetSearchResponse - the asset search result to display
 * @param from: number - resultPage offset where the results start
 * @param setFrom: React hook closure that will increment the current from in an upstream query
 * @param mutate: swr mutate function to optimistically update resultPage
 */
export function WorkflowCardGrid({ resultPage, from, setFrom, mutate }: WorkflowCardGridProps) {
  const apiClient = useApiClient();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteAsset, setDeleteAsset] = useState<{ assetId: string; name: string }>();

  const userHasAccess = (row: AssetSearchResponse['results'][0]) =>
    row.currentUserAccess.length !== 0;

  const linkClick = (row: AssetSearchResponse['results'][0]) => {
    if (userHasAccess(row)) {
      return `/workflows/${row.assetId}/versions/${row.version.versionId}`;
    }

    return '';
  };

  const onDelete = () => {
    if (deleteAsset?.assetId) {
      const newDefinitions = resultPage.results.filter(
        definition => definition.assetId !== deleteAsset.assetId
      );

      mutate(
        async () => {
          await apiClient.deleteAsset(deleteAsset.assetId);

          return undefined;
        },
        {
          optimisticData: {
            ...resultPage,
            results: newDefinitions,
          },
          revalidate: false,
          populateCache: false,
        }
      );

      setDeleteModalOpen(false);

      ga4Event({
        category: 'workflows',
        action: 'asset_delete',
        label: 'success',
      });
    }
  };

  const pluralize = (count: number, text: string): string => {
    if (count === 1) return text;
    return `${text}s`;
  };

  return (
    <>
      <Box className="eds-grid eds-grid--m-cols-3">
        {resultPage.results.map(row => (
          <Card
            key={row.assetId}
            title={row.name}
            className="workflow-card eds-grid--m-row-1 eds-grid--items-center eds-spacing--p-24"
            data-testid={row.name}
          >
            <Box id="" className="eds-grid eds-grid--m-cols-6">
              <Link
                to={linkClick(row)}
                className={classNames('eds-grid--m-col-5 eds-type--title-5', {
                  disabled: !userHasAccess(row),
                  hoverable: userHasAccess(row),
                  overflow: row.name.length > 25 && !row.name.includes(' '),
                })}
              >
                {row.name}
                {row.currentUserAccess.length === 0 ? (
                  <Icon name="LockFilled" color="black" size="s" />
                ) : null}
              </Link>

              <Box onClick={event => event.stopPropagation()}>
                <Button
                  className={`${
                    row.currentUserAccess.length === 0 ? 'disabled' : ''
                  } eds-grid--m-col-1`}
                  size="small"
                  variant="secondary"
                  disabled={row.currentUserAccess.length === 0}
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setDeleteAsset({ assetId: row.assetId, name: row.name });
                  }}
                >
                  <Icon name="Delete" size="s" />
                </Button>
              </Box>
            </Box>

            <Text className="" font="body-3">
              {row.createdByUserId}
            </Text>
            <Text className="" font="body-3">
              {row.createTimestamp.split('T')[0]}
            </Text>
            <Text className="eds-color--grey-1" font="legal-1">
              {match(row.metadata)
                .with(
                  { description: P.when(d => d.length > 100) },
                  d => `${d.description.substring(0, 97)}...`
                )
                .with({ description: P.string }, () => row.metadata.description)
                .otherwise(() => null)}
            </Text>
            <Text font="body-3">{`${row.version.versionNumber} ${pluralize(
              row.version.versionNumber,
              'version'
            )}`}</Text>
          </Card>
        ))}
      </Box>

      <Modal
        isOpen={deleteModalOpen}
        onDismiss={() => setDeleteModalOpen(false)}
        headerSlot={<Text font="title-3">Confirm Delete</Text>}
        footerSlot={
          <Button onClick={onDelete} variant="secondary">
            <Icon name="Delete" size="m" enableFocus />
            Delete
          </Button>
        }
      >
        <Text>Are you sure you want to delete {deleteAsset?.name}?</Text>
      </Modal>

      <Box className="eds-spacing--mt-16">
        {resultPage.results.length === 0 ? (
          <Text font="body-1">No Workflows Found</Text>
        ) : (
          <PaginationFooter from={from} setFrom={setFrom} resultPage={resultPage} />
        )}
      </Box>
    </>
  );
}

export default WorkflowCardGrid;
