import { Box, Text, Card, Spinner } from '@nike/eds';
import { ReactNode } from 'react';
import { WorkflowStep } from '@nike.innovation/composure-sdk';
import { useWorkflow } from '../core/hooks/use-workflow';
import { IconFieldDisplay } from '../shared/icon-field-display';
import '../results/workflow-results.css';

function WorkflowStepCell({ id, name, form }: WorkflowStep) {
  return (
    <>
      {Object.entries(form).map(input => (
        <IconFieldDisplay key={input[1].id} input={input} font={undefined} />
      ))}
    </>
  );
}

function StepsCard({ children }: { children: ReactNode }) {
  return (
    <Card className="eds-elevation--2" style={{ marginTop: '1em' }}>
      {children}
    </Card>
  );
}

export interface StepCardProps {
  workflowId: string;
  versionId: string;
}

export default function WorkflowStepCard({ workflowId, versionId }: StepCardProps) {
  const { workflow, isLoading, isError } = useWorkflow(workflowId, versionId);

  if (isLoading) {
    return (
      <StepsCard>
        <Spinner size="large" />
      </StepsCard>
    );
  }

  if (workflow) {
    return (
      <StepsCard>
        <Text font="title-3" className="eds-spacing--mb-16">
          Workflow Preview
        </Text>

        <Box
          className="result-table-wrap eds-flex eds-flex-direction-row"
          style={{ columnGap: '2em' }}
        >
          {workflow.steps.map(step => (
            <Card key={step.id} className="step-card">
              <Text
                data-testid={step.name}
                key={step.name}
                font="title-5"
                className="eds-spacing--mb-16"
              >
                {step.name}
              </Text>

              <Box key={step.id} className="result-cell">
                {WorkflowStepCell(step)}
              </Box>
            </Card>
          ))}
        </Box>
      </StepsCard>
    );
  }

  return (
    <StepsCard>
      <Text>There was a problem retrieving steps: {isError}</Text>
    </StepsCard>
  );
}
