import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Text } from '@nike/eds';
import { match, P } from 'ts-pattern';
import { WorkflowResultTable } from './workflow-result-table';
import { useWorkflowResultsQuery } from '../../shared/hooks/use-workflow-results-query';
import Breadcrumbs from '../../shared/breadcrumbs/breadcrumbs';
import useWorkflow from '../core/hooks/use-workflow';
import { exportWorkflowResults } from './workflow-result-export';

export default function WorkflowResultsPage() {
  const { oktaAuth } = useOktaAuth();
  const { workflowId, versionId } = useParams();
  const [isDownloading, setIsDownloading] = useState(false);

  const { workflowResults, resultsLoading, resultsFetchErr } = useWorkflowResultsQuery(
    workflowId || '',
    versionId || '',
    `${oktaAuth.getAccessToken()}`
  );

  const { workflow, isLoading, isError } = useWorkflow(workflowId || '', versionId || '');

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { url: '/workflows', name: 'Workflows' },
          {
            url: `/workflows/${workflowId}/versions/${versionId}`,
            name: 'Details',
          },
          { url: '', name: 'Results' },
        ]}
      />
      <div className="eds-flex eds-flex--nowrap eds-flex--justify-content-space-between">
        <Text font="title-3" style={{ marginTop: '1em', marginBottom: '0.5em' }}>
          {workflow?.name} Results
        </Text>
        {workflow && !isLoading && (
          <Button
            className="eds-flex--align-self-flex-end"
            id="getresults"
            disabled={isDownloading || !workflowResults || workflowResults.count === 0}
            onClick={() => {
              setIsDownloading(true);
              exportWorkflowResults(
                workflow,
                workflow?.versionNumber.toString() || '',
                workflowResults.results,
                `${oktaAuth.getAccessToken()}`
              ).then(() => setIsDownloading(false));
            }}
          >
            {isDownloading ? <Spinner /> : 'Export Results'}
          </Button>
        )}
      </div>

      {match({ workflowResults, resultsLoading, resultsFetchErr })
        .with(
          { workflowResults: { count: 0 }, resultsLoading: false, resultsFetchErr: undefined },
          () => (
            <Text font="body-1" style={{ marginTop: '1em' }}>
              No results for this workflow.
            </Text>
          )
        )
        .with(
          {
            workflowResults: { count: P.number },
            resultsLoading: false,
            resultsFetchErr: undefined,
          },
          () =>
            workflow &&
            !isLoading && (
              <WorkflowResultTable
                rows={workflowResults.results}
                token={`${oktaAuth.getAccessToken()}`}
                workflow={workflow}
              />
            )
        )
        .with({ resultsLoading: false }, () => (
          <Text font="body-1" style={{ marginTop: '1em' }}>
            {resultsFetchErr.message}
          </Text>
        ))
        .otherwise(() => (
          <Spinner />
        ))}
    </>
  );
}
