import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const script = document.createElement('script');
script.src = 'https://cdn.jsdelivr.net/npm/rhino3dm@8.0.0-beta/rhino3dm.min.js';

script.addEventListener('load', () => {
  root.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
});
document.body.appendChild(script);
