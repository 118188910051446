import { useState } from 'react';

import { Entitlement } from '@nike.innovation/aurora';
import { Modal, Text, TextField, Button, Spinner, TextGroup } from '@nike/eds';

import { environment } from '../../../../environments/environment';

const defaultEntitlements: Entitlement[] = [
  {
    type: 'group',
    name: environment.adGroup,
    permissions: ['READ'],
  },
];

export interface NewMidsoleFormProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  newAsset: boolean;
  onSubmit: (
    assetName: string,
    meshFiles: FileList,
    midsoleBoundaryFiles: FileList,
    uvBoundaryFiles: FileList,
    entitlemets: Entitlement[]
  ) => Promise<{ assetId: string; versionId: string }>;
}

// TODO: pull this form out of a modal so its easy to test without overriding tappable
// our usage of the modal is also just on a single boolean, so you could argue theres no need to test it
export function NewMidsoleForm({ visible, setVisible, newAsset, onSubmit }: NewMidsoleFormProps) {
  // Form hooks
  const [midsoleName, setMidsoleName] = useState(newAsset ? '' : 'newVersion');
  const [meshFiles, setMeshFiles] = useState<FileList | undefined>();
  const [midsoleBoundaryFiles, setMidsoleBoundaryFiles] = useState<FileList | undefined>();
  const [uvBoundaryFiles, setUVBoundaryFiles] = useState<FileList | undefined>();

  const [isUploading, setIsUploading] = useState(false);

  return (
    <Modal
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={<Text font="title-3">Upload New Midsole Asset</Text>}
      footerSlot={
        <Button
          className="eds--dark"
          type="submit"
          disabled={
            midsoleName === '' ||
            meshFiles === undefined ||
            midsoleBoundaryFiles === undefined ||
            uvBoundaryFiles === undefined ||
            isUploading
          }
          onClick={async (e: any) => {
            setIsUploading(true);
            e.preventDefault();
            e.stopPropagation();

            if (midsoleName !== '' && meshFiles && midsoleBoundaryFiles && uvBoundaryFiles) {
              console.log('creating file');
              console.log('boundaryFiles: ', midsoleBoundaryFiles);
              try {
                const { assetId, versionId } = await onSubmit(
                  midsoleName,
                  meshFiles,
                  midsoleBoundaryFiles,
                  uvBoundaryFiles,
                  defaultEntitlements
                );
                setVisible(false);
              } catch (err) {
                console.log(err);
              }
            }

            setIsUploading(false);
          }}
        >
          {isUploading ? <Spinner /> : 'Submit'}
        </Button>
      }
    >
      <form style={{ width: '30em' }}>
        <TextGroup>
          {newAsset ? (
            <TextField
              type="text"
              required
              id="assetName"
              label="Name"
              onChange={(e: any) => {
                setMidsoleName(e.target.value);
              }}
            />
          ) : null}
          <TextField
            type="file"
            accept=".obj"
            multiple
            required
            id="mesh"
            label="Midsole Mesh"
            subtitle="*.obj"
            onChange={(e: any) => {
              setMeshFiles(e.target.files);
            }}
          />
          <TextField
            type="file"
            accept=".json,.svg,.dxf"
            multiple
            required
            id="boundary"
            label="2D Midsole Boundary"
            subtitle="*.json/.svg/.dxf"
            onChange={(e: any) => {
              setMidsoleBoundaryFiles(e.target.files);
            }}
          />

          <TextField
            type="file"
            accept=".dxf"
            multiple
            required
            id="boundary"
            label="UV Rectangle Boundary"
            subtitle="*.dxf"
            onChange={(e: any) => {
              console.log('files: ', e.target.files);
              setUVBoundaryFiles(e.target.files);
            }}
          />
        </TextGroup>
      </form>
    </Modal>
  );
}

export default NewMidsoleForm;
