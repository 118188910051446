import { Workflow } from '@nike.innovation/composure-sdk';
import { Box, Button, ButtonGroup, Icon, Spinner, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router';
import { useApiClient } from '../../shared/hooks/use-api-client';
import {
  genInitialOpState,
  workflowOperatorReducer,
} from '../core/reducers/workflow-operator-reducer';
import HorizontalLinearStepper from './stepper';
import { OperatorFormElement } from './operator-form-element';
import {
  createFileUploadList,
  generateWorkflowResult,
  createUploadPromises,
} from '../core/reducers/utils';
import { transformFileNames } from '../core/transformers/file-name-transformer';

export interface OperatorFormProps {
  workflow: Workflow;
}

export function OperatorForm({ workflow }: OperatorFormProps) {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('No access token found');
  }

  const user = oktaAuth.token.decode(accessToken).payload.sub;

  const initialState = genInitialOpState(workflow, user);
  const [state, dispatch] = useReducer(workflowOperatorReducer, initialState);

  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentStep = state.workflow.steps[state.currentStep];

  if (loading) {
    return (
      <Box
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <Box mb="1em">
      {!completed ? (
        <>
          <HorizontalLinearStepper
            steps={workflow.steps.map(x => x.name)}
            activeStep={state.currentStep}
          />

          {Object.entries(currentStep.form).map(x => (
            <Box key={x[1].id}>
              <OperatorFormElement
                input={x[1]}
                state={state.userData[state.currentStep][x[1].fieldName]}
                onChange={dispatch}
              />
            </Box>
          ))}

          <Box style={{ display: 'flex' }}>
            <ButtonGroup style={{ marginLeft: 'auto' }}>
              {state.currentStep > 0 && (
                <Button variant="secondary" onClick={() => dispatch({ kind: 'PREVIOUS_STEP' })}>
                  Previous
                </Button>
              )}
              {state.currentStep < state.workflow.steps.length - 1 ? (
                <Button
                  disabled={!state.isStepComplete[state.currentStep]}
                  onClick={() => dispatch({ kind: 'NEXT_STEP' })}
                >
                  Next
                </Button>
              ) : (
                <Button
                  disabled={!state.isStepComplete[state.currentStep]}
                  onClick={async () => {
                    setLoading(true);
                    setCompleted(true);

                    // transform the file names
                    const transformedResult = transformFileNames(state);
                    // Generate the result for persistence
                    const workflowResult = generateWorkflowResult(transformedResult);
                    const response = await apiClient.persistWorkflowResult(workflowResult);

                    // transform the presignedUrl response
                    const urlAndFileList = createFileUploadList(response, transformedResult);

                    // create the promises for the uploads
                    const promises = createUploadPromises(urlAndFileList);

                    try {
                      await Promise.all(promises);
                    } catch (err) {
                      console.error('Problem persisting files', err);
                      setLoading(false);
                    }
                    setLoading(false);
                  }}
                >
                  Finish
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </>
      ) : (
        <Box
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1em',
            textAlign: 'center',
          }}
        >
          <Box
            style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Text font="title-3">
              Workflow Complete <Icon name="CheckCircle" size="m" />
            </Text>
          </Box>
          <Box
            style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <ButtonGroup>
              <Button
                onClick={() => {
                  dispatch({ kind: 'RUN_AGAIN' });
                  setCompleted(false);
                }}
              >
                Run Again
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate(`/workflows/${workflow.id}/versions/${workflow.versionId}/results`);
                }}
              >
                View Results
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      )}
    </Box>
  );
}
