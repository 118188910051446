import { ReactElement } from 'react';
import { Text, Icon, TypographicStyles } from '@nike/eds';
import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { match, P } from 'ts-pattern';

import { ReactComponent as FileIcon } from './designer/icons/file.svg';
import { ReactComponent as NumberIcon } from './designer/icons/number.svg';
import { ReactComponent as SliderIcon } from './designer/icons/slider.svg';

import './icon-field-display.css';

type ButtonData = {
  title: string;
  icon: string | ReactElement;
  kind: WorkflowFormElementSettings['kind'];
};

export const buttonData: ButtonData[] = [
  {
    title: 'Text input',
    icon: 'Text',
    kind: 'text',
  },
  {
    title: 'Slider',
    icon: 'Filter',
    kind: 'slider',
  },
  {
    title: 'Select',
    icon: 'MultiSelect',
    kind: 'select',
  },
  {
    title: 'File upload',
    icon: 'Upload',
    kind: 'file',
  },
  {
    title: 'Number',
    icon: 'FormatNumberedList',
    kind: 'number',
  },
  {
    title: 'Date',
    icon: 'Calendar',
    kind: 'date',
  },
];

export interface IconFieldDisplayParameters {
  input: [string, WorkflowFormElementSettings] | string;
  font: TypographicStyles | undefined;
}
// function to display icon and some text for a workflow field
export function IconFieldDisplay({ input, font }: IconFieldDisplayParameters) {
  const fieldKind = match(input)
    .with(P.string, refined => refined)
    .with([P.string, { kind: P.string }], refined => refined[1].kind)
    .exhaustive();
  const fieldInfo = buttonData.find(element => element.kind === fieldKind);
  const iconElement = (
    <div title={fieldInfo?.title} className="icon-field-display">
      {match(fieldInfo?.icon)
        .with(P.string, refined => <Icon name={refined} size="m" />)
        .otherwise(refined => refined)}
    </div>
  );
  const textDisplay = match(input)
    .with(P.string, refined => (
      <Text className="icon-field-display" font={font} style={{ marginLeft: '0.5em' }}>
        <b>{fieldInfo?.title} field</b>
      </Text>
    ))
    .with([P.string, { kind: P.string }], refined => (
      <Text
        className="icon-field-display"
        font={font}
        data-testid={refined[1].fieldName}
        style={{ marginLeft: '1em' }}
      >
        {refined[1].fieldName}
      </Text>
    ))
    .exhaustive();

  return (
    <div style={{ marginBottom: '0.5em' }}>
      {iconElement}
      {textDisplay}
    </div>
  );
}
