import { AxiosResponse } from 'axios';

export const downloadBinaryFile = (data: Blob, filename: string): void => {
  const href = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
};

export const downloadFile = (data: Promise<AxiosResponse<any, any>>, filename: string) => {
  const blob = new Blob([JSON.stringify(data, null, '\t')], {
    type: 'application/json',
  });

  downloadBinaryFile(blob, filename);
};

export const getFile = (contentUrl: string, filename: string, oktaToken: string) => {
  fetch(contentUrl, { headers: { Authorization: `Bearer ${oktaToken}` } })
    .then(resp => resp.blob())
    .then(blob => {
      downloadBinaryFile(blob, filename);
    });
};

//
// On some browsers, like Chrome, we can't use a redirect to S3 with an Auth header. So we have to
// essentially do the redirect here. The endpoint returns the presigned URL which we then do
// a fetch against, without the Auth header.
//
export const getFileFromWorkflow = (contentUrl: string, filename: string, oktaToken: string) => {
  fetch(contentUrl, { headers: { Authorization: `Bearer ${oktaToken}` } })
    .then(resp => resp.text())
    .then(presignedUrl => {
      fetch(presignedUrl)
        .then(resp => resp.blob())
        .then(blob => {
          downloadBinaryFile(blob, filename);
        });
    });
};
