import { Box, Link, Text } from '@nike/eds';
import { Fragment } from 'react';

export type Breadcrumb = {
  url: string;
  name: string;
};

export interface BreadcrumbProps {
  crumbs: Breadcrumb[];
}

export function Breadcrumbs({ crumbs }: BreadcrumbProps) {
  const currentPage = crumbs[crumbs.length - 1];

  return (
    <Box className="eds-box eds-spacing--mb-16 eds-flex eds-flex--align-items-center eds-gap--8">
      {crumbs.slice(0, -1).map(c => (
        <Fragment key={c.url}>
          <Link href={c.url} variant="secondary" font="body-2">
            <strong>{c.name}</strong>
          </Link>

          <Text>
            <strong>/</strong>
          </Text>
        </Fragment>
      ))}

      {currentPage && <Text>{currentPage.name}</Text>}
    </Box>
  );
}

export default Breadcrumbs;
