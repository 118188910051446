import {
  Modal,
  Text,
  TextField,
  Button,
  Spinner,
  Card,
  Select,
  Box,
  Radio,
  RadioGroup,
} from '@nike/eds';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GrasshopperIO,
  ComposureClient,
  CreateOptions,
  VersionOptions,
  GrasshopperDefinition,
} from '@nike.innovation/composure-sdk';
import { mutate } from 'swr';
import { match, P } from 'ts-pattern';
import { Entitlement } from '@nike.innovation/aurora';
import DefinitionParameterTable from '../definition-parameter-table/definition-parameter-table';
import { ga4Event } from '../../../shared/utils/ga4-helpers/ga4-events';
import { useApiClient } from '../../../shared/hooks/use-api-client';
import { environment } from '../../../../environments/environment';

const publicEntitlements: Entitlement[] = [
  {
    type: 'group',
    name: environment.adGroup,
    permissions: ['READ'],
  },
];

async function uploadDefinition(
  options: CreateOptions,
  definition: GrasshopperDefinition | undefined,
  apiClient: ComposureClient,
  description: string | undefined
): Promise<{ assetId?: string | undefined; versionId?: string | undefined }> {
  return match(definition)
    .with({ id: P.string }, refined => {
      const versionOptions: VersionOptions = {
        ...options,
        id: refined.id,
        versionNotes: description,
      };
      return apiClient.createDefinitionVersion(versionOptions);
    })
    .otherwise(() => apiClient.createDefinition(options));
}

export interface DefinitionUploadFormProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  definition: GrasshopperDefinition | undefined;
  token: string;
  validateEndpoint: string;
}

// TODO: pull this form out of a modal so its easy to test without overriding tappable
// our usage of the modal is also just on a single boolean, so you could argue theres no need to test it
export function DefinitionUploadForm({
  visible,
  setVisible,
  definition,
  token,
  validateEndpoint,
}: DefinitionUploadFormProps) {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  // Form hooks
  const [file, setFile] = useState<File>();
  const [description, setDescription] = useState<string>();
  const [isValidating, setIsValidating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasErrors, setErrors] = useState(false);
  const [methodOfMake, setMethodOfMake] = useState<string[]>([]);
  const [validationErrMsg, setValidationErrMsg] = useState('');
  const [valueSelected, setValueSelected] = useState('public');
  const [entitlements, setEntitlements] = useState<Entitlement[]>(publicEntitlements);

  const [ghIo, setGhIo] = useState<GrasshopperIO | undefined>(undefined);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const options = ['Nectar', 'ACT', 'Siping'];

  // function handler for onChange events for input file selection
  const validateGrasshopperScript = (e: HTMLInputElement) => {
    setErrors(false);
    const fd = new FormData();
    if (e.files !== null) {
      fd.append('file', e.files[0]);

      setFile(e.files[0]);

      setIsValidating(true);
      axios
        .post(validateEndpoint, fd, config)
        .then(res => {
          setGhIo(res.data);
          setIsValidating(false);
        })
        .catch(err => {
          setIsValidating(false);
          setGhIo(undefined);
          setErrors(true);
          if (err.code === 'ERR_NETWORK') {
            setValidationErrMsg('Network error. Cannot connect with backend.');
            console.warn(
              'Network error. If running locally, please review composure-api README and check PORT environment variable.'
            );
          } else {
            setValidationErrMsg(err.response.data);
          }
          console.error(err);
        });
    }
  };

  return (
    <Modal
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={
        <Text font="title-3" as="h3">
          Upload New Grasshopper Definition
        </Text>
      }
      footerSlot={
        <Button
          className="eds--dark"
          type="submit"
          id="submit"
          disabled={isUploading || isValidating || hasErrors || ghIo === undefined}
          data-testid="submit"
          onClick={() => {
            setIsUploading(true);
            if (file && ghIo) {
              const fileType = file.name.split('.').pop()?.toLowerCase().replace(/\./g, '') || '';
              const uploadOptions: CreateOptions = {
                file,
                name: file.name,
                entitlements,
                fileType,
                io: {
                  inputs: ghIo.inputs,
                  outputs: ghIo.outputs,
                },
                description: description && !definition ? description : undefined,
                methodOfMake: definition?.methodOfMake ? definition.methodOfMake : methodOfMake,
                authToken: token,
              };
              uploadDefinition(uploadOptions, definition, apiClient, description)
                .then(({ assetId, versionId }) => {
                  console.log('Response: ', assetId, versionId);
                  mutate(assetId);
                  navigate(`/definitions/${assetId}/versions/${versionId}`);
                  if (!definition) {
                    ga4Event({ category: 'definitions', action: 'asset_upload', label: 'success' });
                  } else {
                    ga4Event({
                      category: 'definitions',
                      action: 'asset_version_upload',
                      label: 'success',
                    });
                  }
                  setIsUploading(false);
                  setVisible(false);
                })
                .catch(error => {
                  console.log(error);
                  if (!definition) {
                    ga4Event({ category: 'definitions', action: 'asset_upload', label: 'failure' });
                  } else {
                    ga4Event({
                      category: 'definitions',
                      action: 'asset_version_upload',
                      label: 'failure',
                    });
                  }
                  setIsUploading(false);
                });
            }
          }}
        >
          {isUploading ? <Spinner /> : 'Submit'}
        </Button>
      }
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <TextField
          type="file"
          accept=".gh,.ghx"
          onChange={e => {
            if (e.target !== null && e.target.files !== null) {
              validateGrasshopperScript(e.target);
            }
          }}
          id="file"
          hasErrors={hasErrors}
          errorMessage={validationErrMsg}
          label="Grasshopper File"
          data-testid="file"
        />

        {!definition && (
          <Select
            id="methodOfMake"
            data-testid="methodOfMake"
            options={options.map(x => ({ value: x, label: x }))}
            isMulti
            label="Method of Make"
            subtitle="Select zero or more tags:"
            onChange={(e: any) => {
              setMethodOfMake(e.map((x: any) => x.value));
            }}
            required="false"
          />
        )}
        {isValidating && (
          <Card
            className="eds-flex"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Spinner size="medium" />
            <Text style={{ marginLeft: '1em' }}>Validating</Text>
          </Card>
        )}

        <TextField
          id="description"
          label={definition ? 'Version Notes' : 'Description'}
          subtitle="Leave a description for the upload"
          hasErrors={false}
          placeholder="Description here"
          onChange={e => {
            if (e.target !== null && e.target.value !== null) {
              setDescription(e.target.value);
            }
          }}
          data-testid="description"
        />

        {!definition ? (
          <Box style={{ display: 'flex', marginTop: '20px' }}>
            <RadioGroup
              label="Visibility"
              name="privacy"
              id="Privacy-toggle"
              valueSelected={valueSelected}
              onChange={e => {
                // By default the uploader is automatically added as admin to entitlements without needing to be added
                // eslint-disable-next-line no-unused-expressions
                e.target.value === 'private'
                  ? setEntitlements([])
                  : setEntitlements(publicEntitlements);
                setValueSelected(e.target.value);
              }}
            >
              <Radio label="Public" value="public" id="public" data-testid="public" />
              <Radio label="Private" value="private" id="private" data-testid="private" />
            </RadioGroup>
          </Box>
        ) : null}
        {ghIo !== undefined && isValidating !== true && (
          <DefinitionParameterTable io={ghIo} setIo={setGhIo} />
        )}
      </form>
    </Modal>
  );
}

export default DefinitionUploadForm;
