import { Route, Routes } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { usePaginationParams } from '../shared/hooks/use-pagination-params';
import IndexMidsolePage from './index/index-midsole-page';
import ShowMidsolePage from './show/show-midsole-page';
import useMidsoleSearch from './shared/use-midsoles/use-midsole-search';

export function MidsoleRoutes() {
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const paginationParams = usePaginationParams();
  const { from, size, filterCurrentUser } = paginationParams;

  const currentUser = filterCurrentUser
    ? oktaAuth.token.decode(accessToken).payload.sub
    : undefined;

  const { midsoleSearchResult, mutate } = useMidsoleSearch(
    { from, size, createdByUserId: currentUser, runtime: 'grasshopper' },
    accessToken
  );

  return (
    <Routes>
      <Route
        path=":midsoleId/versions/:versionId"
        element={
          <ShowMidsolePage midsoleSearchResult={midsoleSearchResult} mutateSearch={mutate} />
        }
      />

      <Route
        path=""
        element={
          <IndexMidsolePage
            paginationParams={paginationParams}
            midsoleSearchResult={midsoleSearchResult}
            mutateSearch={mutate}
          />
        }
      />
    </Routes>
  );
}
