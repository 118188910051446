import { AssetVersions } from '@nike.innovation/aurora';
import { TableHeading, TableCell, Button, Table, Icon, Tooltip } from '@nike/eds';

import ga4Event from '../../../shared/utils/ga4-helpers/ga4-events';
import { getFile } from '../../../shared/utils/file-utils';

type ColumnHeader = {
  id: 'versionNumber' | 'createdByUserId' | 'actions' | 'createTimestamp';
  header: string;
};

const midsoleHeaders: ColumnHeader[] = [
  {
    id: 'versionNumber',
    header: 'Version',
  },
  {
    id: 'createdByUserId',
    header: 'Created by',
  },
  {
    id: 'createTimestamp',
    header: 'Date',
  },
  {
    id: 'actions',
    header: ' ',
  },
];

export interface MidsoleVersionTableProps {
  versions: AssetVersions;
  token: string;
}

/**
 * Allows you to create a tabulated Card that contains various data tables
 * @param
 * @returns
 */
export function MidsoleVersionTable({ versions, token }: MidsoleVersionTableProps) {
  const midsoleVersions = versions.map(x => {
    console.log('original artifacts', x.artifactReferences);
    const filteredArtifacts = x.artifactReferences
      .filter(artifact => artifact.name !== 'io.json')
      .sort((a, b) => (a.fileType < b.fileType ? -1 : 1));
    console.log('filtered artifacts', filteredArtifacts);
    console.log(
      'artifact names',
      filteredArtifacts.map(artifact => artifact.name)
    );
    return {
      ...x,
      artifactReferences: filteredArtifacts,
    };
  });

  return (
    <div className="eds-grid--col-full">
      <Table width="100%">
        <thead>
          <tr>
            {midsoleHeaders.map(column => (
              <TableHeading key={column.id}>{column.header}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {versions &&
            midsoleVersions.map(version => (
              <tr key={version.versionNumber}>
                <TableCell>{version.versionNumber}</TableCell>
                <TableCell>{version.createdByUserId}</TableCell>
                <TableCell>{version.createTimestamp.split('T')[0]}</TableCell>

                <TableCell onClick={event => event.stopPropagation()}>
                  {version.artifactReferences.map(artifact => (
                    <Tooltip bodySlot={artifact.name}>
                      <Button
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          getFile(artifact.contentUrl, artifact.name, token);

                          ga4Event({
                            category: 'midsoles',
                            action: 'asset_download',
                            label: 'success',
                          });
                        }}
                        beforeSlot={<Icon name="Download" />}
                      >
                        {artifact.fileType}
                      </Button>
                    </Tooltip>
                  ))}
                </TableCell>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default MidsoleVersionTable;
