import { Box, Text } from '@nike/eds';
import HelpCards from './help-cards/help-cards';

export function IndexHelpPage() {
  return (
    <>
      <Text font="title-1">Composure</Text>

      <Box className="eds-spacing--mt-32">
        <HelpCards />
      </Box>
    </>
  );
}

export default IndexHelpPage;
