import ReactGA from 'react-ga4';
import { useEffect } from 'react';

export interface Ga4EventProps {
  category: 'definitions' | 'midsoles' | 'workflows';
  action: 'asset_upload' | 'asset_version_upload' | 'asset_delete' | 'asset_download' | 'asset_run';
  label: 'success' | 'failure';
}
export function ga4Event(options: Ga4EventProps) {
  ReactGA.event(options);
}

export interface PageViewProps {
  page: string;
  title:
    | 'Definitions Page'
    | 'Definition Details Page'
    | 'Midsole Details Page'
    | 'Midsoles Page'
    | 'Run Definition Page'
    | 'Definition Solve Page'
    | 'Workflow Details Page'
    | 'Workflows Browse';
}

export function SendPageView({ page, title }: PageViewProps) {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page, title });
  }, []);
}

export default ga4Event;
