import { Route, Routes } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import DefinitionDetailsPage from './show/definition-details-page/definition-details-page';
import DefinitionsPage from './index/definitions-page/definitions-page';
import SolveResultPage from './solves/definition-solve-result-page';
import { usePaginationParams } from '../shared/hooks/use-pagination-params';
import useDefinitionSearch from '../shared/hooks/use-definition-search';

export function DefinitionRoutes() {
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const paginationParams = usePaginationParams({ pageSize: 12 });
  const { from, size, debouncedSearchTerm, createdByUserId } = paginationParams;

  const definitionsSearchResponse = useDefinitionSearch(
    { from, size, name: debouncedSearchTerm, createdByUserId, runtime: 'grasshopper' },
    accessToken
  );

  return (
    <Routes>
      <Route
        path=":definitionId/versions/:versionId"
        element={
          <DefinitionDetailsPage
            definitionSearchResult={definitionsSearchResponse.definitionSearchResult}
            mutateSearch={definitionsSearchResponse.mutate}
          />
        }
      />

      <Route
        path=":definitionId/versions/:versionId/solves/:timestamp"
        element={
          <SolveResultPage
            definitionSearchResult={definitionsSearchResponse.definitionSearchResult}
            mutateSearch={definitionsSearchResponse.mutate}
          />
        }
      />

      <Route
        path=""
        element={
          <DefinitionsPage
            definitionsSearchResponse={definitionsSearchResponse}
            paginationParams={paginationParams}
          />
        }
      />
    </Routes>
  );
}
