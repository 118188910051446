import { useNavigate } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Select } from '@nike/eds';
import { GrasshopperDefinition } from '@nike.innovation/composure-sdk';
import useDefinitionVersions from '../../../shared/hooks/use-definition-versions';

export function DefinitionVersionChooser({ definition }: { definition: GrasshopperDefinition }) {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const { definitionVersions } = useDefinitionVersions(definition.id, accessToken);
  const onChange = (newValue: { value: string } | null) => {
    if (newValue) {
      navigate(`/definitions/${definition.id}/versions/${newValue.value}`);
    }
  };

  return (
    <Box style={{ width: 180 }}>
      <Select
        options={definitionVersions?.map(version => ({
          label: version.versionNumber,
          value: version.versionId,
        }))}
        id="definition-version-select"
        required={false}
        label="Version"
        hideLabel
        defaultValue={{ label: definition.versionNumber, value: definition.versionId }}
        onChange={onChange}
        className="eds-spacing--mb-16"
      />
    </Box>
  );
}
