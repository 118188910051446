import { useState, Fragment } from 'react';
import { Table, TableCell, TableHeading, Spinner, StatusIndicator, Text, Icon } from '@nike/eds';
import { match, P } from 'ts-pattern';
import { Workflow, WorkflowResult } from '@nike.innovation/composure-sdk';
import ResultModal from './workflow-result-modal';

import './workflow-results.css';

export const capitalizeFirstLetters = (a: string) => {
  const words = a.split(' ');

  return words
    .map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const rest = word.slice(1).toLowerCase();
      return firstLetter + rest;
    })
    .join(' ');
};

export const convertDateToDisplayString = (a: Date): string => {
  const date = new Date(a);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };

  return date.toLocaleString(undefined, options);
};

export const getWorkflowResultData = (
  stepId: string,
  fieldName: string,
  workflowResult: WorkflowResult
): unknown => {
  const step = workflowResult.steps.find(a => a.stepId === stepId);
  if (!step) return null;
  return step.userInput[fieldName];
};

export const WorkflowResultCell = (userInput: unknown) =>
  match(userInput)
    .with(P.nullish, () => <Text>--</Text>)
    .with(P.union(P.string, P.number), refined => <Text>{refined}</Text>)
    .with(P.array({ fileName: P.string }), refined =>
      refined.map(x => (
        <Text style={{ display: 'block' }} key={x.fileName}>
          {x.fileName}
        </Text>
      ))
    )
    .with({ label: P.string, value: P.string }, refined => <Text>{refined.value}</Text>)
    .otherwise(() => <Text>--</Text>);

export const WorkflowResultAsText = (userInput: unknown) =>
  match(userInput)
    .with(P.nullish, () => '')
    .with(P.union(P.string, P.number), refined => refined)
    .with(P.array({ fileName: P.string }), refined => refined.map(x => x.fileName))
    .with({ label: P.string, value: P.string }, refined => refined.value)
    .otherwise(() => '');

export const WorkflowResultStatusLabel = (status: string) =>
  match(status)
    .with('completed', () => (
      <StatusIndicator label={capitalizeFirstLetters(status)} status="success" />
    ))
    .with('in progress', () => (
      <StatusIndicator label={capitalizeFirstLetters(status)} status="neutral" />
    ))
    .with('cancelled', () => (
      <StatusIndicator label={capitalizeFirstLetters(status)} status="inactive" />
    ))
    .with('failed', () => (
      <StatusIndicator label={capitalizeFirstLetters(status)} status="danger" />
    ))
    .otherwise(() => <StatusIndicator label={capitalizeFirstLetters(status)} status="neutral" />);

export const WorkflowFieldIcon = (kind: string) =>
  match(kind)
    .with('user', () => <Icon name="Profile" size="s" />)
    .with('time', () => <Icon name="Clock" size="s" />)
    .with('file', () => <Icon name="Upload" size="s" />)
    .with('text', () => <Icon name="Text" size="s" />)
    .with('number', () => <Icon name="PlusMinus" size="s" />)
    .otherwise(() => undefined);

export interface WorkflowResultTableProps {
  // TODO props updated, new type probably needed.
  rows: WorkflowResult[];
  token: string;
  workflow: Workflow;
}

/**
 * Renders a table of `DefinitionSolveDynamoRow`s for the user. These are Dynamo specific objects and
 * different from DefinitionSolve. Intended use of this component is a simple browsing experience
 * @param { rows } - array of DefinitionSolveDynamoRow objects from composure-sdk
 * @returns
 */
export function WorkflowResultTable({ rows, token, workflow }: WorkflowResultTableProps) {
  const [rowModalVis, setRowModalVis] = useState(false);
  const [rowModalValue, setRowModalValue] = useState<WorkflowResult>();

  const metadataColumns = [
    {
      header: 'User',
    },
    {
      header: 'Started At',
    },
    {
      header: 'Completed At',
    },
    {
      header: 'Status',
    },
  ];

  return (
    <div className="result-table-wrap eds-elevation--4" style={{ marginTop: '1em' }}>
      {rowModalValue ? (
        <ResultModal
          workflow={workflow}
          workflowResult={rowModalValue}
          rowModalVis={rowModalVis}
          setRowModalVis={setRowModalVis}
          token={token}
        />
      ) : null}
      <Table width="100%">
        <thead>
          <tr>
            {/*
              Generate top-level (group) headers. This includes Metadata and headers for each step (step1, step2, ..., stepn)
            */}
            <TableHeading>Metadata</TableHeading>
            <TableHeading />
            <TableHeading />
            <TableHeading />
            {workflow.steps.map(step => [
              <TableHeading key={`StepId${step.id}`}>
                {workflow?.steps.find(x => x.id === step.id)?.name ?? step.id}
              </TableHeading>,
              Array.from({ length: Object.entries(step.form).length - 1 }, () => <TableHeading />),
            ])}
          </tr>
          <tr>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>
              {/*
                Generate secondary headers. This includes the metadata columns as well as a column for each field in a step
              */}
              {metadataColumns.map(column => (
                <TableHeading title={column.header} key={column.header}>
                  {column.header}
                </TableHeading>
              ))}
              {workflow.steps.map(step => (
                <Fragment key={step.id}>
                  {Object.entries(step.form).map((form, i) => [
                    <TableHeading key={`FieldId${form[1].id}`}>
                      {form[1].fieldName}
                      {WorkflowFieldIcon(form[1].kind)}
                    </TableHeading>,
                  ])}
                </Fragment>
              ))}
            </>
          </tr>
        </thead>

        <tbody>
          {match(rows)
            .with([], () => <Text>There are no results for this workflow</Text>)
            .with(P.array({ id: P.string }), refined =>
              refined
                .sort((a, b) => (a.startedAt < b.startedAt ? 1 : -1))
                .map(workflowResult => (
                  <tr
                    className="result-row"
                    key={workflowResult.id}
                    onClick={() => {
                      setRowModalVis(true);
                      setRowModalValue(workflowResult);
                    }}
                  >
                    <TableCell>{workflowResult.steps[0].user}</TableCell>
                    <TableCell>{convertDateToDisplayString(workflowResult.startedAt)}</TableCell>
                    <TableCell>
                      {workflowResult.completedAt
                        ? convertDateToDisplayString(workflowResult.completedAt)
                        : '--'}
                    </TableCell>
                    <TableCell>{WorkflowResultStatusLabel(workflowResult.status)}</TableCell>
                    {workflow.steps.map(step =>
                      Object.entries(step.form).map((form, i) => [
                        <TableCell key={`FieldId${form[1].id}`}>
                          {WorkflowResultCell(
                            getWorkflowResultData(step.id, form[1].fieldName, workflowResult)
                          )}
                        </TableCell>,
                      ])
                    )}
                  </tr>
                ))
            )
            .otherwise(() => (
              <Text>There was a problem loading the results of this workflow</Text>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
