import { Route, Routes } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import WorkflowsBrowse from './workflows-browse';
import WorkflowDetailsPage from './workflow-details/workflow-details-page';
import WorkflowResultsPage from './results/workflow-results-page';
import { usePaginationParams } from '../shared/hooks/use-pagination-params';
import useDefinitionSearch from '../shared/hooks/use-definition-search';
import { NewWorkflowPage } from './new/new-workflow-page';
import { NewWorkflowResultPage } from './results/create-new-workflow-result-page';
import { NewWorkflowVersionPage } from './versions/new/new-workflow-version-page';

export function WorkflowRoutes() {
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const paginationParams = usePaginationParams({ pageSize: 12 });
  const { from, size, debouncedSearchTerm, createdByUserId } = paginationParams;

  const definitionsSearchResponse = useDefinitionSearch(
    { from, size, name: debouncedSearchTerm, createdByUserId, runtime: 'workflow' },
    accessToken
  );

  return (
    <Routes>
      <Route path="new" element={<NewWorkflowPage />} />

      <Route path=":workflowId/versions/:versionId/new" element={<NewWorkflowVersionPage />} />

      <Route
        path=""
        element={
          <WorkflowsBrowse
            definitionsSearchResponse={definitionsSearchResponse}
            paginationParams={paginationParams}
          />
        }
      />

      <Route
        path=":workflowId/versions/:versionId"
        element={
          <WorkflowDetailsPage
            definitionSearchResult={definitionsSearchResponse.definitionSearchResult}
            mutateSearch={definitionsSearchResponse.mutate}
          />
        }
      />
      <Route
        path=":workflowId/versions/:versionId/results"
        element={
          <WorkflowResultsPage
          // definitionSearchResult={definitionsSearchResponse.definitionSearchResult}
          // mutateSearch={definitionsSearchResponse.mutate}
          />
        }
      />

      <Route
        path=":workflowId/versions/:versionId/results/new"
        element={<NewWorkflowResultPage />}
      />
    </Routes>
  );
}
