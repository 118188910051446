import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { match } from 'ts-pattern';

/**
 * Accepts field metadata and the field type to convert to and returns a default settings object
 * @param fieldType
 * @param id
 * @param fieldName
 * @param required
 * @returns
 */
export const createDefaultFormElementSettings = (
  fieldType: WorkflowFormElementSettings['kind'],
  id: string,
  fieldName: string,
  required: boolean
): WorkflowFormElementSettings =>
  match(fieldType)
    .with('slider', kind => ({
      id,
      fieldName,
      required,
      kind,
      settings: {
        minimum: 0,
        maximum: 10,
        default: 2,
        step: 1,
      },
    }))
    .with('number', kind => ({
      id,
      fieldName,
      required,
      kind,
      settings: {
        default: 0,
      },
    }))
    .with('file', kind => ({
      id,
      fieldName,
      required,
      kind,
      settings: {
        acceptMultiple: false,
        accepts: '',
        message: '',
        fileTransform: [{ kind: 'fileName' } as const, { kind: 'fileExtension' } as const],
      },
    }))
    .with('text', kind => ({
      id,
      fieldName,
      required,
      kind,
      settings: {
        placeholder: '',
        message: '',
        default: '',
      },
    }))
    .with('select', kind => ({
      id,
      fieldName,
      required,
      kind,
      settings: { options: [] },
    }))
    .with('toggle', kind => ({
      id,
      fieldName,
      kind,
      required,
      settings: {
        default: false,
      },
    }))
    .with('date', kind => ({
      id,
      fieldName,
      kind,
      required,
      settings: {
        withLocalTime: false,
        allowPast: true,
        allowFuture: true,
      },
    }))
    .exhaustive();
