import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {
  Text,
  Box,
  TableHeading,
  TableCell,
  Tooltip,
  IconButton,
  Table,
  Spinner,
  StatusIndicator,
} from '@nike/eds';
import { P, match } from 'ts-pattern';
import { useState } from 'react';
import { useApiClient } from '../hooks/use-api-client';
import { VersionDescriptionCell } from './description-cell';
import { ga4Event } from '../utils/ga4-helpers/ga4-events';

import useDefinitionVersions from '../hooks/use-definition-versions';
import { sleep } from '../utils/sleep';
import { getFile } from '../utils/file-utils';

import './version-table.css';

type ColumnHeader = {
  id:
    | 'versionNumber'
    | 'uploadDate'
    | 'tags'
    | 'filename'
    | 'createdByUserId'
    | 'versionNotes'
    | 'actions';
  header: string;
};

const headers: ColumnHeader[] = [
  {
    id: 'versionNumber',
    header: 'Version',
  },
  {
    id: 'uploadDate',
    header: 'Uploaded on',
  },
  {
    id: 'tags',
    header: '',
  },
  {
    id: 'filename',
    header: 'File Name',
  },
  {
    id: 'createdByUserId',
    header: 'Created by',
  },
  {
    id: 'versionNotes',
    header: 'Version Notes',
  },
  {
    id: 'actions',
    header: ' ',
  },
];

/**
 * Allows you to create a tabulated Card that contains various data tables
 * @param
 * @returns
 */
interface VersionTableProps {
  assetId: string;
  currentVersionId: string;
  downloadEnabled: boolean;
}

export function DefinitionVersionTable({
  assetId,
  currentVersionId,
  downloadEnabled,
}: VersionTableProps) {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const [publishing, setPublishing] = useState('');

  const {
    definitionVersions,
    versionsAreLoading,
    versionError,
    mutate: mutateVersions,
  } = useDefinitionVersions(assetId, accessToken);

  const onPublish = (versionId: string) => {
    setPublishing(versionId);
    const newVersions = definitionVersions?.map(version => {
      if (version.versionId === versionId) {
        return { ...version, tags: [...version.tags, 'PUBLISHED'] };
      }
      return { ...version, tags: [...version.tags.filter(tag => tag !== 'PUBLISHED')] };
    });
    mutateVersions(
      async () => {
        await apiClient.publishAssetVersion(assetId, versionId);
        await sleep(1000);
        setPublishing('');
        return undefined;
      },
      {
        optimisticData: newVersions,
      }
    );
  };

  const grasshopperDefinitionVersions = definitionVersions?.map(x => {
    const filteredArtifacts = x.artifactReferences.filter(artifact => artifact.name !== 'io.json');
    return {
      ...x,
      artifactReferences: filteredArtifacts,
    };
  });

  const parseDate = (date: string): string => date.substring(0, date.indexOf('T'));

  return (
    <>
      <Box className="eds-spacing--px-56" maxWidth="1536px" ml="auto" mr="auto" />

      <div className="eds-grid--col-full">
        {match({ grasshopperDefinitionVersions, versionsAreLoading, versionError })
          .with(
            {
              grasshopperDefinitionVersions: P.when(n => n?.length === 0),
              versionsAreLoading: false,
              versionError: undefined,
            },
            () => (
              <Text font="body-1" style={{ marginTop: '1em' }}>
                No versions found for definition.
              </Text>
            )
          )
          .with({ versionsAreLoading: false, versionError: undefined }, () => (
            <Table width="100%" style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  {headers.map(column => (
                    <TableHeading key={column.id}>{column.header}</TableHeading>
                  ))}
                </tr>
              </thead>

              <tbody>
                {grasshopperDefinitionVersions?.map(version => (
                  <tr
                    key={version.versionNumber}
                    className={
                      currentVersionId === version.versionId
                        ? 'definition-version-row selected'
                        : 'definition-version-row'
                    }
                  >
                    <TableCell>{version.versionNumber}</TableCell>

                    <TableCell>{parseDate(version.createTimestamp)}</TableCell>

                    <TableCell>
                      {version.tags.includes('PUBLISHED') ? (
                        <StatusIndicator
                          label="Published"
                          status="neutral"
                          style={{
                            backgroundColor: 'var(--eds-color-grey-4)',
                            borderRadius: 'var(--eds-radii-4)',
                            padding: '2px 8px',
                          }}
                        />
                      ) : null}
                    </TableCell>

                    <VersionDescriptionCell version={version} />

                    <TableCell>
                      {version.artifactReferences[0] && downloadEnabled && (
                        <Tooltip bodySlot="Download File" enableFocus>
                          <IconButton
                            label="download"
                            size="small"
                            variant="secondary"
                            onClick={() => {
                              getFile(
                                version.artifactReferences[0].contentUrl,
                                version.artifactReferences[0].name,
                                accessToken
                              );

                              ga4Event({
                                category: 'definitions',
                                action: 'asset_download',
                                label: 'success',
                              });
                            }}
                            icon="Download"
                          />
                        </Tooltip>
                      )}

                      <Tooltip bodySlot="Mark as Published" enableFocus>
                        {publishing === version.versionId ? (
                          <Spinner />
                        ) : (
                          <IconButton
                            label="publish"
                            size="small"
                            variant="secondary"
                            disabled={
                              publishing === version.versionId || version.tags.includes('PUBLISHED')
                            }
                            onClick={() => {
                              onPublish(version.versionId);
                            }}
                            icon="Rating"
                          />
                        )}
                      </Tooltip>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))
          .with({ versionsAreLoading: false, versionError: { message: P.string } }, () => (
            <Text font="body-1" style={{ marginTop: '1em' }}>
              {versionError.message}
            </Text>
          ))
          .otherwise(() => (
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner title="Loading" />
            </Box>
          ))}
      </div>
    </>
  );
}
