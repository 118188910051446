import { useEffect, useState } from 'react';
import { KeyedMutator } from 'swr';
import { Text, Box, Card, Spinner, Select, Button, Toggle, TextField, Icon } from '@nike/eds';

import { useOktaAuth } from '@okta/okta-react';
import { AssetSearchResponse, Aurora, AuroraBaseUrl, Entitlement } from '@nike.innovation/aurora';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import MidsoleAssetTable from './asset-table/asset-table';
import NewMidsoleForm from '../shared/new-midsole-form/new-midsole-form';
import midsoleFormSubmit from '../shared/new-midsole-form/new-midsole-create';
import { SendPageView } from '../../shared/utils/ga4-helpers/ga4-events';
import { PaginationParams } from '../../shared/hooks/use-pagination-params';

// import styles from './definitions-page.module.css';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

export function IndexMidsolePage({
  paginationParams: {
    setSearchParams,
    from,
    setFrom,
    size,
    setSize,
    filterCurrentUser,
    setFilterCurrentUser,
    debouncedSearchTerm,
    setSearchValue,
  },
  midsoleSearchResult,
  mutateSearch,
}: {
  paginationParams: PaginationParams;
  midsoleSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  SendPageView({ page: window.location.pathname, title: 'Midsoles Page' });

  const [uploadModalVis, setUploadModalVis] = useState(false);

  useEffect(() => {
    const newSearchParams: Record<string, string> = {
      size: String(size),
      from: String(from),
      currentUser: String(filterCurrentUser),
    };

    setSearchParams(newSearchParams, { replace: true });
  }, [from, size, filterCurrentUser, setSearchParams]);

  /**
   * Utility function called when a user clicks Upload in the NewMidsoleForm
   * @param midsoleName
   * @param meshFiles
   * @param boundaryFiles
   * @returns
   */

  const onSubmit = (
    assetName: string,
    meshFiles: FileList,
    midsoleBoundaryFiles: FileList,
    uvBoundaryFiles: FileList,
    entitlements: Entitlement[]
  ): Promise<{ assetId: string; versionId: string }> =>
    midsoleFormSubmit(
      aurora,
      { midsoleName: assetName },
      meshFiles,
      midsoleBoundaryFiles,
      uvBoundaryFiles,
      entitlements,
      navigate,
      `${oktaAuth.getAccessToken()}`
    );

  return (
    <>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-16">
        <Text font="title-1">Midsoles</Text>

        <NewMidsoleForm
          visible={uploadModalVis}
          setVisible={setUploadModalVis}
          newAsset
          onSubmit={onSubmit}
        />

        <Button className="eds--dark" variant="primary" onClick={() => setUploadModalVis(true)}>
          Upload New Midsole
        </Button>
      </Box>

      <Box className="eds-flex eds-flex--justify-content-space-between eds-flex--align-items-flex-end eds-spacing--mb-32">
        <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--24">
          <Select
            id="resultsPerPageSelect"
            label="Results Per Page"
            defaultValue={{ value: size, label: size }}
            options={[
              { value: 12, label: 12 },
              { value: 24, label: 24 },
              { value: 36, label: 36 },
            ]}
            onChange={(e: any) => {
              setSize(e.value);
              setFrom(0);
            }}
            isSearchable={false}
            required="false"
          />

          <Toggle
            id="currentUserFilterToggle"
            label="Only Show My Midsoles"
            size="large"
            onChange={() => {
              setFilterCurrentUser(!filterCurrentUser);
              setFrom(0);
            }}
            checked={filterCurrentUser}
            className="eds-spacing--mb-8"
          />
        </Box>

        <Box style={{ minWidth: '350px' }}>
          <TextField
            id="definitionSearch"
            onChange={e => {
              if (e.target && e.target.value && e.target.value.length > 0) {
                setSearchValue(e.target.value);
              } else {
                setSearchValue(undefined);
              }
            }}
            label=""
            hideLabel
            beforeSlot={<Icon name="Search" />}
            placeholder="Search by Name"
          />
        </Box>
      </Box>

      {midsoleSearchResult ? (
        <MidsoleAssetTable
          resultPage={midsoleSearchResult}
          from={from}
          setFrom={setFrom}
          mutate={mutateSearch}
        />
      ) : (
        <Spinner size="large" />
      )}
    </>
  );
}

export default IndexMidsolePage;
