import { Entitlement } from '@nike.innovation/aurora';
import { MultiValue } from '@nike/eds';
import { match } from 'ts-pattern';
import { BaseAsset } from '@nike.innovation/composure-sdk';

import { roleToPermissions } from '../utils/entitlement-utils';

export type CollaboratorState = {
  // List of entitlements that is selected in the 'Collaborators' multi-select box
  selectedCollaborators: Entitlement[];

  // List of entitlements that is selected in the 'AD Groups' multi-select box
  selectedGroups: Entitlement[];

  // List of unique entitlements across the selected collaborators and groups; used for showing the list of permissions
  displayedCollaborators: Entitlement[];

  // List of entitlements that has been saved
  savedCollaborators: Entitlement[];
};

export type CollaboratorAction =
  | { kind: 'SELECT_COLLABORATORS'; newCollaborators: MultiValue<{ value: string; label: string }> }
  | { kind: 'SELECT_GROUPS'; newGroups: MultiValue<{ value: string; label: string }> }
  | { kind: 'SAVE_COLLABORATORS' }
  | { kind: 'UPDATE_COLLABORATOR'; name: string; newRole: string }
  | { kind: 'REMOVE_COLLABORATOR'; name: string };

export const genInitialCollaboratorState = (asset: BaseAsset): CollaboratorState => ({
  selectedCollaborators: [],
  selectedGroups: [],
  displayedCollaborators:
    asset.entitlements.filter(entitlement => !entitlement.name.includes('App.Composure')) || [],
  savedCollaborators:
    asset.entitlements.filter(entitlement => !entitlement.name.includes('App.Composure')) || [],
});

export const collaboratorReducer = (
  state: CollaboratorState,
  action: CollaboratorAction
): CollaboratorState =>
  match(action)
    .with({ kind: 'SELECT_COLLABORATORS' }, refined => {
      const selectedCollaborators = refined.newCollaborators.map(
        value =>
          ({
            type: 'user',
            name: value.value,
            permissions: ['READ', 'WRITE'],
          } as Entitlement)
      );
      return {
        ...state,
        selectedCollaborators,
        displayedCollaborators: state.savedCollaborators
          .concat(selectedCollaborators)
          .concat(state.selectedGroups)
          .filter((c1, index, array) => array.findIndex(c2 => c2.name === c1.name) === index),
      };
    })
    .with({ kind: 'SELECT_GROUPS' }, refined => {
      const selectedGroups = refined.newGroups.map(
        value =>
          ({
            type: 'group',
            name: value.value,
            permissions: ['READ', 'WRITE'],
          } as Entitlement)
      );
      return {
        ...state,
        selectedGroups,
        displayedCollaborators: state.savedCollaborators
          .concat(selectedGroups)
          .concat(state.selectedCollaborators)
          .filter((c1, index, array) => array.findIndex(c2 => c2.name === c1.name) === index),
      };
    })
    .with({ kind: 'SAVE_COLLABORATORS' }, () => ({
      ...state,
      savedCollaborators: state.displayedCollaborators,
    }))
    .with({ kind: 'UPDATE_COLLABORATOR' }, refined => ({
      ...state,
      selectedCollaborators: state.selectedCollaborators.map(c => {
        if (refined.name === c.name) {
          return { ...c, permissions: roleToPermissions(refined.newRole) } as Entitlement;
        }
        return c;
      }),
      selectedGroups: state.selectedGroups.map(c => {
        if (refined.name === c.name) {
          return { ...c, permissions: roleToPermissions(refined.newRole) } as Entitlement;
        }
        return c;
      }),
      displayedCollaborators: state.displayedCollaborators.map(c => {
        if (refined.name === c.name) {
          return { ...c, permissions: roleToPermissions(refined.newRole) } as Entitlement;
        }
        return c;
      }),
    }))
    .with({ kind: 'REMOVE_COLLABORATOR' }, refined => ({
      ...state,
      selectedCollaborators: state.selectedCollaborators.filter(c => c.name !== refined.name),
      selectedGroups: state.selectedGroups.filter(c => c.name !== refined.name),
      displayedCollaborators: state.displayedCollaborators.filter(c => c.name !== refined.name),
    }))
    .exhaustive();
