import { Box, Tab, TabGroup } from '@nike/eds';
import { match } from 'ts-pattern';
import { ReactElement, ReactNode, useState } from 'react';

export interface ColumnHeader {
  Header: string;
}

export type tabsntableTuple = [
  {
    value: string;
    label: string;
    disabled?: boolean;
    color?:
      | 'eds-color--black'
      | 'eds-color--brand-red'
      | 'eds-color--brand-orange'
      | 'eds-color--grey-2';
  },
  ReactNode
];

export interface TabtableProps {
  // Array of tuples that contain the tabs, and their relevant table in JSX
  tabsntables: tabsntableTuple[];
}

export function createTab(
  [{ value, label, disabled, color = 'eds-color--black' }, element]: tabsntableTuple,
  i: number
): ReactElement {
  return match({
    value,
    label,
    disabled,
    color,
  })
    .with({ disabled: true }, meta => (
      <Tab
        disabled
        className={color}
        key={value}
        value={i.toString()}
        id={value}
        data-testid={`${value}-tab`}
      >
        {label}
      </Tab>
    ))
    .otherwise(meta => (
      <Tab
        className={color}
        key={value}
        value={i.toString()}
        id={value}
        data-testid={`${value}-tab`}
      >
        {label}
      </Tab>
    ));
}

/**
 * Allows you to create a tabulated Card that contains various data tables
 * @param tabsntable - [{value: string, label: string}, JSX.Element][] - tab config alongside with table
 * @returns
 */
export function Tabtable({ tabsntables }: TabtableProps) {
  const [activeTab, setActiveTab] = useState(tabsntables[0][0].value);
  const [activeIndex, setActiveIndex] = useState('0');

  return (
    <Box className="eds-grid eds-grid--m-cols-4">
      <Box className="eds-grid--col-4">
        <Box>
          <>
            <TabGroup
              name="definition-tab-group"
              activeId={activeTab}
              onChange={e => {
                setActiveTab(e.target.id);
                setActiveIndex(e.target.value);
              }}
              className="eds-spacing--mb-16"
            >
              {tabsntables.map((x, i) => createTab(x, i))}
            </TabGroup>

            {tabsntables[parseInt(activeIndex, 10)][1]}
          </>
        </Box>
      </Box>
    </Box>
  );
}

export default Tabtable;
