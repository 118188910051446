import { FormEvent, Fragment, useState, useReducer, useEffect } from 'react';
import { FormControlLabel } from '@mui/material';
import { mutate } from 'swr';
import { useOktaAuth } from '@okta/okta-react';
import { Box, RadioGroup, Radio, Text, Button, Spinner, Select, Snackbar, Snack } from '@nike/eds';

import { ComposureClient, BaseAsset } from '@nike.innovation/composure-sdk';
import { AssetUpdateResponse, Entitlement } from '@nike.innovation/aurora';
import { useDebounce, useOktaUsers } from '@nike.innovation/ui-components';

import {
  CollaboratorState,
  collaboratorReducer,
  genInitialCollaboratorState,
} from './collaborators-reducer';
import { useApiClient } from '../hooks/use-api-client';
import { environment } from '../../../environments/environment';
import { getCurrentUserAccess, isOwner, permissionsToRoleString } from '../utils/entitlement-utils';
import { useADGroups } from '../hooks/use-okta-groups';
import { Collaborator } from './collaborator';

import './permissions-tab.css';

/*  This function will start with an empty array, then add the current user every time.
    (Only ADMIN users can change this setting so currently it will only be the uploader for new assets)
    Then the ad group is also added when public is set. The original entitlements will be overwritten.
*/
async function updateAssetPrivacy(
  assetId: string,
  isPublic: boolean,
  collaborators: Entitlement[],
  apiClient: ComposureClient
): Promise<AssetUpdateResponse> {
  const entitlements: Entitlement[] = [
    {
      type: 'group',
      name: 'App.Composure.Developers',
      permissions: ['READ', 'WRITE', 'ADMIN'],
    },
  ];

  if (isPublic) {
    entitlements.push({
      type: 'group',
      name: environment.adGroup,
      permissions: ['READ'],
    });
  }

  collaborators.forEach(collaborator => entitlements.push(collaborator));
  return apiClient.updateAssetEntitlements(assetId, entitlements);
}

export function AddCollaborators({ asset, versionId }: { asset: BaseAsset; versionId: string }) {
  const { oktaAuth, authState } = useOktaAuth();
  const apiClient = useApiClient();
  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const userName = authState?.accessToken?.claims?.sub || '';
  // eslint-disable-next-line dot-notation
  const claims = authState?.accessToken?.claims['groups']
    ? // eslint-disable-next-line dot-notation
      (authState?.accessToken?.claims['groups'] as Array<string>)
    : [];

  const initialState: CollaboratorState = genInitialCollaboratorState(asset);
  const [collabState, dispatch] = useReducer(collaboratorReducer, initialState);

  const [isPublic, setIsPublic] = useState(!!asset.isPublic);
  const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [collaboratorsChanged, setCollaboratorsChanged] = useState(false);
  const [initialSelection, setInitialSelection] = useState(asset.isPublic ? 'public' : 'private');
  const [currentSelection, setCurrentSelection] = useState(asset.isPublic ? 'public' : 'private');

  const [userStartsWith, setUserStartsWith] = useState('');
  const [groupStartsWith, setGroupStartsWith] = useState('');

  const debouncedUserStartsWith = useDebounce(userStartsWith, 500);
  const debouncedGroupStartsWith = useDebounce(groupStartsWith, 500);

  const { oktaUsers, isUsersLoading } = useOktaUsers(
    environment.aegisBaseUrl,
    debouncedUserStartsWith
  );
  const { adGroups, isGroupsLoading } = useADGroups(debouncedGroupStartsWith, accessToken);

  const [showSnack, setShowSnack] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setButtonDisabled(true);

    await updateAssetPrivacy(asset.id, isPublic, collabState.displayedCollaborators, apiClient);

    setShowSnack(true);
    setButtonDisabled(false);
    dispatch({ kind: 'SAVE_COLLABORATORS' });
    dispatch({ kind: 'SELECT_COLLABORATORS', newCollaborators: [] });
    dispatch({ kind: 'SELECT_GROUPS', newGroups: [] });
    mutate([asset.id, versionId]);
  };

  if (!getCurrentUserAccess(asset.entitlements, userName, claims).includes('ADMIN')) {
    return null;
  }

  return (
    <>
      <Snackbar>
        {showSnack && (
          <Snack id="success-message" onDismiss={id => setShowSnack(false)} status="success">
            <Text>Permissions updated</Text>
          </Snack>
        )}
      </Snackbar>

      <form onSubmit={onSubmit}>
        <Box className="eds-spacing--mt-32">
          <RadioGroup
            label="General access"
            name="privacy"
            id="Privacy-toggle"
            valueSelected={currentSelection}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentSelection((event.target as HTMLInputElement).value);
              setIsPublic(!isPublic);
            }}
            style={{ marginLeft: '11px' }}
          >
            <FormControlLabel
              value="public"
              control={<Radio id="public" label="Public" data-testid="public" />}
              label=""
              title="Everyone has View access."
            />
            <FormControlLabel
              value="private"
              control={<Radio id="private" label="Private" data-testid="private" />}
              label=""
              title="Only Collaborators can access."
            />
          </RadioGroup>
        </Box>

        <Box className="eds-spacing--mt-40">
          <Select
            id="okta-users"
            label="Add collaborators"
            isMulti
            isLoading={isUsersLoading}
            onInputChange={(value: string) => {
              setUserStartsWith(value);
            }}
            onChange={values => {
              dispatch({ kind: 'SELECT_COLLABORATORS', newCollaborators: values });
              setIsSelectionEmpty(values.length === 0);
            }}
            options={oktaUsers.items.map((user: { email: string }) => ({
              value: user.email,
              label: user.email,
            }))}
            noOptionsMessage={() => 'No user starts with that email'}
            placeholder="Begin typing user's email"
            required={false}
            value={collabState.selectedCollaborators.map(e => ({ value: e.name, label: e.name }))}
          />
        </Box>
        <Box className="eds-spacing--mt-32">
          <Select
            id="okta-groups"
            label="Add AD groups"
            isMulti
            isLoading={isGroupsLoading}
            onInputChange={(value: string) => {
              setUserStartsWith(value);
            }}
            onChange={values => {
              dispatch({ kind: 'SELECT_GROUPS', newGroups: values });
              setIsSelectionEmpty(values.length === 0);
            }}
            options={adGroups.items.map((group: { displayName: string; description: string }) => ({
              value: group.displayName,
              label: group.displayName,
            }))}
            noOptionsMessage={() => 'No AD group starts with that text'}
            placeholder="Begin typing the AD group"
            required={false}
            value={collabState.selectedGroups.map(e => ({ value: e.name, label: e.name }))}
          />
        </Box>

        <Box className="eds-spacing--mt-40">
          <Text font="title-5" as="h4" className="eds-spacing--mb-24">
            Collaborators
          </Text>

          <Box className="permissions-tab--grid eds-grid" data-testid="collaborators">
            {collabState.displayedCollaborators.filter(isOwner).map(collaborator => (
              <Fragment key={collaborator.name}>
                <Text>{collaborator.name}</Text>
                <Text>
                  {permissionsToRoleString(collaborator.permissions) ||
                    "There was a problem retrieving this Collaborator's role"}
                </Text>
                <Box /> {/* empty div needed to keep grid aligned */}
              </Fragment>
            ))}

            {collabState.displayedCollaborators
              .filter(collaborator => !isOwner(collaborator))
              .map(collaborator => (
                <Collaborator
                  key={`${collaborator.name}-${permissionsToRoleString(collaborator.permissions)}`}
                  collaborator={collaborator}
                  updatePermissions={(name, newRole) => {
                    dispatch({ kind: 'UPDATE_COLLABORATOR', name, newRole });
                    setCollaboratorsChanged(true);
                  }}
                  removeCollaborator={name => {
                    dispatch({ kind: 'REMOVE_COLLABORATOR', name });
                    setCollaboratorsChanged(true);
                  }}
                />
              ))}
          </Box>
        </Box>

        <Button
          type="submit"
          variant="primary"
          disabled={
            initialSelection === currentSelection && isSelectionEmpty && !collaboratorsChanged
          }
          className="eds-spacing--mt-40"
        >
          {!buttonDisabled ? 'Save' : <Spinner />}
        </Button>
      </form>
    </>
  );
}
