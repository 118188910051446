/* eslint-disable dot-notation */
import { useNavigate, Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {
  Text,
  Box,
  TableHeading,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  Table,
  Spinner,
  StatusIndicator,
} from '@nike/eds';
import { P, match } from 'ts-pattern';
import { useState } from 'react';
import { useApiClient } from '../hooks/use-api-client';

import './version-table.css';
import useDefinitionVersions from '../hooks/use-definition-versions';

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

type ColumnHeader = {
  id:
    | 'versionNumber'
    | 'createdOn'
    | 'tags'
    | 'filename'
    | 'createdByUserId'
    | 'versionNotes'
    | 'results'
    | 'actions';
  header: string;
};

const headers: ColumnHeader[] = [
  {
    id: 'versionNumber',
    header: 'Version',
  },
  {
    id: 'createdOn',
    header: 'Created on',
  },
  {
    id: 'tags',
    header: '',
  },
  {
    id: 'createdByUserId',
    header: 'Created by',
  },
  {
    id: 'results',
    header: '',
  },
  {
    id: 'actions',
    header: ' ',
  },
];

/**
 * Allows you to create a tabulated Card that contains various data tables
 * @param
 * @returns
 */
interface VersionTableProps {
  assetId: string;
  currentVersionId: string;
}

export function WorkflowVersionTable({ assetId, currentVersionId }: VersionTableProps) {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { oktaAuth } = useOktaAuth();

  const [publishing, setPublishing] = useState('');

  const {
    definitionVersions,
    versionsAreLoading,
    versionError,
    mutate: mutateVersions,
  } = useDefinitionVersions(assetId || '', `${oktaAuth.getAccessToken()}`);

  const onPublish = (versionId: string) => {
    setPublishing(versionId);
    const newVersions = definitionVersions?.map(version => {
      if (version.versionId === versionId) {
        return { ...version, tags: [...version.tags, 'PUBLISHED'] };
      }
      return { ...version, tags: [...version.tags.filter(tag => tag !== 'PUBLISHED')] };
    });
    mutateVersions(
      async () => {
        await apiClient.publishAssetVersion(assetId, versionId);
        await sleep(1000);
        setPublishing('');
        return undefined;
      },
      {
        optimisticData: newVersions,
      }
    );
  };

  const grasshopperDefinitionVersions = definitionVersions?.map(x => {
    const filteredArtifacts = x.artifactReferences.filter(artifact => artifact.name !== 'io.json');
    return {
      ...x,
      artifactReferences: filteredArtifacts,
    };
  });

  const parseDate = (date: string): string => date.substring(0, date.indexOf('T'));

  return (
    <>
      <Box className="eds-spacing--px-56" maxWidth="1536px" ml="auto" mr="auto" />

      <Box>
        {match({ grasshopperDefinitionVersions, versionsAreLoading, versionError })
          .with(
            {
              grasshopperDefinitionVersions: P.when(n => n?.length === 0),
              versionsAreLoading: false,
              versionError: undefined,
            },
            () => (
              <Text font="body-1" style={{ marginTop: '1em' }}>
                No versions found for definition.
              </Text>
            )
          )
          .with({ versionsAreLoading: false, versionError: undefined }, () => (
            <Table className="eds-spacing--mb-16" width="100%">
              <thead>
                <tr>
                  {headers.map(column => (
                    <TableHeading key={column.id}>{column.header}</TableHeading>
                  ))}
                </tr>
              </thead>

              <tbody>
                {grasshopperDefinitionVersions?.map(version => (
                  <tr
                    key={version.versionNumber}
                    onClick={() => navigate(`/workflows/${assetId}/versions/${version.versionId}`)}
                    className={
                      currentVersionId === version.versionId
                        ? 'definition-version-row selected'
                        : 'definition-version-row'
                    }
                  >
                    <TableCell>{version.versionNumber}</TableCell>

                    <TableCell>{parseDate(version.createTimestamp)}</TableCell>

                    <TableCell>
                      {version.tags.includes('PUBLISHED') ? (
                        <StatusIndicator
                          label="Published"
                          status="neutral"
                          style={{
                            backgroundColor: 'var(--eds-color-grey-4)',
                            borderRadius: 'var(--eds-radii-4)',
                            padding: '2px 8px',
                          }}
                        />
                      ) : null}
                    </TableCell>

                    <TableCell>{version.createdByUserId}</TableCell>

                    <TableCell onClick={event => event.stopPropagation()}>
                      <Button
                        size="small"
                        variant="secondary"
                        as={Link}
                        to={`/workflows/${assetId}/versions/${version.versionId}/results`}
                      >
                        Results
                      </Button>
                    </TableCell>

                    <TableCell onClick={event => event.stopPropagation()}>
                      <Tooltip bodySlot="Mark as Published" enableFocus>
                        {publishing === version.versionId ? (
                          <Spinner />
                        ) : (
                          <IconButton
                            label="publish"
                            size="small"
                            variant="secondary"
                            disabled={
                              publishing === version.versionId || version.tags.includes('PUBLISHED')
                            }
                            onClick={() => {
                              onPublish(version.versionId);
                            }}
                            icon="Rating"
                          />
                        )}
                      </Tooltip>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))
          .with({ versionsAreLoading: false, versionError: { message: P.string } }, () => (
            <Text font="body-1" style={{ marginTop: '1em' }}>
              {versionError.message}
            </Text>
          ))
          .otherwise(() => (
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner title="Loading" />
            </Box>
          ))}
      </Box>
    </>
  );
}
