import { Box, Label } from '@nike/eds';

import { SettingsCog, SettingsCogProps } from './settings-cog';

export function InputTitle({
  input,
  runFormState,
  dispatch,
  role,
  postInputSettings,
}: SettingsCogProps) {
  return (
    <Box className="eds-flex eds-flex-direction-row">
      <Box>
        <Label font="title-6" htmlFor={input.name.replace('RH_IN:', '')}>
          {input.name.replace('RH_IN:', '')}
        </Label>
        <Label className="eds-label eds-color--text-secondary eds-type--body-3">{input.type}</Label>
      </Box>
      <SettingsCog
        input={input}
        runFormState={runFormState}
        dispatch={dispatch}
        role={role}
        postInputSettings={postInputSettings}
      />
    </Box>
  );
}
