import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { IconButton, Modal, Text } from '@nike/eds';
import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { WorkflowDesignerAction } from '../../core/reducers/workflow-designer-reducer';
import { createDefaultFormElementSettings } from '../../core/defaults/workflow-field-default';
import { buttonData } from '../icon-field-display';

export function WorkflowAddFieldModal({
  dispatch,
  newFieldModalVisible,
  setNewFieldModalVisible,
}: {
  dispatch: Dispatch<WorkflowDesignerAction>;
  newFieldModalVisible: boolean;
  setNewFieldModalVisible: Dispatch<boolean>;
}) {
  const addField = (kind: WorkflowFormElementSettings['kind']) => {
    dispatch({
      kind: 'ADD_FIELD',
      newField: createDefaultFormElementSettings(kind, uuidv4(), 'New field name', true),
    });

    setNewFieldModalVisible(false);
  };

  return (
    <Modal
      isOpen={newFieldModalVisible}
      headerSlot={<Text font="title-4">Add field</Text>}
      onDismiss={() => {
        setNewFieldModalVisible(false);
      }}
    >
      <div className="eds-grid eds-grid--m-cols-3">
        {buttonData.map(button => (
          <IconButton
            label={button.kind}
            icon={button.icon}
            key={button.kind}
            size="large"
            className="eds-grid--m-col-1"
            variant="secondary"
            onClick={() => addField(button.kind)}
            title={button.title}
            data-testid={button.kind}
          />
        ))}
      </div>
    </Modal>
  );
}

export default WorkflowAddFieldModal;
