/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@nike/eds';

export interface HorizontalLinearStepperProps {
  activeStep: number;
  steps: string[];
}

export default function HorizontalLinearStepper({
  activeStep,
  steps,
}: HorizontalLinearStepperProps) {
  return (
    <Box className="eds-spacing--mb-32">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
            StepIconProps?: { style: { color: string } };
          } = {};
          if (index < activeStep) {
            // Step is completed
            stepProps.completed = true;
            labelProps.StepIconProps = { style: { color: 'black' } };
          }

          return (
            <Step key={label}>
              <StepLabel {...labelProps} color="black">
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
