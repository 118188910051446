import { FileNameTransform } from '@nike.innovation/composure-sdk';
import { match, P } from 'ts-pattern';
import { v4 as uuid } from 'uuid';
import { WorkflowOperatorState } from '../reducers/workflow-operator-reducer';

/**
 * Returns a stringified version of the workflow input if available otherwise an empty string
 * @param stepId
 * @param fieldName
 * @param workflowState
 * @returns
 */
export const getWorkflowInput = (
  stepId: string,
  fieldName: string,
  workflowState: WorkflowOperatorState
) => {
  const stepIndex = workflowState.workflow.steps.findIndex(step => step.id === stepId);
  const userInput = workflowState.userData[stepIndex][fieldName];

  return match(userInput)
    .with(P.string, refined => refined)
    .with({ label: P.string, value: P.string }, refined => refined.value)
    .otherwise(() => '');
};

export const transformFileName = (
  fileName: string,
  transforms: FileNameTransform[],
  workflowState: WorkflowOperatorState
) => {
  // Iterate over the fileTransform array and apply the transformations
  const results = transforms.map(transform =>
    match(transform)
      .with({ kind: 'currentDate' }, () =>
        new Date(Date.now()).toLocaleString().split(',')[0].replace(/\//g, '-')
      )
      .with({ kind: 'currentTime' }, () => new Date(Date.now()).toLocaleString().split(',')[1])
      .with({ kind: 'divider' }, refined => refined.divider)
      .with({ kind: 'customText' }, refined => refined.text)
      .with({ kind: 'fileName' }, () => fileName.replace(/\.[^/.]+$/, ''))
      .with({ kind: 'fileExtension' }, () => `.${fileName.split('.').pop()}`)
      .with({ kind: 'upstreamTextInputSelection' }, refined =>
        getWorkflowInput(refined.stepId, refined.fieldName, workflowState)
      )
      .with({ kind: 'user' }, () => workflowState.user)
      .with({ kind: 'uuid' }, () => uuid())
      .exhaustive()
  );
  return results.join('');
};

// Type guard to check if a value is an array of { fileName: string } objects
function isFileNameArray(value: unknown): value is { fileName: string }[] {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.every(
    item =>
      typeof item === 'object' &&
      item !== null &&
      'fileName' in item &&
      typeof item.fileName === 'string'
  );
}

export const renameFile = (originalFile: File, newName: string) =>
  new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

// Accepts a WorkflowOperatorState and returns a new WorkflowOperatorState with the file names transformed
export const transformFileNames = (workflowState: WorkflowOperatorState): WorkflowOperatorState => {
  // iterate over the userData and for each file input, transform the file name
  // using the configuration in the workflow
  const newUserData = workflowState.userData.map((stepData, stepIndex) =>
    Object.fromEntries(
      Object.entries(stepData).map(([fieldName, fieldValue]) => {
        if (fieldValue instanceof FileList) {
          const fileArray = Array.from(fieldValue as FileList);
          // Check whether the field in the workflow has a transformation
          const field = Object.values(workflowState.workflow.steps[stepIndex].form).find(
            fieldRecord => fieldRecord.fieldName === fieldName
          );
          if (field && 'fileTransform' in field.settings && field.settings.fileTransform) {
            const { fileTransform } = field.settings;
            // Iterate over the fileTransform array and apply the transformations
            return [
              fieldName,
              fileArray.map(file => {
                const newName = transformFileName(file.name, fileTransform, workflowState);
                const newFile = renameFile(file, newName);
                return newFile;
              }),
            ];
          }
          return [fieldName, fieldValue];
        }
        return [fieldName, fieldValue];
      })
    )
  );

  return { ...workflowState, userData: newUserData };
};
