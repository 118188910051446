import { Dispatch, useState } from 'react';
import { match } from 'ts-pattern';

import { Box, IconButton, Select, TextField, Toggle } from '@nike/eds';
import {
  FieldErrors,
  fileNameTransformToDisplayText,
  WorkflowFormElementSettings,
} from '@nike.innovation/composure-sdk';
import { FileNameForm } from './filename-form';
import { WorkflowDesignerAction } from '../../core/reducers/workflow-designer-reducer';

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function WorkflowDesignerFieldSettings({
  availableTransformFields,
  dispatch,
  field,
  settingErrors,
}: {
  availableTransformFields: { stepId: string; fieldId: string; fieldName: string }[];
  dispatch: Dispatch<WorkflowDesignerAction>;
  field: WorkflowFormElementSettings;
  settingErrors: FieldErrors['settings'];
}) {
  const [renameFileModalVis, setRenameFileModalVis] = useState(false);

  interface FileType {
    value: string;
    label: string;
  }

  const fileTypeOptions: FileType[] = [
    { value: '.txt', label: '.txt' },
    { value: '.csv', label: '.csv' },
    { value: '.html', label: '.html' },
    { value: '.css', label: '.css' },
    { value: '.js', label: '.js' },
    { value: '.json', label: '.json' },
    { value: '.png', label: '.png' },
    { value: '.jpg', label: '.jpg' },
    { value: '.jpeg', label: '.jpeg' },
    { value: '.gif', label: '.gif' },
    { value: '.pdf', label: '.pdf' },
  ];

  return match(field)
    .with({ kind: 'text' }, refined => (
      <>
        <Box className="eds-spacing--mb-24">
          <TextField
            id="selectedField-message"
            label="Message"
            message="Displayed alongside input"
            value={refined.settings.message}
            onChange={e =>
              dispatch({ kind: 'EDIT_FIELD_SETTING', newSetting: { message: e.target.value } })
            }
          />
        </Box>

        <Box className="eds-spacing--mb-24">
          <TextField
            id="selectedField-default"
            label="Default Value"
            value={refined.settings.default}
            onChange={e =>
              dispatch({ kind: 'EDIT_FIELD_SETTING', newSetting: { default: e.target.value } })
            }
          />
        </Box>
      </>
    ))
    .with({ kind: 'slider' }, refined => (
      <>
        {Object.entries(refined.settings).map(([settingName, value]) => (
          <Box className="eds-spacing--mb-24" key={refined.id + settingName}>
            <TextField
              id={`selectedField-${settingName}`}
              label={capitalizeFirstLetter(settingName)}
              value={value}
              type="number"
              hasErrors={settingErrors[settingName] && settingErrors[settingName]!.length > 0}
              errorMessage={
                settingErrors[settingName] !== undefined
                  ? settingErrors[settingName]!.join(', ')
                  : ''
              }
              onChange={e => {
                const newValue = parseFloat(e.target.value);

                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: { [settingName]: !Number.isNaN(newValue) ? newValue : 0 },
                });
              }}
            />
          </Box>
        ))}
      </>
    ))
    .with({ kind: 'number' }, refined => (
      <>
        {Object.entries(refined.settings).map(([settingName, value]) => (
          <Box className="eds-spacing--mb-24" key={refined.id + settingName}>
            <TextField
              id={`selectedField-${settingName}`}
              label={capitalizeFirstLetter(settingName)}
              placeholder={String(value)}
              value={value}
              type="number"
              onChange={e => {
                const newValue = parseFloat(e.target.value);

                dispatch({
                  kind: 'EDIT_FIELD_SETTING',
                  newSetting: { [settingName]: !Number.isNaN(newValue) ? newValue : 0 },
                });
              }}
            />
          </Box>
        ))}
      </>
    ))
    .with({ kind: 'toggle' }, refined => (
      <Toggle
        id={`${refined.id}toggleDefault`}
        label="Default value"
        onChange={e => {
          dispatch({
            kind: 'EDIT_FIELD_SETTING',
            newSetting: { default: !refined.settings.default },
          });
        }}
      />
    ))
    .with({ kind: 'select' }, refined => (
      <Box className="eds-spacing--mb-24">
        <Select
          id="selectedField-options"
          name="Options"
          label="Options"
          placeholder=""
          // eslint-disable-next-line dot-notation
          hasErrors={settingErrors['options'] && settingErrors['options'].length > 0}
          errorMessage={
            // eslint-disable-next-line dot-notation
            settingErrors['options'] !== undefined ? settingErrors['options'].join(', ') : ''
          }
          required
          isCreatable
          isMulti
          value={refined.settings.options}
          onChange={(e: any) => {
            const options = e.map((v: any) => ({ label: v.label, value: v.label }));
            if (e !== null)
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { options },
              });
          }}
        />
      </Box>
    ))
    .with({ kind: 'file' }, refined => (
      <>
        <Box className="eds-spacing--mb-24">
          <Select
            id="selectedField-acceptsdropdown"
            options={fileTypeOptions}
            isMulti
            label="File Types Accepted"
            value={
              refined.settings.accepts.split(',')[0] === ''
                ? null
                : refined.settings.accepts.split(',').map((v: string) => ({ label: v, value: v }))
            }
            subtitle="Select all accepted file types."
            required={false}
            isCreatable
            onChange={e => {
              const fileTypes: string = e
                .map((v: FileType) => (!v.value.startsWith('.') ? `.${v.value}` : v.value))
                .toString();
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { accepts: fileTypes },
              });
            }}
          />
        </Box>
        <Box className="eds-spacing--mb-24">
          <Toggle
            id="selectedField-acceptMultiple"
            label="Accept Multiple"
            checked={refined.settings.acceptMultiple}
            onChange={() =>
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { acceptMultiple: !refined.settings.acceptMultiple },
              })
            }
          />
        </Box>
        <Box className="eds-spacing--mb-24">
          <TextField
            id="selectedField-message"
            label="Message"
            placeholder="Message"
            value={refined.settings.message}
            onChange={e =>
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { message: e.target.value },
              })
            }
          />
        </Box>
        <Box className="eds-spacing--mb-24">
          <TextField
            style={{ textOverflow: 'ellipsis' }}
            id="selectedField-filename"
            label="Rename"
            message="File rename pattern"
            placeholder="Rename Pattern"
            value={refined.settings.fileTransform
              ?.map(e => fileNameTransformToDisplayText(e))
              .join('')}
            readOnly
            afterSlot={
              <IconButton
                icon="Expand"
                label="RenameModal"
                size="small"
                variant="ghost"
                onClick={() => {
                  setRenameFileModalVis(true);
                }}
              />
            }
          />
          <FileNameForm
            availableTransformFields={availableTransformFields}
            dispatch={dispatch}
            transforms={refined.settings.fileTransform}
            isOpen={renameFileModalVis}
            setIsOpen={setRenameFileModalVis}
          />
        </Box>
      </>
    ))
    .with({ kind: 'date' }, refined => (
      <>
        <Box className="eds-spacing--mb-24">
          <Toggle
            id="selectedField-withLocalTime"
            label="With Time"
            checked={refined.settings.withLocalTime}
            onChange={() =>
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { withLocalTime: !refined.settings.withLocalTime },
              })
            }
          />
        </Box>
        <Box className="eds-spacing--mb-24">
          <Toggle
            id="selectedField-allowPast"
            label="Allow Dates from Past"
            checked={refined.settings.allowPast}
            disabled={!refined.settings.allowFuture}
            onChange={() =>
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { allowPast: !refined.settings.allowPast },
              })
            }
          />
        </Box>
        <Box className="eds-spacing--mb-24">
          <Toggle
            id="selectedField-allowFuture"
            label="Allow Dates in Future"
            checked={refined.settings.allowFuture}
            disabled={!refined.settings.allowPast}
            onChange={() =>
              dispatch({
                kind: 'EDIT_FIELD_SETTING',
                newSetting: { allowFuture: !refined.settings.allowFuture },
              })
            }
          />
        </Box>
      </>
    ))
    .exhaustive();
}
