import { IconButton, Select, Text } from '@nike/eds';

import { Entitlement } from '@nike.innovation/aurora';
import { entitlementPermissions, permissionsToRoleString } from '../utils/entitlement-utils';

export function Collaborator({
  collaborator,
  updatePermissions,
  removeCollaborator,
}: {
  collaborator: Entitlement;
  updatePermissions: (name: string, newRole: string) => void;
  removeCollaborator: (name: string) => void;
}) {
  const { name } = collaborator;
  const role = permissionsToRoleString(collaborator.permissions);

  return (
    <>
      <Text>{name}</Text>
      {role ? (
        <Select
          defaultValue={{ value: role, label: role }}
          required={false}
          id={`collaborator-${name}`}
          options={entitlementPermissions.map(x => ({ value: x, label: x }))}
          label={`${name} permissions`}
          aria-label={`${name} permissions`}
          onChange={value => updatePermissions(name, value?.value || '')}
          hideLabel
        />
      ) : (
        <Text>There was a problem retrieving this Collaborator&apos;s role</Text>
      )}

      <IconButton
        icon="Delete"
        label={`Remove collaborator ${name}`}
        variant="secondary"
        onClick={() => removeCollaborator(name)}
      />
    </>
  );
}
