import { ComposureEnvironment } from './environment';

export const environment: ComposureEnvironment = {
  production: false,
  adGroup: 'App.Composure.Test.Users',
  clientId: 'nike.picc.composure',
  issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  apiBaseUrl: 'https://api.composure-test.nike.io',
  aegisBaseUrl: 'https://api.aegis.nikecloud.com/v1/qa',
  composureApiEnvironment: 'test',
};
