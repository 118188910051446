import { Aurora, AuroraBaseUrl, AssetSearchOptions } from '@nike.innovation/aurora';
import useSWR from 'swr';
import { environment } from '../../../../environments/environment';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

export interface useMidsoleSearchOptions {
  from: number;
  size: number;
  active: boolean;
}

/**
 * React hook for a search result page for ComputationDefinition
 * @param { from: number, size: number, active: boolean}
 * @param token
 * @returns
 */
export function useMidsoleSearch(options: AssetSearchOptions, token: string) {
  const fetcher = async () =>
    aurora.Midsole.search(
      {
        ...options,
        active: true,
      },
      token
    );

  const { data, error, mutate } = useSWR({ ...options, assetClass: 'midsoles' }, fetcher);

  return {
    midsoleSearchResult: data,
    midsoleSearchIsLoading: !error && !data,
    midsoleSearchError: error,
    mutate,
  };
}

export default useMidsoleSearch;
