import { Dispatch, ReactElement } from 'react';

import { Checkbox, Text, TextField } from '@nike/eds';
import { WorkflowFormElementSettings } from '@nike.innovation/composure-sdk';
import { WorkflowDesignerFieldSettings } from './workflow-designer-field-settings';
import {
  WorkflowDesignerAction,
  WorkflowDesignerState,
} from '../../core/reducers/workflow-designer-reducer';
import { getFieldErrors } from '../../core/validation/field/validate-field';
import { getAvailableInputFieldsForTransform } from '../../core/reducers/utils';
import { IconFieldDisplay } from '../icon-field-display';

export function WorkflowDesignerSettings({
  designerState,
  dispatch,
  selectedField,
}: {
  designerState: WorkflowDesignerState;
  dispatch: Dispatch<WorkflowDesignerAction>;
  selectedField: WorkflowFormElementSettings;
}) {
  const fieldErrors = getFieldErrors(designerState, selectedField.id);

  return (
    <>
      <Text font="title-4" className="eds-spacing--mb-12">
        Settings
      </Text>

      {selectedField && (
        <>
          <IconFieldDisplay input={selectedField.kind} font="legal-1" />
          <TextField
            id="selectedField-fieldName"
            label="Field Name"
            placeholder="Field Name"
            value={selectedField.fieldName}
            hasErrors={!!fieldErrors.fieldName && fieldErrors.fieldName.length > 0}
            errorMessage={
              fieldErrors.fieldName !== undefined ? fieldErrors.fieldName.join(', ') : ''
            }
            required
            onChange={e => dispatch({ kind: 'EDIT_FIELD_NAME', name: e.target.value })}
            data-testid="fieldName"
          />

          <Checkbox
            id="selectedField-required"
            label="Required"
            checked={selectedField.required}
            className="eds-spacing--mt-16 eds-spacing--mb-32"
            onChange={e => dispatch({ kind: 'TOGGLE_FIELD_REQUIREMENT' })}
          />

          <WorkflowDesignerFieldSettings
            availableTransformFields={getAvailableInputFieldsForTransform(designerState)}
            dispatch={dispatch}
            field={selectedField}
            settingErrors={fieldErrors.settings}
          />
        </>
      )}
    </>
  );
}
