import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Card, Icon, Modal, Text } from '@nike/eds';
import { GrasshopperDefinition } from '@nike.innovation/composure-sdk';

import { AddCollaborators } from '../../../shared/permissions/add-collaborators';
import { getUserRole } from '../../../shared/utils/entitlement-utils';
import Tabtable, { tabsntableTuple } from '../../../shared/tabtable/tabtable';
import { ApiTab } from './details-card/api-tab';

export function DefinitionSettingsModal({
  definition,
  settingsModalOpen,
  setSettingsModalOpen,
}: {
  definition: GrasshopperDefinition;
  settingsModalOpen: boolean;
  setSettingsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { authState } = useOktaAuth();
  const role = getUserRole(authState, definition.entitlements || []);

  const permissionsTab: tabsntableTuple = [
    {
      label: 'Permissions',
      value: 'Permissions',
    },
    <Card className="eds-elevation--2">
      <Text id="name" data-testid="permissions" font="title-3" className="eds-spacing--mb-16">
        Advanced
      </Text>

      <Box>
        <Box
          id="permissions"
          className={classNames(
            'eds-tag',
            { green: role === 'Owner' },
            { yellow: role === 'Editor' },
            { blue: role === 'Viewer' }
          )}
        >
          <Icon name="Personalization" />

          {role ||
            'There was a problem retrieving your role. This problem is only related to the display, and you are able to take all actions you have permissions for.'}
        </Box>
      </Box>

      {role === 'Owner' ? (
        <AddCollaborators asset={definition} versionId={definition.versionId} />
      ) : null}
    </Card>,
  ];

  const apiTab: tabsntableTuple = [
    { value: 'api', label: 'API' },
    <ApiTab io={definition.io} definitionId={definition.id} versionId={definition.versionId} />,
  ];

  return (
    <Modal
      isOpen={settingsModalOpen}
      onDismiss={() => setSettingsModalOpen(false)}
      headerSlot={<Text font="title-3">Settings</Text>}
    >
      <Box>
        <Tabtable tabsntables={[permissionsTab, apiTab]} />
      </Box>
    </Modal>
  );
}
