import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';

export const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: [
    'openid',
    'offline_access',
    'product_design:aurora.gateway.internal::read:',
    'product_design:aurora.search.allassets.v2::read:',
  ],
  tokenManager: {
    autoRenew: true,
  },
  pkce: true,
});
