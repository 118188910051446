import { Text, Box } from '@nike/eds';
import { KeyedMutator } from 'swr';
import { useParams } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import { AssetSearchResponse } from '@nike.innovation/aurora';
import useDefinition from '../../../shared/hooks/use-definition';
import { DetailsCard } from './details-card/details-card';

import { SendPageView } from '../../../shared/utils/ga4-helpers/ga4-events';
import { Breadcrumbs } from '../../../shared/breadcrumbs/breadcrumbs';
import { DefinitionDetailsHeader } from './definition-details-header';

export function DefinitionDetailsPage({
  definitionSearchResult,
  mutateSearch,
}: {
  definitionSearchResult?: AssetSearchResponse;
  mutateSearch: KeyedMutator<AssetSearchResponse>;
}) {
  SendPageView({ page: window.location.pathname, title: 'Definition Details Page' });

  const { oktaAuth } = useOktaAuth();
  const { definitionId, versionId } = useParams();

  // Individual ComputationDefinition asset
  const { definition, isError } = useDefinition(
    definitionId || '',
    versionId || '',
    `${oktaAuth.getAccessToken()}`
  );

  return definition ? (
    <>
      <Breadcrumbs
        crumbs={[
          { url: '/definitions', name: 'Grasshopper Definitions' },
          { url: '', name: 'Details' },
        ]}
      />

      <DefinitionDetailsHeader
        definition={definition}
        definitionSearchResult={definitionSearchResult}
        mutateSearch={mutateSearch}
      />

      <DetailsCard definition={definition} />
    </>
  ) : (
    isError && (
      <Box>
        <Text>{isError}</Text>
      </Box>
    )
  );
}

export default DefinitionDetailsPage;
