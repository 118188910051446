import { Button, Modal, Text } from '@nike/eds';
import './definition-solve-result-page.css';

export interface SolveValueModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSave?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
}

// TODO: pull this form out of a modal so its easy to test without overriding tappable
// our usage of the modal is also just on a single boolean, so you could argue theres no need to test it
export function SolveValueModal({ visible, setVisible, setSave, value }: SolveValueModalProps) {
  return (
    <Modal
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={
        <Text font="title-3" as="h3">
          Value
        </Text>
      }
      // footerSlot={
      //   setSave && (
      //     <Button
      //       onClick={() => {
      //         setVisible(false);
      //         setSave(true);
      //       }}
      //     >
      //       Save File
      //     </Button>
      //   )
      // }
    >
      <Text className="value-cell">{value}</Text>
    </Modal>
  );
}

export default SolveValueModal;
