import { Card, RadioGroup, Radio, Text, Box, IconButton, Spinner, Tooltip } from '@nike/eds';
import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useDefinitionVersions from '../../../../shared/hooks/use-definition-versions';
import { environment } from '../../../../../environments/environment';

export interface ApiUrlCardProps {
  definitionId: string;
  versionId: string;
}

export function ApiUrlCard({ definitionId, versionId }: ApiUrlCardProps) {
  const { oktaAuth } = useOktaAuth();
  const apiBaseUrl = `${environment.apiBaseUrl}/api/v1/definitions`;
  const hopsBaseUrl = 'http://localhost:5081/composure/v1/compute';
  const { definitionVersions } = useDefinitionVersions(
    definitionId || '',
    `${oktaAuth.getAccessToken()}`
  );
  const publishedVersionExists =
    definitionVersions?.some(v => v.tags.includes('PUBLISHED')) || false;
  const [apiVersion, setApiVersion] = useState(publishedVersionExists ? 'published' : 'latest');
  const [apiBase, setApiBase] = useState(apiBaseUrl);
  const [apiSuffix, setApiSuffix] = useState('/solve');

  useEffect(() => {
    if (definitionVersions?.some(v => v.tags.includes('PUBLISHED')) || false) {
      setApiVersion('published');
    }
  }, [definitionVersions]);

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-16">
      <Text font="title-3" as="h2" className="eds-spacing--mb-16">
        API Endpoint
      </Text>

      {definitionVersions ? (
        <>
          <Box className="eds-flex eds-flex--align-items-center eds-gap--8 eds-spacing--p-16 eds-spacing--mb-16 eds-background--secondary">
            <Box hidden={apiBase === hopsBaseUrl}>
              <Text font="subtitle-2" className="eds-color--secondary">
                POST
              </Text>
            </Box>

            <Box className="eds-flex--grow-1">
              <Text font="subtitle-2">
                {apiBase}/{definitionId}/versions/{apiVersion}
                {apiSuffix}
              </Text>
            </Box>

            <Box>
              <Tooltip bodySlot="Copy to Clipboard">
                <CopyToClipboard
                  text={`${apiBase}/${definitionId}/versions/${apiVersion}${apiSuffix}`}
                >
                  <IconButton
                    variant="ghost"
                    label="Copy to Clipboard"
                    icon="CopyPaste"
                    size="small"
                  />
                </CopyToClipboard>
              </Tooltip>
            </Box>
          </Box>

          <Box className="eds-grid eds-grid--m-cols-6">
            <Box className="eds-grid--m-col-4">
              <RadioGroup
                label="Version"
                subtitle="The version of the definition to utilize"
                name="apiVersion"
                orientation="horizontal"
                valueSelected={apiVersion}
                onChange={e => setApiVersion(e.target.value)}
              >
                <Radio label="Latest" value="latest" id="radio-latest" />
                <Radio label="This Version" value={versionId} id="radio-this-version" />
                <Radio
                  label="Published"
                  value="published"
                  id="radio-published"
                  disabled={!publishedVersionExists}
                />
              </RadioGroup>
            </Box>

            <Box className="eds-grid--m-col-2">
              <RadioGroup
                label="Usage Type"
                subtitle="Where the API will be used"
                name="apiUsage"
                orientation="horizontal"
                valueSelected={apiBase}
                onChange={e => {
                  setApiBase(e.target.value);
                  setApiSuffix(e.target.id === 'radio-hops' ? '' : '/solve');
                }}
              >
                <Radio label="Standard" value={apiBaseUrl} id="radio-standard" />
                <Radio label="Proxzilla" value={hopsBaseUrl} id="radio-hops" />
              </RadioGroup>
            </Box>
          </Box>
        </>
      ) : (
        <Spinner />
      )}
    </Card>
  );
}
