import { Text, Box } from '@nike/eds';

export function InputDescription({ description }: { description: string | undefined | null }) {
  return (
    <Box>
      {description !== undefined && description !== null && description !== '' ? (
        <Text font="body-2" className="eds-spacing--mt-8">
          Description: {description}
        </Text>
      ) : null}
    </Box>
  );
}
