import { ComposureClient } from '@nike.innovation/composure-sdk';

import { useOktaAuth } from '@okta/okta-react';
import { environment } from '../../../environments/environment';

export function useApiClient() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  return new ComposureClient(oktaToken, environment.composureApiEnvironment);
}
