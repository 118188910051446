import axios from 'axios';
import useSWR from 'swr';
import { match, P } from 'ts-pattern';

import { Aurora, AuroraBaseUrl } from '@nike.innovation/aurora';
import { GrasshopperDefinition } from '@nike.innovation/composure-sdk';

import { environment } from '../../../environments/environment';
import { AssetDeletedError } from '../errors';
import { checkForPublicEntitlement } from '../utils/entitlement-utils';

const aurora = new Aurora({
  baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
});

/**
 * React hook for a single ComputationDefinition asset in Aurora
 * @param assetId
 * @param token
 * @returns
 */
export function useDefinition(
  assetId: string,
  assetVersionId: string,
  token: string
): {
  definition: GrasshopperDefinition | undefined;
  isLoading: boolean;
  isError: any; // this could also become a union of different "error types" for even easier rendering
} {
  const fetcher = async () => {
    const fetchDefinition = match(assetVersionId)
      .with('latest', () => async () => aurora.ComputationDefinition.getAssetLatest(assetId, token))
      .otherwise(
        () => async () =>
          aurora.ComputationDefinition.getAssetVersion(assetId, assetVersionId, token)
      );

    const definition = await fetchDefinition();

    //
    // Throw error if requested asset is no longer active. SWR will catch the error and return it
    // as the error value
    //
    if (definition.assetActive === false) {
      throw new AssetDeletedError('Asset not found');
    }

    const ioArtifact = definition.artifacts.filter(x => x.name === 'io.json');
    const definitionArtifact = definition.artifacts.filter(x => x.name !== 'io.json');

    const ioData =
      ioArtifact.length > 0
        ? await axios
            .get(ioArtifact[0].url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(res => res.data)
        : undefined;

    const grasshopperDefinition: GrasshopperDefinition = {
      id: definition.assetId,
      versionId: definition.versionId,
      versionNumber: definition.versionNumber,
      fileType: definitionArtifact[0].name.split('.').pop(),
      name: definition.name,
      description: definition.assetMetadata.description,
      versionTags: definition.versionTags,
      methodOfMake: definition.assetMetadata.methodOfMake,
      runtime: definition.assetMetadata.runtime,
      isPublic: checkForPublicEntitlement(definition),
      io: ioData,
      entitlements: definition.entitlements || [],
    };
    return grasshopperDefinition;
  };

  const { data, error } = useSWR(['definition', assetId, assetVersionId], fetcher);

  const errorMessage = match(error)
    .with({ details: { statusCode: 403 } }, () => 'You do not have permission to view this asset')
    .with({ message: P.string }, e => e.message)
    .otherwise(() => undefined);

  return { definition: data, isLoading: !error && !data, isError: errorMessage };
}

export default useDefinition;
